import React from 'react'
import classNames from 'classnames'

import { I18n } from 'utils/i18n'
import { Image } from 'components/images'
import { Price } from 'components/price'
import { Link } from 'components/text'

import styles from './item-details.css'

export const webLabels = {
  sku: `WEB.PRODUCT.SKU`,
  price: `WEB.PRODUCT.PRICE`,
  color: `WEB.PRODUCT.COLOR`,
  size: `WEB.PRODUCT.SIZE`,
  localsize: `WEB.PRODUCT.LOCALSIZE`,
  quantity: `WEB.PRODUCT.QUANTITY`,
  sizeType: `WEB.PRODUCT.SIZETYPE`
}

export const ItemDetails = ({
  children,
  'data-testid': testId,
  index,
  item,
  variation
}) => {
  const {
    discounts,
    options: {
      color,
      size,
      sizeType
    },
    name,
    quantity,
    price,
    sku,
    thumbnailUrl,
    url
  } = item
  const { priceField, ...rest } = getFieldsToShow()
  const completeUrl = `${url}?sku=${sku}`

  return (
    <div className={classNames(styles.itemDetails, styles[variation])}>
      <Link
        className={styles.image}
        data-testid={`${testId}-item-details-image-link-${index}`}
        to={completeUrl}
      >
        <Image
          interactive
          src={thumbnailUrl}
        />
      </Link>
      <div className={styles.details}>
        <div className={styles.nameWrapper}>
          <Link
            className={styles[`${variation}Name`]}
            to={completeUrl}
          >
            {name}
          </Link>
          {variation === 'basketItem' &&
            <div className={styles.detail}>
              <span className={styles.label}>
                {I18n.t(webLabels.sku)}
              </span>
              <span className={styles.value}>
                {sku}
              </span>
            </div>
          }
        </div>
        <div className={styles.aboutItem}>
          {priceField &&
            <div className={classNames(styles.detail, styles.price)}>
              <span className={styles.label}>
                {`${I18n.t(webLabels.price)}:`}
              </span>
              <Price
                price={price}
                showPricingStrategy={false}
                type="xs"
              />
            </div>
          }
          {Object.keys(rest).map(field => (
            <div
              className={classNames(styles.detail, styles[field])}
              key={field}
            >
              <span className={styles.label}>
                {`${I18n.t(webLabels[field])}:`}
              </span>
              <span className={styles.value}>
                {rest[field]}
              </span>
            </div>
          ))}
          {discounts && renderDiscounts()}
          {children}
        </div>
      </div>
    </div>
  )

  function getFieldsToShow() {
    const fields = {
      color: color.name,
      priceField: price,
      quantity
    }

    if (sizeType) {
      fields.sizeType = sizeType.name
    }

    // isLocalised
    if (size.labelSize) {
      fields.localsize = size.labelSize
    } else {
      fields.size = size.name
    }

    return fields
  }

  function renderDiscounts() {
    return (
      <div className={styles.discounts}>
        {discounts.map(discount => {
          const label = I18n.t(discount.messageId, {
            returnKey: false // might want to set this to true if web labels are not being used
          })

          if (label) {
            return (
              <div
                className={styles.discount}
                key={discount.name}
              >
                {label}
              </div>
            )
          }

          return null
        })}
      </div>
    )
  }
}
