import React from 'react'

import { Svg } from 'components/icons'
import { Image } from 'components/images'
import { Heading, Link } from 'components/text'

import styles from './category-card.css'

export const CategoryCard = ({ label, path, image, categories }) => (
  <div className={styles.categoryCard}>
    <Link to={path} className={styles.imageWrapper}>
      <Image
        alt={label}
        interactive
        prefix
        src={image}
      />
    </Link>
    <Link to={path}>
      <Heading
        content={{
          html: label
        }}
        styling={{
          fontSize: `xs`
        }}
      />
    </Link>
    <ul>
      {categories.map(item => (
        <li key={item.path} className={styles.item}>
          <Link to={item.path}>
            {item.label}
            <Svg icon="angle_right" />
          </Link>
        </li>
      ))}
    </ul>
  </div>
)
