import React from 'react'

import styles from './circular-loader.css'

export const CircularLoader = ({ color = 'currentColor', size = 5, percentage, placeholder }) => {
  const renderCircle = (strokeColor, progress) => (
    <svg viewBox="0 0 36 36">
      <path
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        fill="none"
        stroke={strokeColor}
        strokeWidth="1"
        strokeDasharray={`${progress}, 100`}
      />
    </svg>
  )

  const renderPercentageCircle = () => {
    return renderCircle(color, percentage)
  }

  const renderPlaceholderCircle = () => {
    return renderCircle('rgba(224, 224, 224, 0.75)', 100)
  }

  return (
    <div
      className={styles.container}
      style={{
        height: `${size}rem`,
        width: `${size}rem`
      }}
    >
      {placeholder && renderPlaceholderCircle()}
      {renderPercentageCircle()}
    </div>
  )
}
