import React from 'react'
import { useSelector } from 'react-redux'

import { getMetaOptions } from 'global-content'
import { currencyFormatted } from 'utils'
import { useFilters } from 'hooks'
import { CREATEFILTERSMAP } from 'services/algolia'
import { RangeSlider } from 'components/filters'

import styles from './range-selector.css'

export const RangeSelector = ({ data, uiOptions, usePending }) => {
  const { name } = uiOptions

  const selectedFilters = useSelector(state => state.listing.selectedFilters)
  const pendingFilters = useSelector(state => state.listing.pendingFilters)

  let selectedFacet = selectedFilters[name]

  if (usePending) {
    selectedFacet = pendingFilters[name]
  }

  const { onFilterSelect } = useFilters(usePending)
  const { stepMultiple } = getMetaOptions('currency')
  const numbers = data.map(({ value }) => parseFloat(value))
  const availableMax = Math.max(...numbers)
  const availableMin = Math.min(...numbers)

  let min = availableMin
  let max = availableMax

  if (selectedFacet) {
    min = selectedFacet[0]
    max = selectedFacet[1]
  }

  const [selected, setSelected] = React.useState([min, max])

  React.useEffect(() => {
    setSelected([min, max])
  }, [selectedFacet])

  return (
    <div className={styles.container}>
      <div className={styles.priceRangeValues}>
        <div>{renderNumber(selected[0])}</div>
        <div>{renderNumber(selected[1])}</div>
      </div>
      <RangeSlider
        min={availableMin}
        max={availableMax}
        step={stepMultiple}
        values={{
          min: parseFloat(min),
          max: parseFloat(max)
        }}
        onSliderDragEnd={onSliderDragEnd}
        onValuesUpdated={onValuesUpdated}
      />
    </div>
  )

  function renderNumber(value) {
    const formatAsCurrency = CREATEFILTERSMAP()[name].currency

    if (formatAsCurrency) {
      return currencyFormatted(value)
    }

    return value
  }

  function onValuesUpdated({ values }) {
    setSelected(values)
  }

  function onSliderDragEnd() {
    onFilterSelect(name, selected)
  }
}
