export {
  setAlgoliaIndices,
  getAlgoliaIndex
} from './algolia'

export {
  defaultComponentConfigs,
  getComponentOptions,
  setComponents
} from './components'
export {
  getContent,
  loadBadges,
  loadClpRoutes,
  loadFooter,
  loadFooterNav,
  loadHead,
  loadNavigation,
  loadPromotions,
  loadSeoData
} from './content'
export { loadSchema } from './schemas'
export {
  setGlobalConfig,
  getConfigOptions,
  getMetaOptions
} from './config'
export {
  getShippingOptions,
  setShippingOptions
} from './shippingOptions'
export {
  defaultThemes,
  setThemes,
  getThemes
} from './themes'
