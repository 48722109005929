import React from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import { I18n } from 'utils/i18n'
import { getComponentOptions } from 'global-content'
import { IconLabel } from 'components/icon-label'
import { Svg } from 'components/icons'

import styles from './cart-count.css'

export const webLabels = {
  label: `WEB.CHECKOUT.LABEL`
}

export const CartCount = ({ onClick }) => {
  const { position, size } = getComponentOptions(`cartCount`)
  const totalItemCount = useSelector(state => state.cart.cartSummary.totalItemCount)

  return (
    <button
      data-testid="cart-count"
      onClick={onClick}
    >
      <div className={styles.container}>
        <Svg
          icon="basket"
          size={size}
        />
        {(position !== 'onTop' || (position === 'onTop' && !!totalItemCount)) &&
        <span className={classNames(styles.count, styles[position])}>
          {totalItemCount || `0`}
        </span>
        }
      </div>
      <IconLabel iconLabel={I18n.t(webLabels.label)} />
    </button>
  )
}
