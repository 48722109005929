import React from 'react'

import { getComponentOptions } from 'global-content'

import { Svg } from 'components/icons'

import styles from './selected-filter-button.css'

export const SelectedFilterButton = ({ children, onClick }) => {
  const css = getComponentOptions(`selectedFilterButton.options.styling.css`)

  return (
    <button
      className={styles.button}
      onClick={onClick}
      style={css}
    >
      {children}
      <Svg
        icon="close"
        size="1.2rem"
      />
    </button>
  )
}
