import { logToCloudWatch } from 'utils/logToCloudWatch'
import { loadValidation } from 'services/api'

function fetchJson(filename) {
  return fetch(`${filename}?cb=${window.$cacheBust}`)
    .then(response => handleMissing(response, filename))
    .then(text => parseJSON(text, filename))
}

function handleMissing(response, filename) {
  if (!response.ok) {
    jsonMissing(filename, response)
  }

  return response.text()
}

function parseJSON(text, filename) {
  try {
    return JSON.parse(text)
  } catch (err) {
    // server returning index.html
    if (text.startsWith(`<!DOCTYPE`)) {
      jsonMissing(filename, { statusText: `Not Found` })
    } else {
      logToCloudWatch('Error-JsonInvalid', {
        file: filename
      })

      throw new Error(`${filename}: ${err}`)
    }
  }
}

function jsonMissing(filename, response) {
  logToCloudWatch('Error-JsonMissing', {
    file: filename
  })

  throw new Error(`${filename}: ${response.statusText}`)
}

export function loadContent(file) {
  // todo: support other file extensions
  if (file.endsWith('json')) {
    return fetchJson(file)
      .then(data => {
        return ({
          content: data,
          name: file
        })
      })
  }

  throw new Error(`${file} doesn't end with json`)
}

export function loadCheckoutSchema(language) {
  return loadValidation(language)
}

export function loadSchema(file, name) {
  // todo: update this to use fetchJson for the error logging
  return fetch(`${file}`).then(res => res.json())
    .then(schemaJson => ({
      schema: schemaJson,
      name: name
    }))
}
