import React from 'react'
import classNames from 'classnames'

import {
  useLockBodyScroll,
  useOnClickOutside,
  useKeyPress
} from 'hooks'

import { capitalize } from 'utils'
import { Svg } from 'components/icons'

import styles from './modal.css'

export const Modal = ({
  children,
  onClose,
  padding = 'medium'
}) => {
  const ref = React.useRef()
  useLockBodyScroll()
  useOnClickOutside(ref, onClose)

  if (useKeyPress('Escape')) {
    onClose()
  }

  return (
    <div className={styles.modal}>
      <div
        className={styles.contentWrapper}
        ref={ref}
      >
        <button
          className={styles.close}
          onClick={onClose}
        >
          <Svg
            icon="close"
            size="2rem"
          />
        </button>
        <div className={classNames(styles.content, styles[`padding${capitalize(padding)}`])}>
          {children}
        </div>
      </div>
    </div>
  )
}
