import React from 'react'
import classNames from 'classnames'

import { logToCloudWatch } from 'utils/logToCloudWatch'

import styles from './image.css'

export const Image = ({
  alt,
  fade,
  interactive,
  overlay,
  prefix,
  ratio,
  src,
  style,
  type
}) => {
  const to = prefix ? `${src}` : src

  if (fade || interactive || overlay || ratio) {
    return (
      <div className={classNames({
        [styles.overlay]: overlay,
        [styles.fade]: fade,
        [styles.interactive]: interactive,
        [styles[ratio]]: ratio
      })}>
        {renderImage()}
      </div>
    )
  }

  return (
    renderImage()
  )

  function renderImage() {
    if (type === 'cover') {
      return (
        <div
          className={styles.cover}
          style={{ backgroundImage: `url(${to})`, ...style }}
        />
      )
    }

    return (
      <img
        alt={alt}
        onError={imageLoadError}
        src={to}
        style={style}
      />
    )
  }
}

function imageLoadError(e) {
  logToCloudWatch('Error-ImageLoad', {
    url: e.target.src
  })
}
