import { currencyFormatted } from 'utils'
import { I18n } from 'utils/i18n'
import { localPercent } from 'utils/localPercent'

export const webLabels = {
  PERCENT_OFF: `WEB.PROMO.DISCOUNT.PERCENT.OFF`,
  VALUE_OFF: `WEB.PROMO.DISCOUNT.VALUE.OFF`,
  VALUE_OVERRIDE: `WEB.PROMO.DISCOUNT.VALUE.OVERRIDE`,
  FREE: `WEB.PROMO.DISCOUNT.VALUE.OVERRIDE.FREE`
}

export function typeAndAmountInterpolater({ amount, language, type }) {
  return I18n.t(getWebLabel(), {
    language,
    replace: {
      amount: type !== `PERCENT_OFF` ? currencyFormatted(amount, { includeDecimal: false }) : localPercent(amount, language)
    }
  })

  function getWebLabel() {
    if (amount === 0 && type === `VALUE_OVERRIDE`) {
      return webLabels.FREE
    }

    return webLabels[type]
  }
}
