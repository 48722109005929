import React from 'react'
import classNames from 'classnames'

import { getComponentOptions } from 'global-content'

import { useOnClickOutside } from 'hooks'

import { Svg } from 'components/icons'
import { SiteSearch } from 'components/inputs'

import styles from './header-search-input.css'

export const HeaderSearchInput = ({ onSubmit }) => {
  const ref = React.useRef()
  const { mobileToggle, size } = getComponentOptions(`header.components.search.styling`)
  const { expandable } = getComponentOptions(`header.components.search.config`)

  const [show, setShow] = React.useState(false)
  useOnClickOutside(ref, () => setShow(false))

  return (
    <div
      className={classNames(styles.container, {
        [styles.show]: show
      })}
      ref={ref}
    >
      <button
        className={styles.toggle}
        onClick={() => toggleMobileSearch()}
      >
        <Svg
          icon="search"
          size={mobileToggle.iconSize}
        />
      </button>
      <div className={styles.mobileWrapper}>
        <SiteSearch
          expandable={expandable}
          onSubmit={handleSubmit}
          size={size}
          variation="header"
        />
      </div>
    </div>
  )

  function toggleMobileSearch() {
    setShow(!show)
  }

  function handleSubmit() {
    setShow(false)
    onSubmit()
  }
}
