import { getMetaOptions } from 'global-content'

export function naverPixel() {
  if (getMetaOptions('integrations.naver')) {
    const script = document.createElement('script')
    script.src = `https://wcs.naver.net/wcslog.js`
    script.id = 'navelPixel'
    script.type = 'text/javascript'
    script.onload = () => {
      if (!window.wcs_add) {
        window.wcs_add = {}
      }
      window.wcs_add['wa'] = getMetaOptions('integrations.naver.siteId')
      if (!window._nasa) {
        window._nasa = {}
      }
      window.wcs.inflow()
      window.wcs_do(window._nasa)
    }
    let scriptsHandle = document.getElementById('scripts')
    scriptsHandle.appendChild(script)
  }
}
