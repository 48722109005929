import React from 'react'

import { Svg } from 'components/icons'
import { Image } from 'components/images'
import { HtmlText } from 'components/text'

import styles from './shipping-promo.css'

export const ShippingPromo = ({
  content,
  styling = {}
}) => {
  console.log()
  const { iconRef, imageSrc, html } = content
  const { iconSize = `2rem` } = styling

  return (
    <div className={styles.itemWrapper}>
      {iconRef &&
        <div className={styles.iconWrapper}>
          <Svg
            block
            icon={iconRef}
            size={iconSize}
          />
        </div>
      }

      {imageSrc &&
        <div className={styles.imageWrapper}>
          <Image src={imageSrc} />
        </div>
      }

      {html &&
        <HtmlText html={html} />
      }
    </div>
  )
}
