import React from 'react'
import get from 'lodash/get'

import { useContent } from 'hooks'

import { Head, Header } from 'components/header'

export const HeaderOrder = () => {
  const head = useContent('head')

  const components = {
    head: Head,
    header: Header
  }

  const renderOrder = ['header']

  if (head) {
    if (get(head, 'config.position') === 'headTop') {
      renderOrder.unshift('head')
    } else {
      renderOrder.push('head')
    }
  }

  return (
    <React.Fragment>
      {renderOrder.map(key => {
        const Component = components[key]

        return (
          <Component key={key} />
        )
      })}
    </React.Fragment>
  )
}
