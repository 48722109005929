import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { updateGoogleAnalytics } from 'services/analytics'
import { useRouteChange } from 'hooks'
import { changeLanguage } from 'state/actions'
import { contentDependencies } from 'global-content/content'

export function useChangeLanguage() {
  const hasSwitchedLanguages = useSelector(state => state.site.hasSwitchedLanguages)
  const dispatch = useDispatch()
  const { pathname, search } = useLocation()
  const updateLocation = useRouteChange()

  return function switchLanguage(language, onClick) {
    if (!hasSwitchedLanguages) {
      contentDependencies(language).then(() => {
        handleSwitch()
      })
    } else {
      handleSwitch()
    }

    function handleSwitch() {
      updateLocation({ pathname, search })
      dispatch(changeLanguage(language))
      updateGoogleAnalytics(`switchLanguage`, {
        text: language
      })

      onClick && onClick()
    }
  }
}
