import React from 'react'
import classNames from 'classnames'

import shared from './skeleton.css'
import styles from './skeleton-button.css'

export const SkeletonButton = ({ margin, type, width }) => (
  <div
    className={classNames(shared.skeleton, styles[type])}
    style={{
      margin,
      width
    }}
  />
)
