import React from 'react'

import { useFilters } from 'hooks'
import { FilterBellow } from 'components/filters'

import styles from './sidebar-filters.css'

export const SidebarFilters = ({ content = {}, positioning = {} }) => {
  const { removeUnavailableFilters } = useFilters()
  const { filters } = content
  const { filtersPadding } = positioning

  return (
    <div className={styles.bellowWrapper}>
      {filters
        .filter(removeUnavailableFilters) // this causes filter bellow to remount every time
        .map(filter => (
          <FilterBellow
            buttonClass={styles.button}
            key={filter.name}
            optionsClass={styles.optionsWrapper}
            filter={filter}
            filtersPadding={filtersPadding}
          />
        ))}
    </div>
  )
}
