import React from 'react'
import classNames from 'classnames'

import { capitalize } from 'utils'

import shared from './skeleton.css'
import styles from './skeleton-text.css'

export const SkeletonText = ({
  lineHeight = 'normal',
  margin,
  size = 'bodyRegular',
  width
}) => (
  <div
    className={classNames(shared.animate, styles[size], styles[`lineHeight${capitalize(lineHeight)}`])}
    style={{
      margin,
      width
    }}
  />
)
