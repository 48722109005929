import React from 'react'

export const Loader = ({ size = 100 }) => {
  const halfSize = Math.trunc(size / 2)
  const quarterSize = Math.trunc(size / 4)

  return (
    <div
      className="css3Loader"
      data-testid="loader"
    > {/*  Deliberately absolute reference */}
      <svg
        height={size}
        width={size}
      >
        <circle
          cx={halfSize}
          cy={halfSize}
          fill="none"
          r={quarterSize}
          strokeWidth="5"
        />
      </svg>
    </div>
  )
}
