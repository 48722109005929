import React from 'react'
import classNames from 'classnames'

import { capitalize } from 'utils'
import { Link } from 'components/text'

import styles from './button.css'

export const Button = React.forwardRef(function Button({
  'data-testid': testId,
  children,
  className,
  config = {},
  content = {},
  disabled,
  nowrap,
  onClick,
  positioning = {},
  style = {},
  styling = {}
}, ref) {
  const {
    href,
    html,
    target
  } = content

  const {
    buttonHeight,
    buttonWidth
  } = positioning

  const {
    backgroundColor,
    borderRadius,
    color,
    fontSize,
    singleLine,
    textDecoration,
    type
  } = styling

  if (href) {
    return (
      <Link
        className={styles.buttonWrapper}
        target={target}
        to={href}
      >
        {buttonContent(`span`)}
      </Link>
    )
  }

  return buttonContent(config.tag || `button`)

  function buttonContent(tag) {
    const Tag = tag

    return (
      <Tag
        data-testid={testId}
        onClick={onClick}
        className={classNames(className,
          styles[`font${capitalize(fontSize)}`],
          {
            [styles.primary]: type === `primary`,
            [styles.secondary]: type === `secondary`,
            [styles.promoPrimary]: type === `promoPrimary`,
            [styles.select]: type === `select`,
            [styles.text]: type === `text`,
            [styles.lineHeightOne]: singleLine,
            [styles.disabled]: disabled,
            [styles.verticalSmall]: buttonHeight === `small`,
            [styles.verticalMedium]: buttonHeight === `medium`,
            [styles.verticalLarge]: buttonHeight === `large`,
            [styles.horizontalSmall]: buttonWidth === `small`,
            [styles.horizontalMedium]: buttonWidth === `medium`,
            [styles.horizontalLarge]: buttonWidth === `large`,
            [styles.horizontalFull]: buttonWidth === `full`,
            [styles.borderRadiusSmall]: borderRadius === `small`,
            [styles.borderRadiusMedium]: borderRadius === `medium`,
            [styles.borderRadiusLarge]: borderRadius === `large`,
            [styles.nowrap]: nowrap
          }
        )}
        disabled={disabled}
        style={{
          ...style,
          backgroundColor,
          color,
          textDecoration
        }}
        ref={ref}
      >
        {html || children}
      </Tag>
    )
  }
})
