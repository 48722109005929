import React from 'react'

import { Grid } from 'components/grid'
import { CategoryCard } from './category-card'

import styles from './category-cards.css'

export const CategoryCards = ({ items, positioning }) => {
  const { gap } = positioning

  return (
    <Grid
      className={styles.categoryCards}
      columns={items.length}
      gap={gap}
    >
      {items.map(item => (
        <CategoryCard
          key={item.path}
          {...item}
        />
      ))}
    </Grid>
  )
}
