import React from 'react'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import { capitalize } from 'utils'
import { cssMap } from 'css/css-map'
import styles from './layout.css'

export const Layout = ({
  children,
  className,
  layout,
  wrap,
  style,
  ...rest
}) => {
  if (!isEmpty(layout) || wrap) {
    return (
      <div
        {...layoutProps({ className, layout, style })}
        {...rest}
      >
        {children}
      </div>
    )
  }

  return children
}

function layoutProps({
  className,
  layout = {},
  style = {}
}) {
  const {
    backgroundColor,
    borderBottomSize,
    borderColor,
    borderSize,
    borderTopSize,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    width,
    ...rest
  } = layout

  return {
    className: layoutClasses(rest, className),
    style: {
      ...borders({ borderSize, borderBottomSize, borderTopSize }),
      backgroundColor,
      borderColor,
      paddingTop: paddingTop && `var(--layout${capitalize(paddingTop)})`,
      paddingBottom: paddingBottom && `var(--layout${capitalize(paddingBottom)})`,
      paddingLeft: paddingLeft && `var(--layout${capitalize(paddingLeft)})`,
      paddingRight: paddingRight && `var(--layout${capitalize(paddingRight)})`,
      width,
      ...style
    }
  }
}

function layoutClasses(
  {
    container,
    justifyContent,
    marginBottom,
    marginHorizontal,
    marginVertical,
    paddingVertical,
    paddingHorizontal
  } = {},
  className
) {
  const containerClass = container && cssMap({
    type: 'container',
    value: container
  })

  const marginBottomClass = marginBottom && cssMap({
    type: 'marginBottom',
    value: marginBottom
  })

  const paddingHorizontalClass = paddingHorizontal && cssMap({
    type: 'paddingHorizontal',
    value: paddingHorizontal
  })

  const paddingVerticalClass = paddingVertical && cssMap({
    type: 'paddingVertical',
    value: paddingVertical
  })

  const marginHorizontalClass = marginHorizontal && cssMap({
    type: 'marginHorizontal',
    value: marginHorizontal
  })

  const marginVerticalClass = marginVertical && cssMap({
    type: 'marginVertical',
    value: marginVertical
  })

  return classNames(
    styles.layout,
    className,
    containerClass,
    marginBottomClass,
    marginHorizontalClass,
    marginVerticalClass,
    paddingHorizontalClass,
    paddingVerticalClass, {
      [styles.justifyCenter]: justifyContent === `center`,
      [styles.justifyRight]: justifyContent === `right`
    }
  )
}

function borders({ borderSize, borderBottomSize, borderTopSize }) {
  if (borderSize) {
    return {
      border: `${borderSize} solid`
    }
  }

  return {
    borderBottom: borderBottomSize && `${borderBottomSize} solid`,
    borderTop: borderTopSize && `${borderTopSize} solid`
  }
}
