
import * as algolia from 'services/algolia'
import merge from 'lodash/merge'
import { getAlgoliaIndex } from 'global-content'
import { HITS_PER_PAGE } from 'utils/constants'

import {
  CANCEL_ACTION,
  LOAD_PRODUCTS,
  LOAD_PAGE_FILTERS,
  LOAD_FULL_FILTER_SET,
  UPDATE_FILTER_OPTIONS,
  UPDATE_PENDING_FILTER_OPTIONS
} from 'state/actions'

// Actions
export const cancelAction = actionId => ({
  type: CANCEL_ACTION,
  payload: actionId
})

export const loadFullFilterSet = language => ({
  type: LOAD_FULL_FILTER_SET,
  payload: getAlgoliaFilterSet(language)
})

export const updatePendingFilterOptions = options => ({
  type: UPDATE_PENDING_FILTER_OPTIONS,
  payload: options
})

export const updateFilteroptions = options => dispatch => {
  const formattedOptions = formatOptions(options)

  dispatch({
    type: UPDATE_FILTER_OPTIONS,
    payload: formattedOptions
  })

  dispatch(loadProducts())
}

// Additional functions for actions to work
async function getAlgoliaFilterSet(language) {
  const filters = await algolia.getFilters(language)
  return {
    language,
    filters
  }
}

export const loadProducts = actionId => (dispatch, getState) => {
  const language = getState().language.active
  const { filters } = getState().navigation.currentPage
  const { selectedSort, selectedFilters, query, page } = getState().listing
  const index = getAlgoliaIndex(selectedSort)

  dispatch({
    type: LOAD_PRODUCTS,
    payload: algolia.getProducts({
      language,
      index,
      options: {
        hitsPerPage: HITS_PER_PAGE,
        query,
        page: getPage(page),
        filters: merge({}, filters, selectedFilters)
      },
      selectedSort
    }).then(results => ({
      results,
      actionId
    }))
  })
}

export const loadFiltersForPage = () => (dispatch, getState) => {
  const language = getState().language.active
  const { filters } = getState().navigation.currentPage
  const { selectedSort, query } = getState().listing
  const index = getAlgoliaIndex(selectedSort)

  dispatch({
    type: LOAD_PAGE_FILTERS,
    payload: algolia.getProducts({
      language,
      format: false,
      index,
      options: {
        attributesToRetrieve: [`objectID`],
        hitsPerPage: 1,
        query,
        filters
      }
    }).then(results => ({
      results,
      language
    }))
  })
}

// we store our page references as 1-indexed. Algolia wants it as 0-index
function getPage(page) {
  return page - 1
}

function formatOptions({ filters, ...rest }) {
  const FILTERSMAP = algolia.CREATEFILTERSMAP()

  let build = {
    ...rest
  }

  if (filters) {
    build.filters = {}

    Object.entries(filters).forEach(([key, value]) => {
      if (
        FILTERSMAP[key] &&
        [`facet`, `unfaceted`].includes(FILTERSMAP[key].filterType)
      ) {
        build.filters[key] = formatValues(value)
      } else {
        build.filters[key] = value
      }
    })
  }

  return build
}

function formatValues(value) {
  if (Array.isArray(value)) {
    return value
  }

  return [value]
}
