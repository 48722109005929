import * as Sentry from '@sentry/browser'
import { getMetaOptions } from 'global-content'

export function sentrySetup() {
  if (!window.location.hostname.includes('localhost') && !window.location.hostname.includes('localised-test') && getMetaOptions('integrations.sentry')) {
    const { key, project } = getMetaOptions('integrations.sentry')

    Sentry.init({
      beforeSend,
      dsn: `https://${key}@sentry.io/${project}`,
      environment: getMetaOptions(`environment`),
      release: window.$RELEASE,
      whitelistUrls: [
        `${window.location.host}/js`
      ]
    })

    Sentry.configureScope(function(scope) {
      scope.setUser({ id: window.$shoppingSessionId.value })
    })
  }

  // https://github.com/getsentry/sentry-javascript/issues/2380
  // https://github.com/getsentry/sentry-javascript/issues/2210#issuecomment-540553675
  function beforeSend(event) {
    // (try catch is only so you dont have to assert any null values in that long chain)
    try {
      if (event.extra.__serialized__.detail.reason.message === 'Extension context invalidated.') return null
    } catch (e) {
      return event
    }
    return event
  }
}
