import React from 'react'
import classNames from 'classnames'

import { capitalize } from 'utils'

import { SkeletonText } from 'components/skeleton'
import { HtmlText } from 'components/text'

import styles from './heading.css'

export const Heading = React.forwardRef(function Heading({
  'data-testid': testId,
  config = {},
  content = {},
  itemProp,
  styling = {}
}, ref) {
  const {
    tag
  } = config

  const {
    html
  } = content

  const {
    color,
    fontFamily,
    fontSize,
    fontStyle,
    fontWeight,
    lineHeight,
    textAlign,
    universalColor,
    uppercase
  } = styling

  const tagMap = {
    medium: 'h2',
    small: 'h3',
    xs: 'h4',
    xsRegular: 'h4'
  }

  const Tag = tag || tagMap[fontSize] || 'h1'

  return (
    <Tag
      data-testid={testId}
      className={classNames({
        [styles.primary]: fontFamily === `primary`,
        [styles.secondary]: fontFamily === `secondary`,
        [styles.custom]: fontFamily === `custom`,
        [styles.xxl]: fontSize === `xxl`,
        [styles.xl]: fontSize === `xl`,
        [styles.large]: fontSize === `large`,
        [styles.medium]: fontSize === `medium`,
        [styles.small]: fontSize === `small`,
        [styles.xs]: fontSize === `xs`,
        [styles.xsRegular]: fontSize === `xsRegular`,
        [styles.universalColor]: universalColor
      })}
      style={{
        color,
        fontStyle,
        fontWeight,
        lineHeight,
        textAlign,
        textTransform: uppercase && `uppercase`
      }}
      ref={ref}
    >
      {html &&
        <HtmlText
          html={html}
          itemProp={itemProp}
        />
      }
      {!html &&
        <SkeletonText
          lineHeight="small"
          size={`heading${capitalize(fontSize)}`}
          width="50rem"
        />
      }
    </Tag>
  )
})
