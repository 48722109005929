import { useMediaQuery } from 'react-responsive'
import mediaSizes from 'css/media-sizes.json'

import {
  DESKTOP_KEY,
  DESKTOP_SMALL_KEY,
  TABLET_LARGE_KEY,
  TABLET_MEDIUM_KEY,
  TABLET_SMALL_KEY,
  MOBILE_LARGE_KEY
} from 'utils/constants'

const mediaQueries = {
  [DESKTOP_KEY]: {
    min: mediaSizes[DESKTOP_SMALL_KEY] + 1,
    max: 9999
  },
  [DESKTOP_SMALL_KEY]: {
    min: mediaSizes[TABLET_LARGE_KEY] + 1,
    max: mediaSizes[DESKTOP_SMALL_KEY]
  },
  [TABLET_LARGE_KEY]: {
    min: mediaSizes[TABLET_MEDIUM_KEY] + 1,
    max: mediaSizes[TABLET_LARGE_KEY]
  },
  [TABLET_MEDIUM_KEY]: {
    min: mediaSizes[TABLET_SMALL_KEY] + 1,
    max: mediaSizes[TABLET_MEDIUM_KEY]
  },
  [TABLET_SMALL_KEY]: {
    min: mediaSizes[MOBILE_LARGE_KEY] + 1,
    max: mediaSizes[TABLET_SMALL_KEY]
  },
  [MOBILE_LARGE_KEY]: {
    min: 1,
    max: mediaSizes[MOBILE_LARGE_KEY]
  }
}

export const Responsive = ({ children, deviceRange = [] }) => {
  const [largest, smallest] = deviceRange

  const isInDeviceSelection = useMediaQuery({
    minWidth: mediaQueries[smallest]?.min || 1,
    maxWidth: mediaQueries[largest].max
  })

  return isInDeviceSelection ? children : null
}
