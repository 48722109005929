import React from 'react'

import { Link } from 'components/text'

import styles from './link-set.css'

export const LinkSet = ({ items, onClick }) => {
  return (
    <div className={styles.setWrapper}>
      <ul className={styles.setWrapper}>
        {items.map((route) => (
          <li
            className={styles.item}
            key={route.label}
          >
            <Link
              to={getPath(route)}
              onClick={route.onClick || onClick}
            >
              {route.label}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )

  function getPath(route) {
    if (route.subItems) {
      return route.subItems[0].path
    }

    return route.path
  }
}
