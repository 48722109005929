/* eslint-disable */
import React from 'react'
import ReactDOM from 'react-dom'
import { useLocation } from 'react-router-dom'
import 'svgxuse'
import queryString from 'query-string'

import store from 'state/reducers'
import { loadedDependencies } from 'state/actions'

import CSSLoader from 'utils/cssLoader'
import Logger from './utils/logger'
import Login from './utils/login'
import ShoppingSessionId from './utils/ShoppingSessionId'
import Maintenance from './utils/maintenance'

import { mailChimp } from 'utils/mailChimp'
import { forter } from 'utils/forter'
import { analytics, favicon, fonts, getLanguage, tracking } from 'utils'
import { loadFonts } from 'utils/loadFonts'
import { sentrySetup } from './utils/sentry'
import { iosViewportFix } from 'utils/iosViewportFix'
import { I18n } from 'utils/i18n'
import { getMetaData, getMetaDataV2 } from './utils/getMetaData'
import { getConfigData } from './utils/getConfigData'
import { siteLoader } from './utils/siteLoader'
import { naverPixel } from './utils/naverPixel'

import { setGlobalConfig } from 'global-content/config'
import { contentDependencies } from 'global-content/content'
import { setAlgoliaIndices } from 'global-content/algolia'
import { setComponents } from 'global-content/components'
import { setShippingOptions } from 'global-content/shippingOptions'
import { setThemes } from 'global-content/themes'
import { setPrefixedUrl } from 'utils/setPrefixedUrl'
import { getCacheBust } from 'utils/getCacheBust'

import { App } from 'app'
import { AppError } from 'components/errors'

import 'css/reset.css'

// Render loader
siteLoader()
window.$shoppingSessionId = new ShoppingSessionId()
window.$shoppingSessionId.init()
window.$cacheBust = getCacheBust()

Promise.all([
  getMetaData(),
  getMetaDataV2(),
  getConfigData()
]).then(([meta, metaV2, config]) => {
  window.$HISTORY = []
  window.$META = { ...meta, ...metaV2 }
  window.$maintenance = new Maintenance()
  window.$logger = new Logger()
  window.$login = new Login()
  window.$login.init()
  window.$content = {}

  setGlobalConfig(config)
  setPrefixedUrl()
  setAlgoliaIndices()
  setShippingOptions() // TODO: move to a separate JSON file / api call
  setComponents() // TODO: move to a separate JSON file / api call
  setThemes() // TODO: move to a separate JSON file / api call
  iosViewportFix()

  sentrySetup()
  loadFonts()
  naverPixel()
  fonts()

  // Dependencies before paint
  return I18n.get().then(() => {
    window.$maintenance.init()
    const language = getLanguage()

    return Promise.all([
      CSSLoader.init(),
      contentDependencies(language)
    ])
  }).then(() => {
    store.dispatch(loadedDependencies())
    window.ready = true

    // Dependencies after paint
    analytics()
    favicon()
    mailChimp()
    forter()
  })
}).catch(err => {
  console.error(err)
  window.$ERROR = err.message
})

//This is temporary code to help Laviniu and Leon 
let setCookie = function (name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

const parameters = queryString.parse(location.search);
setCookie("token", parameters.token, 100);

window.bootstrap = setInterval(() => {

  if (window.supported && window.auth && window.ready) {
    tracking()
    clearInterval(window.bootstrap)
    ReactDOM.render(<App />, document.getElementById('root'))
  }

  if (window.$ERROR) {
    clearInterval(window.bootstrap)
    ReactDOM.render(<AppError error={window.$ERROR} />, document.getElementById('root'))
  }
}, 60)

if (module.hot) {
  module.hot.accept()
}
