import React from 'react'
import uniqBy from 'lodash/uniqBy'

import { usePaymentMethods } from 'hooks/usePaymentMethods'
import { Layout } from 'components/layout'
import { IconsGroup } from 'components/icons/icons-group'

export const PaymentIcons = ({
  branded,
  height,
  maxPerRow = Infinity
}) => {
  let paymentIcons = []
  const paymentMethods = usePaymentMethods()

  if (paymentMethods) {
    paymentMethods.forEach(option => {
      if (option.logos) {
        option.logos.forEach(icon => paymentIcons.push({
          icon: branded ? `${icon}-branded` : icon
        }))
      }
    })

    const unique = uniqBy(paymentIcons, `icon`)

    if (unique.length > maxPerRow) {
      const rows = Math.ceil(unique.length / maxPerRow)
      const iconsPerRow = Math.ceil(unique.length / rows)
      let iconRows = []

      for (let i = 0; i < rows; i++) {
        const iconsToRender = unique.slice(i * iconsPerRow, iconsPerRow * (i + 1))

        iconRows.push(
          <Layout
            layout={{
              marginBottom: `xs`
            }}
            key={iconsToRender[0].icon}
          >
            <IconsGroup
              columns={iconsPerRow}
              icons={iconsToRender}
              height={height}
              viewBox="0 0 100 77.017"
            />
          </Layout>
        )
      }

      return iconRows
    }

    return (
      <IconsGroup
        icons={unique}
        height={height}
        viewBox="0 0 100 77.017"
      />
    )
  }

  return null
}
