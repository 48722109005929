import mergeWith from 'lodash/mergeWith'
import get from 'lodash/get'

import { customizer } from 'utils/mergeCustomizer'

export function setThemes() {
  window.$themes = mergeWith({}, defaultThemes, window.$CONFIG.themes, customizer)
}

export function getThemes(key) {
  return get(window.$themes, key)
}

export const defaultThemes = {
  font: [],
  layout: []
}
