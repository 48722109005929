import React from 'react'
import { useDispatch } from 'react-redux'

import { addComponents } from 'state/actions'
import { PageTemplate } from 'pages'
import { PageBody } from 'components/scaffold'
import { Factory } from 'components/factory'
import { BrandIndexes } from 'pages/brands'

export const Brands = () => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(addComponents({
      brandIndexes: BrandIndexes
    }))
  }, [])

  return (
    <PageTemplate template="brands-index">
      {BrandsComponent}
    </PageTemplate>
  )
}

const BrandsComponent = ({ items, ...props }) => {
  return (
    <PageBody {...props}>
      <Factory items={items} />
    </PageBody>
  )
}
