export function localPercent(value, language) {
  // CHINESE PUT PERCENTAGES AS PERCENT REMAINING
  // RATHER THAN PERCENT OFF
  if (language === `zh`) {
    const remaining = 100 - value

    if ((remaining / 10) % 1 === 0) {
      return (100 - value) / 10 // so 80% remaining is 8
    }

    return remaining
  }

  return value
}
