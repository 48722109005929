import React from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import { getComponentOptions } from 'global-content'
import { I18n } from 'utils/i18n'

import { useFilters } from 'hooks'
import { Svg } from 'components/icons'
import { SkeletonButton, SkeletonText } from 'components/skeleton'

import styles from './paginator.css'

export const webLabels = {
  pages: 'WEB.LISTING.PAGINATION.PAGES'
}

export const Paginator = () => {
  const { applyPage } = useFilters()
  const { size } = getComponentOptions(`paginator`)
  const loading = useSelector(state => state.listing.loading)
  const page = Number(useSelector(state => state.listing.page))
  const pages = useSelector(state => state.listing.resultsSummary.pages)

  if (loading) {
    return renderSkeleton()
  }

  const isLastPage = page >= pages
  const isFirstPage = page <= 1

  if (pages) {
    return (
      <div
        className={styles.paginator}
        data-testid="paginator"
      >
        <button
          className={classNames(styles.button, {
            [styles.disabled]: isFirstPage
          })}
          data-testid="previous-btn"
          disabled={isFirstPage}
          onClick={() => handleClick(page - 1)}
        >
          <Svg icon="angle_left" />
        </button>

        <span className={classNames({ [styles[size]]: size })}>
          {I18n.t(webLabels.pages, {
            replace: {
              current: page,
              total: pages
            }
          })}
        </span>

        <button
          className={classNames(styles.button, {
            [styles.disabled]: isLastPage
          })}
          data-testid="next-btn"
          disabled={isLastPage}
          onClick={() => handleClick(page + 1)}
        >
          <Svg icon="angle_right" />
        </button>
      </div>
    )
  }

  return null

  function renderSkeleton() {
    return (
      <div
        className={styles.paginator}
        data-testid="skeleton-paginator"
      >
        <SkeletonButton
          type="circle"
          width="3rem"
        />
        <SkeletonText
          margin="0 1rem"
          width="5rem"
        />
        <SkeletonButton
          type="circle"
          width="3rem"
        />
      </div>
    )
  }

  function handleClick(number) {
    // assume they want to be scrolled back up if they are scrolled more than their viewport height
    if (window.pageYOffset > window.innerHeight) {
      window.scrollTo(0, 0)
    }

    applyPage(number)
  }
}
