import { normalizeCartResponse } from 'models/cart-information'

import { adyenDeviceFingerprint } from 'utils'
import {
  BRANDCODE_CREDITCARD,
  BRANDCODE_PAYPAL,
  BRANDCODE_PAYPAL_EXPRESS,
  BRANDCODE_GOOGLEPAY_EXPRESS,
  BRANDCODE_GOOGLEPAY,
  BRANDCODE_APPLEPAY,
  BRANDCODE_APPLEPAY_EXPRESS,
  BRANDCODE_AFTERPAY
} from 'utils/constants'
import * as api from 'services/api'
import * as content from 'services/content'
import { updateGoogleAnalytics } from 'services/analytics'

export const ADD_CART_ITEM = 'ADD_CART_ITEM'
export const ADD_COMPONENTS = `ADD_COMPONENTS`
export const ADD_ORDER_MESSAGE = `ADD_ORDER_MESSAGE`
export const APPLY_VOUCHER = 'APPLY_VOUCHER'
export const CANCEL_ACTION = `CANCEL_ACTION`
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
export const CLOSE_PULLOUT = 'CLOSE_PULLOUT'
export const CLEAR_ORDER_MESSAGES = `CLEAR_ORDER_MESSAGES`
export const DETAILS_ADD_MESSAGE = 'DETAILS_ADD_MESSAGE'
export const DETAILS_PRELOAD = 'DETAILS_PRELOAD'
export const DETAILS_REMOVE_MESSAGE = 'DETAILS_REMOVE_MESSAGE'
export const DISABLE_CHECKOUT_SUBMIT = `DISABLE_CHECKOUT_SUBMIT`
export const ENABLE_TEST_PAYMENTS = `ENABLE_TEST_PAYMENTS`
export const FAVORITE_ADD = 'FAVORITE_ADD'
export const FAVORITE_REMOVE = 'FAVORITE_REMOVE'
export const FINGERPRINT_DEVICE = 'FINGERPRINT_DEVICE'
export const GET_PRODUCT = 'GET_PRODUCT'
export const HIDE_MOBILE_SEARCH = 'HIDE_MOBILE_SEARCH'
export const LOAD_BRANDS = 'LOAD_BRANDS'
export const LOAD_CART_CONTENTS = 'LOAD_CART_CONTENTS'
export const LOAD_CHECKOUT_SCHEMA = 'LOAD_CHECKOUT_SCHEMA'
export const LOAD_CONTENT = 'LOAD_CONTENT'
export const LOAD_COUNTRIES = 'LOAD_COUNTRIES'
export const LOAD_FULL_FILTER_SET = 'LOAD_FULL_FILTER_SET'
export const LOAD_PAGE_FILTERS = 'LOAD_PAGE_FILTERS'
export const LOAD_PRODUCTS = 'LOAD_PRODUCTS'
export const LOAD_SCHEMA = 'LOAD_SCHEMA'
export const LOADED_DEPENDENCIES = 'LOADED_DEPENDENCIES'
export const NO_PRODUCT = 'NO_PRODUCT'
export const OPEN_PULLOUT = 'OPEN_PULLOUT'
export const OVERLAY_OFF = 'OVERLAY_OFF'
export const OVERLAY_ON = 'OVERLAY_ON'
export const PLACE_ORDER = 'PLACE_ORDER'
export const PRERENDER_READY = 'PRERENDER_READY'
export const RECREATE_CART = `RECREATE_CART`
export const REMOVE_FROM_EMAIL_LIST = 'REMOVE_FROM_EMAIL_LIST'
export const REMOVE_REJECTION_ERROR = 'REMOVE_REJECTION_ERROR'
export const REMOVE_VOUCHER = 'REMOVE_VOUCHER'
export const REMOVE_VOUCHER_MESSAGE = 'REMOVE_VOUCHER_MESSAGE'
export const RESTART_SESSION = 'RESTART_SESSION'
export const ROUTE_CHANGE = 'ROUTE_CHANGE'
export const SAVE_SHIPPING_SELECTION = 'SAVE_SHIPPING_SELECTION'
export const SET_ACTIVE_TOOLTIP = 'SET_ACTIVE_TOOLTIP'
export const SET_INCHES = 'SET_INCHES'
export const SET_PENDING_QUANTITY = 'SET_PENDING_QUANTITY'
export const SHOW_MOBILE_SEARCH = 'SHOW_MOBILE_SEARCH'
export const START_EXTERNAL_PAYMENT_METHOD = 'START_EXTERNAL_PAYMENT_METHOD'
export const START_SESSION = 'START_SESSION'
export const STORE_ORDER_REFERENCE = 'STORE_ORDER_REFERENCE'
export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM'
export const UPDATE_NAVIGATION = `UPDATE_NAVIGATION`
export const UPDATE_PAYMENT_METHOD = 'UPDATE_PAYMENT_METHOD'
export const UPDATE_SELECTED_OPTION = 'UPDATE_SELECTED_OPTION'
export const UPDATE_FILTER_OPTIONS = `UPDATE_FILTER_OPTIONS`
export const UPDATE_PENDING_FILTER_OPTIONS = `UPDATE_PENDING_FILTER_OPTIONS`
export const UPDATE_USER_LANGUAGE_PREFERENCE = 'UPDATE_USER_LANGUAGE_PREFERENCE'

export const addComponents = components => ({
  type: ADD_COMPONENTS,
  payload: components
})

// On start-up
export const loadedDependencies = config => ({
  type: LOADED_DEPENDENCIES
})

export const fingerprintDevice = (elementId) => ({
  type: FINGERPRINT_DEVICE,
  payload: adyenDeviceFingerprint(elementId)
})

// Sessions
export const startSession = () => ({
  type: START_SESSION,
  payload: api.createSession()
})

export const restartSession = () => ({
  type: RESTART_SESSION,
  payload: api.restartSession()
})

// Language
export const changeLanguage = id => ({
  type: CHANGE_LANGUAGE,
  payload: id
})

export const updateUserLanguagePreference = lang => ({
  type: UPDATE_USER_LANGUAGE_PREFERENCE,
  payload: api.put({
    path: `session/updateProperties`,
    params: {
      paramId: `lang`,
      value: lang
    }
  })
})

// Overlay
export const overlayOff = overlayType => ({
  type: OVERLAY_OFF,
  payload: overlayType
})

export const overlayOn = overlayType => ({
  type: OVERLAY_ON,
  payload: overlayType
})

// Pullout
export const openPullout = pulloutContent => ({
  type: OPEN_PULLOUT,
  payload: pulloutContent
})

export const closePullout = () => ({
  type: CLOSE_PULLOUT
})

export const hideMobileSearch = () => ({
  type: HIDE_MOBILE_SEARCH
})

export const showMobileSearch = () => ({
  type: SHOW_MOBILE_SEARCH
})

// Favorites
export const favoriteAdd = productId => ({
  type: FAVORITE_ADD,
  payload: productId
})

export const favoriteRemove = productId => ({
  type: FAVORITE_REMOVE,
  payload: productId
})

// Cart
export const addOrderMessage = statusCode => ({
  type: ADD_ORDER_MESSAGE,
  payload: statusCode
})

export const clearOrderMessages = () => ({
  type: CLEAR_ORDER_MESSAGES
})

export const disableCheckoutSubmit = () => ({
  type: DISABLE_CHECKOUT_SUBMIT
})

export const loadCartContents = () => ({
  type: LOAD_CART_CONTENTS,
  payload: api.loadCartContents()
    .then(response => normalizeCartResponse(response))
})

export const addCartItem = ({ quantity, product, skuOptions }) => {
  return ({
    type: ADD_CART_ITEM,
    payload: api.addCartItem({ quantity, product, skuOptions })
      .then(response => ({
        cartResponse: normalizeCartResponse(response),
        product,
        skuOptions,
        quantity
      }))
      .then(handleAnalytics)
  })
}

export const updateCartItem = ({ cartItem, checkoutStep, quantity }) => ({
  type: UPDATE_CART_ITEM,
  payload: api.updateCartItem({ cartItem, quantity })
    .then(response => ({
      cartResponse: normalizeCartResponse(response),
      cartItem,
      checkoutStep,
      quantity
    }))
    .then(handleAnalytics)
})

export const recreateCart = ({ cartPublicId }) => ({
  type: RECREATE_CART,
  payload: api.recreateCart({ cartPublicId })
    .then(response => normalizeCartResponse(response))
})

export const loadContent = file => ({
  type: LOAD_CONTENT,
  payload: content.loadContent(file)
})

// Details
export const detailsPreload = (variant, selectedSlugs) => ({
  type: DETAILS_PRELOAD,
  payload: {
    selectedSlugs,
    variant
  }
})

export const detailsAddMessage = payload => ({
  type: DETAILS_ADD_MESSAGE,
  payload
})

export const detailsRemoveMessage = payload => ({
  type: DETAILS_REMOVE_MESSAGE,
  payload
})

export const getProduct = ({ callback, productId, language }) => async(dispatch, getState) => {
  await dispatch({
    type: GET_PRODUCT,
    payload: api.getProduct({ productId, language })
  })

  callback(getState)
}

export const noProduct = () => ({
  type: NO_PRODUCT
})

export const updateSelectedDetailOption = ({ level, value, urlManager }) => async(dispatch) => {
  await dispatch({
    type: UPDATE_SELECTED_OPTION,
    payload: {
      level,
      value
    }
  })

  urlManager({
    method: `replace`,
    params: {
      append: {
        [level]: value
      },
      remove: ['sku']
    }
  })
}

export const setPendingQuantity = payload => ({
  type: SET_PENDING_QUANTITY,
  payload
})

// API Loading
export const loadCountries = () => ({
  type: LOAD_COUNTRIES,
  payload: api.loadCountries()
})

export const updateShippingSelection = (id) => async(dispatch) => {
  await dispatch(saveShippingSelection(id))
  dispatch(loadCartContents())
}

export const saveShippingSelection = (id) => ({
  type: SAVE_SHIPPING_SELECTION,
  payload: api.saveShippingOption(id)
})

// Payment
export const updatePaymentMethod = (method) => ({
  type: UPDATE_PAYMENT_METHOD,
  payload: method
})

// Schemas
export const loadSchema = (schema, name) => ({
  type: LOAD_SCHEMA,
  payload: content.loadSchema(schema, name)
})

export const loadCheckoutSchema = language => ({
  type: LOAD_CHECKOUT_SCHEMA,
  payload: content.loadCheckoutSchema(language)
})

// Vouchers
export const applyVoucher = (voucher) => ({
  type: APPLY_VOUCHER,
  payload: api.applyVoucher(voucher)
    .then(response => normalizeCartResponse(response))

})

export const removeVoucher = voucher => ({
  type: REMOVE_VOUCHER,
  payload: api.removeVouchers(voucher)
    .then(response => normalizeCartResponse(response))
})

export const removeVoucherMessage = () => ({
  type: REMOVE_VOUCHER_MESSAGE
})

// Payment
export const expressPaymentMethod = ({ brandCode }) => {
  return function(dispatch, getState) {
    if (brandCode === BRANDCODE_PAYPAL_EXPRESS) {
      dispatch(startPaymentMethod(brandCode))
    } else {
      dispatch(placeOrder({
        brandCode,
        customerInformation: {},
        deviceFingerprint: getState().cart.deviceFingerprint,
        language: getState().language.active
      }))
    }
  }
}

export const startPaymentMethod = brandCode => ({
  type: START_EXTERNAL_PAYMENT_METHOD,
  payload: api.checkout({
    paymentMethod: brandCode,
    userAgent: window.navigator.userAgent
  })
})

export const storeOrderReference = orderReference => ({
  type: STORE_ORDER_REFERENCE,
  payload: orderReference
})

export const placeOrder = ({
  customerInformation,
  brandCode,
  deviceFingerprint,
  orderReference,
  language
}) => ({
  type: PLACE_ORDER,
  payload: async() => {
    const combinedPayloadOptions = {
      ...customerInformation,
      paymentMethod: customerInformation.paymentMethod || brandCode,
      deviceFingerprint
    }

    let response
    switch (brandCode) {
    case BRANDCODE_PAYPAL_EXPRESS:
      response = await api.checkoutWithPaypal(combinedPayloadOptions, orderReference)
      break
    case BRANDCODE_GOOGLEPAY:
      response = await api.checkoutWithGooglePay(combinedPayloadOptions, { language })
      break
    case BRANDCODE_GOOGLEPAY_EXPRESS:
      response = await api.checkoutWithGooglePay(
        combinedPayloadOptions,
        {
          language,
          shippingAddressRequired: true
        }
      )
      break
    case BRANDCODE_AFTERPAY:
      response = await api.checkout(combinedPayloadOptions)
      break
    case BRANDCODE_PAYPAL:
    case BRANDCODE_CREDITCARD:
    case BRANDCODE_APPLEPAY:
    case BRANDCODE_APPLEPAY_EXPRESS:
    default:
      response = await api.checkout(combinedPayloadOptions)
      break
    }

    if (response?.code === 'SUCCESS') {
      return response
    } else {
      throw response
    }
  }
})

export const removeRejectionError = () => ({
  type: REMOVE_REJECTION_ERROR
})

// PrerenderReady

export const prerenderReady = selector => ({
  type: PRERENDER_READY,
  payload: selector
})

// tooltips
export const setActiveTooltip = (tooltip) => ({
  type: SET_ACTIVE_TOOLTIP,
  payload: tooltip
})

// User Preferences
export const enableTestPayments = () => ({
  type: ENABLE_TEST_PAYMENTS
})

export const setInches = (inches) => ({
  type: SET_INCHES,
  payload: inches
})

function handleAnalytics(response) {
  const {
    cartItem, // updateCartItem
    cartResponse,
    checkoutStep, // in checkout
    product, // addCartItem
    quantity,
    skuOptions // addCartItem
  } = response

  const data = {
    cartItem,
    product,
    skuOptions,
    quantity
  }

  if (checkoutStep) {
    updateGoogleAnalytics('checkout', {
      items: cartResponse.items,
      step: checkoutStep
    })
  }

  if (quantity === 0) {
    updateGoogleAnalytics('removeFromCart', data)
  } else {
    updateGoogleAnalytics('addToCart', data)
  }

  // return response to resolve actions
  return response
}
