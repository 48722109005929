import queryString from 'query-string'
import cloneDeep from 'lodash/cloneDeep'

import { MAX_LINE_ITEMS } from 'utils/constants'
import { logToCloudWatch } from 'utils/logToCloudWatch'
import { I18n } from 'utils/i18n'
import {
  ADD_CART_ITEM,
  DETAILS_ADD_MESSAGE,
  DETAILS_PRELOAD,
  DETAILS_REMOVE_MESSAGE,
  GET_PRODUCT,
  NO_PRODUCT,
  SET_PENDING_QUANTITY,
  UPDATE_SELECTED_OPTION
} from 'state/actions'

import { toDetailProduct } from 'models/product'

const initialState = {
  infoMessages: [],
  levelOptions: {},
  loadingProduct: true,
  notFound: false,
  pendingQuantity: 1,
  product: undefined,
  selectedSlugs: {},
  skuOptions: {}
}

export const webLabels = {
  maxlineItems: `WEB.CART.MAXLINEITEMS.TEXT`,
  genericError: `WEB.PDP.ERROR`
}

const details = (state = initialState, action) => {
  switch (action.type) {
  case `${ADD_CART_ITEM}_REJECTED`:
    return {
      ...state,
      infoMessages: handleInfoMessages(state.infoMessages, getErrorMessage(action.payload))
    }
  case DETAILS_ADD_MESSAGE: {
    return {
      ...state,
      infoMessages: handleInfoMessages(state.infoMessages, action.payload)
    }
  }
  case DETAILS_REMOVE_MESSAGE: {
    const infoMessages = state.infoMessages.filter(message => message.text !== action.payload)

    return {
      ...state,
      infoMessages
    }
  }
  case SET_PENDING_QUANTITY: {
    return {
      ...state,
      pendingQuantity: action.payload
    }
  }
  case UPDATE_SELECTED_OPTION: {
    const selectedSlugs = {
      ...state.selectedSlugs,
      [action.payload.level]: action.payload.value
    }
    const levelOptions = getOptionValues(state.product, selectedSlugs)

    return {
      ...state,
      infoMessages: [],
      levelOptions,
      selectedSlugs,
      skuOptions: getSkuOptions(levelOptions, selectedSlugs)
    }
  }
  case `${GET_PRODUCT}_PENDING`: {
    return {
      ...state,
      loadingProduct: true,
      notFound: false
    }
  }
  case `${GET_PRODUCT}_FULFILLED`: {
    // note to self: this is product api payload
    const product = toDetailProduct(action.payload.product)
    const selectedSlugs = getSelectedSlugs(product)
    const levelOptions = getOptionValues(product, selectedSlugs)

    return {
      infoMessages: [],
      loadingProduct: false,
      levelOptions,
      notFound: false,
      pendingQuantity: 1,
      product,
      selectedSlugs,
      skuOptions: getSkuOptions(levelOptions, selectedSlugs)
    }
  }
  case DETAILS_PRELOAD: {
    const { variant, selectedSlugs } = action.payload

    return {
      ...state,
      infoMessages: [],
      loadingProduct: true,
      notFound: false,
      pendingQuantity: 1,
      levelOptions: {
        color: variant.options
      },
      skuOptions: {
        availability: variant.availability,
        price: variant.price
      },
      product: variant,
      selectedSlugs
    }
  }
  case NO_PRODUCT:
  case `${GET_PRODUCT}_REJECTED`:
    logToCloudWatch('Product-API-fetch-rejected')

    return {
      ...initialState,
      loadingProduct: false,
      notFound: true
    }
  default:
    return state
  }
}

function getSkuOptions(levelOptions, selectedSlugs) {
  let skuOptions = {
    price: {
      list: {},
      sale: {}
    }
  }

  for (const slug in selectedSlugs) {
    skuOptions = {
      ...skuOptions,
      ...levelOptions[slug].find(option => selectedSlugs[slug] === option.slug),
      options: selectedSlugs
    }
  }

  return skuOptions
}

function getSelectedSlugs(product) {
  const { options, optionLevels } = product
  const { sku, ...levels } = queryString.parse(window.location.search)
  let selectedSlugs = { ...levels }

  if (sku) {
    const selectedOptions = getSlugsFromSku(options, optionLevels, sku)

    if (selectedOptions) {
      return getSlugsFromSku(options, optionLevels, sku)
    }
  }

  return getSlugsForFirstVariant(product, selectedSlugs)
}

export function getSlugsFromSku(
  tree,
  keys,
  sku,
  depth = 0,
  selected = {}
) {
  // returns selected option slugs (ex: { color: 'black', size: 's' }) for the
  // corresponding sku by DFS-traversing options tree
  const level = keys[depth]
  for (let i = 0; i < tree.length; i++) {
    const newSelected = { ...selected, [level]: tree[i].slug } // add current level/slug
    if ('options' in tree[i]) {
      const found = getSlugsFromSku(tree[i].options, keys, sku, depth + 1, newSelected)
      if (found) { // this controls that only matching sku gets bubbled up
        return found
      }
    } else if ('sku' in tree[i] && tree[i].sku === sku) {
      return newSelected
    }
  }
  return null
}

export function getSlugsForFirstVariant(detailProduct, selected = {}) {
  // returns the option slugs (ex: { color: 'black', size: 's' }) for first
  // variant for given detailProduct.
  // if selected options is provided, it will try use it (when valid)
  let firstVariantOptions = {}
  let options = detailProduct.options

  for (let i = 0; i < detailProduct.optionLevels.length; i++) {
    let currLevel = detailProduct.optionLevels[i]
    if (currLevel in selected) {
      // verifies that selected is actually an valid option
      const o = options.find(option => option.slug === selected[currLevel])

      if (o) {
        firstVariantOptions[currLevel] = selected[currLevel]
        options = o.options
        continue
      }
      // falls thru when not found
    }
    // else use the first one
    firstVariantOptions[currLevel] = options[0].slug
    options = options[0].options
  }

  return firstVariantOptions
}

export function getOptionValues(detailProduct, selected = {}) {
  // returns option values to populate option selectors. it will use the
  // selected option (when valid) to go to the next level. otherise, it
  // will select the first option of that level

  let values = {}
  let options = detailProduct.options

  for (let i = 0; i < detailProduct.optionLevels.length; i++) {
    let currLevel = detailProduct.optionLevels[i]
    values[currLevel] = trimOptionsNode(options)
    if (currLevel in selected) {
      // verifies that selected is actually an valid option
      const validOption = options.find(option => option.slug === selected[currLevel])

      if (validOption) {
        options = validOption.options // go to selected's children
        continue
      }
      // falls thru when not found
    }
    options = options[0].options // go to first's children
  }
  return values

  function trimOptionsNode(optionsNode) {
    // trims subsequent levels
    let ret = cloneDeep(optionsNode) // cloning since we are deleting
    for (let i = 0; i < ret.length; i++) {
      delete ret[i].options // trims subsequent levels
    }
    return ret
  }
}

function handleInfoMessages(existingMessages, message) {
  if (existingMessages.find(({ text }) => message.text === text)) {
    return existingMessages
  }

  return [...existingMessages, message]
}

function getErrorMessage({ errorCode }) {
  const errorMap = {
    MAX_LINE_ITEMS_EXCEEDED: {
      text: I18n.t(webLabels.maxlineItems, {
        replace: {
          maxLineItems: MAX_LINE_ITEMS
        }
      }),
      type: `warning`
    }
  }

  if (errorMap[errorCode]) {
    return errorMap[errorCode]
  }

  return {
    text: I18n.t(webLabels.genericError),
    type: `error`
  }
}

export default details
