import { getMetaOptions } from 'global-content/config'
import { promoInterpolation } from './promo-interpolation'

export function formatCampaigns(campaigns, language) {
  const filteredCampaigns = campaigns.map(filterCampaign).filter(Boolean)

  return filteredCampaigns.map(campaign => {
    return promoInterpolation(campaign, language)
  })
}

function filterCampaign(campaign) {
  const promotion = campaign.promotions.find(promo => promo.siteTag === getMetaOptions(`siteTag`))

  if (promotion) {
    return {
      ...campaign,
      promotion
    }
  }
}

// "WEB.PROMO.TIER.ITEM": "BUY {{min}} GET {{discount}}",
// "WEB.PROMO.TIER.BASKET": "{{discount}} when you spend {{min}}",

// "WEB.PROMO.NORMAL.ITEM": "{{discount}}",
// "WEB.PROMO.NORMAL.ITEM.CONDITIONS.BASKET.MIN": "{{discount}} when you spend {{min}}",
// "WEB.PROMO.NORMAL.ITEM.CONDITIONS.ITEM.MIN": "Buy {{min}} for {{bundledDiscount}}",
// "WEB.PROMO.NORMAL.ITEM.CONDITIONS.ITEM.EQ": "Buy {{min}} for {{bundledDiscount}}",
// "WEB.PROMO.NORMAL.ITEM.FREE": "Get this free when you spend {{min}}",

// "WEB.PROMO.NORMAL.BASKET": "{{discount}} at checkout",
// "WEB.PROMO.NORMAL.BASKET.CONDITIONS": "{{discount}} when you spend {{min}}",
// "WEB.PROMO.NORMAL.BASKET.FREE": "FREE when you spend {{min}}",

// "WEB.PROMO.NORMAL.LASTITEM": "{{discount}} when you buy {{min}}",
// "WEB.PROMO.NORMAL.LASTITEM.FREE": "Buy {{min}} get one FREE",

// "WEB.PROMO.NORMAL.SHIPPING.EXPRESS": "{{discount}} express shipping when you spend {{min}}",
// "WEB.PROMO.NORMAL.SHIPPING.EXPRESS.FREE": "FREE express shipping on orders over {{min}}",
// "WEB.PROMO.NORMAL.SHIPPING.STANDARD": "{{discount}} standard shipping when you spend {{min}}",
// "WEB.PROMO.NORMAL.SHIPPING.STANDARD.FREE": "FREE standard shipping on orders over {{min}}",

// "WEB.PROMO.DISCOUNT.VALUE.OFF": "{{amount}} off",
// "WEB.PROMO.DISCOUNT.PERCENT.OFF": "{{amount}}% off",
// "WEB.PROMO.DISCOUNT.VALUE.OVERRIDE": "Only {{amount}}",
// "WEB.PROMO.DISCOUNT.VALUE.OVERRIDE.FREE": "FREE",
