import React from 'react'

import { Grid } from 'components/grid'

import styles from './options-category-columns.css'

export const OptionsCategoryColumns = ({
  uiOptions,
  renderButton,
  renderHeading,
  data
}) => {
  const { columns = 3 } = uiOptions

  return renderContent(data)

  function renderContent(d, i = 0) {
    if (Array.isArray(d)) {
      return renderList(d)
    }

    return i ? renderSubCategories(d) : renderCategories(d, i)
  }

  function renderList(options) {
    return options.map(filter => (
      <div
        className={styles.buttonWrapper}
        key={filter.tag}
      >
        {renderButton(filter)}
      </div>
    ))
  }

  function renderCategories(categories, i) {
    const categoryNames = Object.keys(categories)

    return (
      <Grid
        columns={columns}
        gap="2rem"
      >
        {categoryNames.map(category => (
          <div key={category}>
            {category && category !== `undefined` && renderHeading(category, i)}
            {renderContent(categories[category], i + 1)}
          </div>
        ))}
      </Grid>
    )
  }

  function renderSubCategories(categories, i) {
    const categoryNames = Object.keys(categories)

    return categoryNames.map(category => (
      <div key={category}>
        {category && category !== `undefined` && renderHeading(category, i)}
        {renderContent(categories[category], i + 1)}
      </div>
    ))
  }
}
