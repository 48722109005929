import React from 'react'
import classNames from 'classnames'

import { Layout } from 'components/layout'
import { Loader } from 'components/loader'

import styles from './page-body.css'

export const PageBody = ({
  children,
  className,
  'data-testid': testId,
  layout = {},
  loading
}) => {
  return (
    <Layout layout={layout}>
      <div
        className={classNames(styles.pageBody, {
          [className]: className
        })}
        data-testid={testId}
      >
        {renderContent()}
      </div>
    </Layout>
  )

  function renderContent() {
    if (loading) {
      return (
        <div className={styles.loading}>
          <Loader />
        </div>
      )
    }

    return children
  }
}
