import React from 'react'

import { Grid } from 'components/grid'

import styles from './options-columns.css'

export const OptionsColumns = ({
  data,
  renderButton,
  renderHeading,
  uiOptions
}) => {
  const { columns = 3 } = uiOptions

  return (
    <div>
      {renderContent(data)}
    </div>
  )

  function renderContent(d, i = 0) {
    if (Array.isArray(d)) {
      return renderList(d)
    }

    return renderCategories(d, i)
  }

  function renderList(options) {
    return (
      <div className={styles.gridWrapper}>
        <Grid
          columns={columns}
          gap="1rem"
        >
          {options.map(filter => renderButton(filter))}
        </Grid>
      </div>
    )
  }

  function renderCategories(categories, i) {
    const categoryNames = Object.keys(categories)

    return categoryNames.map(category => (
      <div key={category}>
        {category && category !== `undefined` && renderHeading(category, i)}
        {renderContent(categories[category], i + 1)}
      </div>
    ))
  }
}
