import React from 'react'
import Rheostat from 'rheostat'

import styles from './range-slider.css'

export const RangeSlider = ({
  max,
  min,
  onSliderDragEnd,
  onValuesUpdated,
  step = 1,
  values
}) => {
  const minimum = getMin()
  const maximum = getMax()

  return (
    <Rheostat
      algorithm={{
        getValue,
        getPosition
      }}
      className={styles.rangeSlider}
      min={minimum}
      max={maximum}
      values={[values.min, values.max]}
      onValuesUpdated={onValuesUpdated}
      onSliderDragEnd={onSliderDragEnd}
      handle={handle}
      progressBar={progressBar}
    >
      <div className={styles.slide} />
    </Rheostat>
  )

  function getValue(pos) {
    const decimal = pos / 100

    if (pos === 0) {
      return minimum
    } else if (pos === 100) {
      return maximum
    }

    return Math.round(((maximum - minimum) * decimal + minimum) / step) * step
  }

  function getPosition(value) {
    return (value - minimum) / (maximum - minimum) * 100
  }

  function handle(props) {
    return (
      <button
        {...props}
        className={styles.handle}
      />
    )
  }

  function progressBar(props) {
    return (
      <div
        {...props}
        className={styles.progressBar}
      />
    )
  }

  function getMin() {
    return Math.min(values.min, min)
  }

  function getMax() {
    return Math.max(values.max, max)
  }
}
