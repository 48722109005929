import { getCountryName, getIso3CountryCode } from 'utils'
import {
  CARD_TYPE_MAP,
  EMPTY_MANDATORY_FIELD_STUB,
  EMPTY_MANDATORY_POSTCODE_STUB
} from 'utils/constants'
import { getMetaOptions } from 'global-content/config'
import { loadCountries } from 'services/api'

export class GooglePay {
  constructor({
    customerInformation,
    options
  }) {
    this.customerInformation = customerInformation
    this.options = options
    this.environment = getMetaOptions('environment') === 'production' ? 'PRODUCTION' : 'TEST'
    this.baseRequest = {
      apiVersion: 2,
      apiVersionMinor: 0
    }

    this.tokenizationSpecification = {
      type: 'PAYMENT_GATEWAY',
      parameters: {
        gateway: 'adyen',
        gatewayMerchantId: getMetaOptions(`localisedEntity.psmMerchantCode`)
      }
    }
    this.baseCardPaymentMethod = {
      type: 'CARD',
      parameters: {
        allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
        allowedCardNetworks: ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'],
        billingAddressRequired: true,
        billingAddressParameters: {
          format: `FULL`,
          phoneNumberRequired: true
        }
      }
    }

    this.cardPaymentMethod = Object.assign({}, this.baseCardPaymentMethod, { tokenizationSpecification: this.tokenizationSpecification })
    this.paymentsClient = null
  }

  googlePayFlow(cart) {
    return this.popupDetection().then(() => {
      return this.buildGooglePay()
    }).then(() => {
      return this.setGooglePaymentsClient()
    }).then(() => {
      return this.isAvailable()
    }).then(() => {
      return this.paymentFlow(cart)
    }).catch((e) => {
      throw e
    })
  }

  popupDetection() {
    return new Promise((resolve, reject) => {
      let isFirefox = navigator.userAgent.includes('Firefox')
      if (isFirefox) {
        let popup = window.open('https://pay.google.com')
        if (!popup || popup.closed || typeof popup.closed === 'undefined') {
          reject({
            statusCode: `POPUPS_DISALLOWED`,
            message: `GooglePay can't proceed as pop-ups aren't enabled`
          })
        } else {
          popup.close()
          resolve()
        }
      } else {
        resolve()
      }
    })
  }

  buildGooglePay() {
    if (document.getElementById('googlePayScript')) {
      return Promise.resolve()
    } else {
      return new Promise((resolve, reject) => {
        let script = document.createElement('script')
        script.src = `https://pay.google.com/gp/p/js/pay.js`
        script.id = 'googlePayScript'
        script.type = 'text/javascript'
        script.onload = () => {
          resolve()
        }
        let scripts = document.getElementById('scripts')
        scripts.appendChild(script)
      })
    }
  }

  setGooglePaymentsClient() {
    let onPaymentAuthorized = (paymentData) => {
      let state = paymentData.error ? 'ERROR' : 'SUCCESS'
      return Promise.resolve({ transactionState: state })
    }
    this.paymentsClient = new window.google.payments.api.PaymentsClient({
      environment: this.environment,
      paymentDataCallbacks: {
        onPaymentAuthorized: onPaymentAuthorized
      }
    })
  }

  isAvailable() {
    return new Promise((resolve, reject) => {
      let isReadyToPayRequest = this.getGooglePaymentDataRequest()
      this.paymentsClient.isReadyToPay(isReadyToPayRequest).then((d) => {
        if (d.result) {
          resolve()
        } else {
          reject(`GooglePay.isAvailable: [ERROR] Google Pay unavailable.`)
        }
      }).catch((e) => {
        reject(`GooglePay.isAvailable: [ERROR] ${e}`)
      })
    })
  }

  paymentFlow(cart) {
    const paymentDataRequest = this.getGooglePaymentDataRequest()
    paymentDataRequest.transactionInfo = this.getGoogleTransactionInfo(cart)

    return this.paymentsClient.loadPaymentData(paymentDataRequest).then(response => {
      console.log(`GooglePay.paymentFlow: [TOKEN]`, response)

      return loadCountries().then(countries => this.formatResponse(response, countries))
    })
  }

  getGooglePaymentDataRequest() {
    const paymentDataRequest = Object.assign({}, this.baseRequest)
    paymentDataRequest.allowedPaymentMethods = [this.cardPaymentMethod]
    paymentDataRequest.merchantInfo = {
      merchantId: 'BCR2DN6T5PBKJIIU'
    }
    paymentDataRequest.callbackIntents = ['PAYMENT_AUTHORIZATION']

    if (this.options.shippingAddressRequired) {
      paymentDataRequest.emailRequired = true
      paymentDataRequest.shippingAddressRequired = true
      paymentDataRequest.shippingAddressParameters = this.getGoogleShippingAddressParameters()
    }

    return paymentDataRequest
  }

  getGoogleShippingAddressParameters() {
    return {
      allowedCountryCodes: [getMetaOptions(`country.code`).toUpperCase()],
      phoneNumberRequired: true
    }
  }

  getGoogleTransactionInfo(cart) {
    return {
      displayItems: [
        {
          label: 'Subtotal',
          type: 'SUBTOTAL',
          price: cart.cartSummary.totalItemCost.toString()
        },
        {
          label: 'Shipping',
          type: 'LINE_ITEM',
          price: cart.cartSummary.deliveryCost.toString()
        },
        {
          label: 'Tax',
          type: 'TAX',
          price: cart.cartSummary.totalTax.toString()
        }
      ],
      countryCode: getMetaOptions(`country.code`),
      currencyCode: getMetaOptions(`currency.code`),
      totalPriceStatus: 'FINAL',
      totalPrice: cart.cartSummary.totalCost.toString(),
      totalPriceLabel: 'Total'
    }
  }

  // https://developers.google.com/pay/api/web/reference/response-objects
  formatResponse(response, countries) {
    const { paymentMethodData, shippingAddress } = response
    const { info, tokenizationData } = paymentMethodData
    const { billingAddress } = info
    const { token } = tokenizationData
    const {
      address1,
      address2,
      address3,
      administrativeArea,
      countryCode,
      locality,
      postalCode
    } = billingAddress

    const cardNetwork = info.cardNetwork.toLowerCase()

    let build = {
      googleEncryptedData: token,
      paymentMethodVariant: CARD_TYPE_MAP[cardNetwork] || cardNetwork,
      userAgent: window.navigator.userAgent,
      deviceFingerprint: this.customerInformation.deviceFingerprint,
      paymentMethod: this.customerInformation.paymentMethod,
      consumer: {
        accountId: null,
        reference: null,
        typeTag: `NEW`,
        properties: {
          MARKETING_EMAIL_OPT_MERC: false,
          ...this.customerInformation.consumer?.properties
        }
      },
      addresses: {
        BILLING: {
          addressLine1: address1,
          addressLine2: constructAddressLine2(address2, address3),
          city: locality || getCountryName(countryCode, countries), // in case of city states
          region: administrativeArea || EMPTY_MANDATORY_FIELD_STUB,
          postcode: postalCode || EMPTY_MANDATORY_POSTCODE_STUB,
          territoryCode: getIso3CountryCode(countryCode, countries)
        },
        DELIVERY: this.customerInformation.addresses?.DELIVERY
      }
    }

    if (this.options.shippingAddressRequired) {
      const [firstName, lastName] = parseNameField(shippingAddress.name, this.options.language)

      build.consumer.properties.firstName = firstName
      build.consumer.properties.lastName = lastName
      build.consumer.properties.phoneNumber = shippingAddress.phoneNumber
      build.consumer.properties.email = response.email

      build.addresses.DELIVERY = {
        addressLine1: shippingAddress.address1,
        addressLine2: constructAddressLine2(shippingAddress.address2, shippingAddress.address3),
        city: shippingAddress.locality || getCountryName(shippingAddress.countryCode, countries), // in case of city states
        region: shippingAddress.administrativeArea || EMPTY_MANDATORY_FIELD_STUB,
        postcode: shippingAddress.postalCode || EMPTY_MANDATORY_POSTCODE_STUB,
        territoryCode: getIso3CountryCode(shippingAddress.countryCode, countries)
      }
    }

    return build
  }
}

// Google only gives us one name so we have to manually parse it
// and make some assumptions on how to parse it
function parseNameField(name, language) {
  const parts = name.split(` `)

  if (parts.length === 1) {
    return [parts[0], EMPTY_MANDATORY_FIELD_STUB]
  }

  const fileName = `/data/checkout-layout.json-${language}`
  const nameLayout = window.$cache?.[fileName]?.file.layout.output.shipping[0]

  if (nameLayout === `{lastName}{firstName}`) {
    const lastName = parts[0]
    const firstName = name.replace(`${lastName} `, ``)

    return [firstName, lastName]
  }

  const lastName = parts[parts.length - 1]
  const firstName = name.replace(` ${lastName}`, ``)

  return [firstName, lastName]
}

// Google gives back a possibility of 3 address lines
function constructAddressLine2(line2, line3) {
  if (!line3) {
    return line2
  }

  return `${line2}, ${line3}`
}
