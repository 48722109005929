import React from 'react'

import { useDataFetching } from 'hooks'
import { MediaQuery2, PageBody } from 'components/scaffold'
import withErrorBoundry from 'components/errors/with-error-boundry'

export const PageTemplate = withErrorBoundry(({
  children,
  items, // augmentation items
  template
}) => {
  const [file, triedFetch] = useDataFetching({
    folder: `/themes/templates`,
    source: `${template}.json`
  })

  if (!triedFetch) {
    return <PageBody loading />
  }

  if (file) {
    return (
      <MediaQuery2
        augmentItems={items}
        {...file}
      >
        {children}
      </MediaQuery2>
    )
  }

  return null
}, `scaffold`)
