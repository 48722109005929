import React from 'react'
import classNames from 'classnames'

import { Svg } from 'components/icons'

import styles from './check-icon.css'

export const CheckIcon = ({ color, roundedCorners }) => (
  <span
    className={classNames(styles.checkIcon, {
      [styles.roundedCorners]: roundedCorners,
      [styles.black]: color === `black`,
      [styles.white]: color === `white`
    })}
  >
    <Svg icon="ok" />
  </span>
)
