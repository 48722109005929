import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { useChangeLanguage } from 'hooks'
import { getComponentOptions, getMetaOptions } from 'global-content'
import { I18n } from 'utils/i18n'

import styles from './language-selector.css'

export const webLabels = {
  toggle: `WEB.LANGUAGE.TOGGLE`
}

export const LanguageSelector = ({ onSelect }) => {
  const switchLanguage = useChangeLanguage()
  const activeLanguage = useSelector(state => state.language.active)
  const languages = getMetaOptions('languages')
  const { borderLeft } = getComponentOptions(`languageSelector.styling`)

  if (languages.length > 1) {
    return (
      <div
        className={classNames({
          [styles.borderLeft]: borderLeft
        })}
      >
        {languages.map(language => (
          <button
            className={classNames(styles.option, {
              [styles.active]: language === activeLanguage
            })}
            data-testid={`language-selector-${language}`}
            key={language}
            onClick={() => handleClick(language)}
          >
            {I18n.t(webLabels.toggle, {
              language
            })}
          </button>
        ))}
      </div>
    )
  }

  return null

  function handleClick(language) {
    switchLanguage(language, onSelect)
  }
}
