import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import find from 'lodash/find'
import queryString from 'query-string'
import LazyLoad from 'react-lazyload'

import { toListingProduct } from 'models/product'
import { translate } from 'utils/translate'
import { detailsPreload } from 'state/actions'
import { ProductCard } from 'components/product-listing'
import { updateGoogleAnalytics } from 'services/analytics'
import { SkeletonCard } from 'components/skeleton'

export const ProductCardContainer = props => {
  return (
    <LazyLoad
      offset={100}
      placeholder={
        <SkeletonCard options={props.options} />
      }
    >
      <ProductCardContainerContent {...props} />
    </LazyLoad>
  )
}

const ProductCardContainerContent = ({
  favoritePage,
  listLocation,
  options,
  position,
  product,
  analyticsTitle
}) => {
  const activeLanguage = useSelector(state => state.language.active)
  const translatedProduct = toListingProduct(translate({
    content: product,
    language: activeLanguage,
    returnSomething: true
  }))

  const location = useLocation()
  const dispatch = useDispatch()

  const {
    color: { slug },
    mainImage,
    sku,
    url
  } = translatedProduct

  const [selectedColor, setSelectedColor] = React.useState(slug)
  const [selectedImage, setSelectedImage] = React.useState(mainImage)
  const [selectedUrl, setSelectedUrl] = React.useState(constructParams(slug))

  return (
    <ProductCard
      favoritePage={favoritePage}
      handleClick={handleClick}
      onColorChange={onColorChange}
      position={position}
      product={translatedProduct}
      selectedColor={selectedColor}
      selectedImage={selectedImage}
      url={selectedUrl}
      {...options}
    />
  )

  function onColorChange(slugName) {
    const colorOption = findOption(translatedProduct.options, slugName)

    setSelectedColor(colorOption.slug)
    setSelectedImage(colorOption.mainImage)
    setSelectedUrl(constructParams(colorOption.slug))
  }

  function handleClick() {
    dispatch(detailsPreload(translatedProduct, {
      color: selectedColor
    }))
    updateGoogleAnalytics('productClick', {
      listLocation: analyticsTitle,
      algoliaItem: {
        ...product,
        listLocation,
        position
      }
    })
  }

  function constructParams(colorSlug) {
    // So that the dispatch team can directly check a product when they use a url
    // like search?includeOutOfStock=1&sku=nm_sku177270207

    if (queryString.parse(location.search).sku) {
      return `${url}?sku=${sku}`
    }

    // Otherwise return the standard URL
    return `${url}?color=${colorSlug}`
  }
}

function findOption(options, slug) {
  return find(options, color => color.slug === slug)
}
