import React from 'react'
import classNames from 'classnames'
import merge from 'lodash/merge'

import { HITS_PER_PAGE } from 'utils/constants'
import { updateGoogleAnalytics } from 'services/analytics'
import { logToCloudWatch } from 'utils/logToCloudWatch'
import { getComponentOptions } from 'global-content'
import { ProductCardContainer } from 'components/product-listing'
import { SkeletonCard } from 'components/skeleton'

import styles from './product-grid.css'

export const ProductGrid = ({
  analyticsTitle,
  itemsPerRow = 4,
  itemsToShow = HITS_PER_PAGE,
  displayOptions,
  favoritePage,
  logger,
  skeleton,
  products
}) => {
  const cardConfig = getComponentOptions(`card`)
  const options = merge({}, cardConfig, displayOptions)

  React.useEffect(() => {
    if (products?.length) {
      updateGoogleAnalytics('productImpression', {
        items: products,
        analyticsTitle
      })
    }

    if (!products?.length && logger) {
      logToCloudWatch(logger)
    }
  }, [JSON.stringify(products?.map(product => product.productId))])

  return (
    <div className={classNames(styles.grid, styles[`grid${itemsPerRow}`])}>
      {renderContent()}
    </div>
  )

  function renderContent() {
    if (skeleton) {
      return renderSkeleton()
    }

    return products.slice(0, itemsToShow).map((v, i) => {
      return (
        <ProductCardContainer
          analyticsTitle={analyticsTitle}
          options={options}
          favoritePage={favoritePage}
          key={v.objectID}
          position={i + 1}
          product={v}
        />
      )
    })
  }

  function renderSkeleton() {
    const skeletonGrid = []

    for (let i = 0; i < itemsToShow; i++) {
      skeletonGrid.push(
        <SkeletonCard
          key={i}
          options={options}
        />
      )
    }

    return skeletonGrid
  }
}
