import React from 'react'
import classNames from 'classnames'

import { Button } from 'components/buttons'

import styles from './button-group.css'

export const ButtonGroup = ({
  buttons,
  matchButtonWidths,
  orientation
}) => {
  const buttonRefs = React.useRef([])
  const [width, setWidth] = React.useState()
  const style = { width }

  React.useEffect(() => {
    buttonRefs.current = buttonRefs.current.slice(0, buttons.length)

    if (matchButtonWidths) {
      getLongestWidth()
    }
  }, [])

  return (
    <div
      className={classNames({
        [styles.horizontal]: orientation === `horizontal`,
        [styles.vertical]: orientation === `vertical`
      })}
    >
      {buttons.map(renderButton)}
    </div>
  )

  function renderButton({ config, content, positioning, styling }, i) {
    return (
      <Button
        config={config}
        content={content}
        key={content.href || content.html}
        ref={el => { buttonRefs.current[i] = el }}
        positioning={positioning}
        style={style}
        styling={styling}
      >
        {content.html}
      </Button>
    )
  }

  function getLongestWidth() {
    const widths = buttonRefs.current.map(ref => ref.getBoundingClientRect().width)
    setWidth(Math.max(...widths) + 3) // + 3 is magic number to stop different fonts creating a second line
  }
}
