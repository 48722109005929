import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { useUrlManager } from 'hooks'
import { I18n } from 'utils/i18n'
import { storage } from 'utils/storage'
import { getMetaOptions } from 'global-content/config'
import { updateGoogleAnalytics } from 'services/analytics'
import { restartSession } from 'state/actions'
import { Svg } from 'components/icons'
import { Layout } from 'components/layout'
import { Heading, HtmlText } from 'components/text'
import withErrorBoundry from 'components/errors/with-error-boundry'

import styles from './complete.css'

export const webLabels = {
  title: `WEB.CHECKOUT.CONFIRM.TITLE`,
  desc: `WEB.CHECKOUT.CONFIRM.DESC`,
  orderNum: `WEB.CHECKOUT.CONFIRM.ORDERNUM`,
  orderDate: `WEB.CHECKOUT.CONFIRM.ORDERDATE`
}

const CompleteComponent = () => {
  const dispatch = useDispatch()
  const { search } = useLocation()
  const urlManager = useUrlManager()
  const activeLanguage = useSelector(state => state.language.active)
  const cartContents = useSelector(state => state.cart.items)
  const deliveryCost = useSelector(state => state.cart.cartSummary.deliveryCost)
  const totalDiscountShipping = useSelector(state => state.cart.cartSummary.totalDiscountShipping)
  const totalItemCost = useSelector(state => state.cart.cartSummary.totalItemCost)
  const totalDiscountProduct = useSelector(state => state.cart.cartSummary.totalDiscountProduct)
  const totalTax = useSelector(state => state.cart.cartSummary.totalTax)
  const voucherCode = useSelector(state => state.cart.cartSummary.voucherCode)
  const paymentMethod = useSelector(state => state.cart.paymentMethod.brandCode)

  const locale = `${activeLanguage}-${getMetaOptions('country.code').toLowerCase()}`
  const [orderReference] = React.useState(queryString.parse(search).utr) // so we have a record of it before it is removed from the url
  const orderDate = Intl.DateTimeFormat(locale).format(new Date())
  const description = I18n.t(webLabels.desc)

  React.useEffect(() => {
    if (!orderReference) {
      urlManager({
        pathname: {
          value: `/`
        }
      })
    }
  }, [])

  React.useEffect(() => {
    if (cartContents.length > 0 && orderReference) {
      updateGoogleAnalytics('transactionComplete', {
        cartContents,
        orderReference,
        totalItemCost,
        totalDiscountProduct,
        deliveryCost,
        totalDiscountShipping,
        totalTax,
        voucherCode,
        paymentMethod
      })

      // Naver Pixel notification
      if (getMetaOptions('integrations.naver')) {
        window._nasa = {}
        window._nasa['cnv'] = window.wcs && window.wcs.cnv ? window.wcs.cnv('1', totalItemCost + totalTax) : null
      }

      dispatch(restartSession())
      // remove all data provided by customer during checkout
      storage.remove('customerDetails')
      // hide all incoming data from address bar
      urlManager({
        params: {
          replace: ``
        }
      })
    }
  }, [cartContents.length])

  return (
    <section className={styles.container}>
      <div>
        <Layout
          layout={{
            borderBottomSize: `2px`,
            borderColor: `currentColor`,
            marginBottom: `xxs`,
            paddingBottom: `xxs`
          }}
          style={{
            display: `inline-block`
          }}
        >
          <Heading
            content={{
              html: I18n.t(webLabels.title)
            }}
            styling={{
              fontFamily: `primary`,
              fontSize: `large`,
              fontWeight: 500,
              universalColor: true
            }}
          />
        </Layout>

        <div className={styles.info}>
          <div className={styles.iconWrapper}>
            <Svg
              icon="check"
              size="7.5rem"
            />
          </div>
          <Layout
            layout={{
              marginBottom: `xs`
            }}
          >
            {renderInfoItem(webLabels.orderNum, orderReference, `order-reference`)}
            {renderInfoItem(webLabels.orderDate, orderDate)}
          </Layout>
          <HtmlText html={description} />
        </div>
      </div>
    </section>
  )

  function renderInfoItem(label, value, testId) {
    return (
      <div>
        <span className={styles.label}>{I18n.t(label)}</span>
        <span
          className={styles.orderData}
          data-testid={testId} // BE use this for tests
        >
          {value}
        </span>
      </div>
    )
  }
}

export const Complete = withErrorBoundry(CompleteComponent, 'scaffold')
