import React from 'react'
import { useSelector } from 'react-redux'

import { Layout } from 'components/layout'

export const Factory = ({ data = {}, items = [] }) => {
  const components = useSelector(state => state.components)
  const { wrap } = data

  return (
    <React.Fragment>
      {items.map(item => {
        const { component, layout, ...itemProps } = getData(item)
        const Component = components[component] || (() => <div />)

        return (
          <Layout
            key={item.id}
            layout={layout}
            wrap={wrap}
            style={layoutHeight(item)}
          >
            <Component
              items={item.items}
              {...itemProps}
            />
          </Layout>
        )
      })}
    </React.Fragment>
  )
}

function layoutHeight(item) {
  const { component, positioning = {} } = getData(item)

  if (
    component === `group` &&
    positioning.direction === `vertical`
  ) {
    return {
      height: `100%`
    }
  }
}

function getData(item) {
  if (item.data) {
    return item.data
  }

  return item || {}
}

// helper function for legacy components
export function v1FactoryToV2({ order, components }) {
  if (order) {
    return order.map(id => formatComponent(components, components[id], id))
  }

  return Object.entries(components).map(([id, component]) => formatComponent(components, component, id))
}

function formatComponent(components, component, id) {
  if (component.data || component.items) {
    return component
  }

  return {
    id,
    data: {
      ...components[id]
    }
  }
}
