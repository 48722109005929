import React from 'react'
import classNames from 'classnames'

import { HtmlText } from 'components/text'

import styles from './subtitle.css'

export const Subtitle = ({
  content = {},
  styling = {}
}) => {
  const {
    html
  } = content

  const {
    color,
    fontFamily,
    fontSize,
    fontStyle,
    fontWeight,
    textAlign,
    uppercase
  } = styling

  return (
    <div
      className={classNames({
        [styles.primary]: fontFamily === `primary`,
        [styles.secondary]: fontFamily === `secondary`,
        [styles.custom]: fontFamily === `custom`,
        [styles.xxl]: fontSize === `xxl`,
        [styles.xl]: fontSize === `xl`,
        [styles.large]: fontSize === `large`,
        [styles.medium]: fontSize === `medium`,
        [styles.small]: fontSize === `small`,
        [styles.xs]: fontSize === `xs`
      })}
      style={{
        color,
        fontStyle,
        fontWeight,
        textAlign,
        textTransform: uppercase && `uppercase`
      }}
    >
      <HtmlText html={html} />
    </div>
  )
}
