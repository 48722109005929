import get from 'lodash/get'
import store from 'state/reducers'

import { getContent } from 'global-content/content'

export function constructUrl({ productId, slug }) {
  return `/product/${slug}/${productId}`
}

const PROMOTIONSMAP = {
  brands: {
    location: 'brand.tag'
  },
  brand: { // v1 name reference
    location: 'brand.tag'
  },
  categories: {
    location: 'categories'
  },
  collections: {
    location: 'collections',
    arrayKey: 'tag'
  },
  sale: {
    location: 'sale'
  }
}

export function getPromotions(product) {
  const activeLanguage = store.getState().language.active
  const promotionsContent = getContent(`promotions`, activeLanguage)
  const productPromotions = promotionsContent?.products || []
  const campaignPromotions = getContent(`campaigns`, activeLanguage) || []

  const validPromotions = [
    ...productPromotions.map(v1ToV2Promotion).filter(promotion => isPromotionValid(promotion, product)),
    ...campaignPromotions.filter(campaign => isPromotionValid(campaign, product))
  ]

  const activePromotions = getActivePromotions(validPromotions)
  const orderedPromotions = orderPromotions(activePromotions)
  const areas = promotionAreas(orderedPromotions)

  return areas
}

export function v1ToV2Promotion({ filters, ...promo }) {
  if (!filters) {
    return promo
  }

  if (filters) {
    let inclusions = {}
    let exclusions = {}

    const filterKeys = Object.keys(filters)
    filterKeys.forEach(filterKey => {
      if (filterKey === `onSale` && filters.onSale) {
        inclusions.sale = `ONSALE`
      }

      if (filters[filterKey].excludes) {
        exclusions[filterKey] = filters[filterKey].excludes
      }

      if (filters[filterKey].includes) {
        inclusions[filterKey] = filters[filterKey].includes
      }
    })

    return {
      promotion: {
        conditions: {
          inclusions,
          exclusions
        }
      },
      ...promo
    }
  }
}

export function isPromotionValid(
  {
    promotion = {},
    locations
  } = {},
  product
) {
  if (locations) {
    const { conditions = {} } = promotion
    const { exclusions, inclusions } = conditions

    if (hasNoExclusions(exclusions, product)) { // exclusions overrule inclusions, so check if excluded first
      return hasInclusions(inclusions, product)
    }
  }
}

export function hasNoExclusions(input, product) {
  if (isEmpty(input)) {
    return true
  }

  const inputKeys = Object.keys(input)
  let value = false

  for (let i = 0; i < inputKeys.length; i++) {
    const inputKey = inputKeys[i]
    const inputData = normalizeData(input[inputKey])

    const {
      arrayKey,
      location
    } = PROMOTIONSMAP[inputKey]

    const locationData = normalizeData(getLocationData({ product, location, arrayKey }))

    if (inputData.some(data => locationData.includes(data))) {
      value = false
      break
    } else {
      value = true
    }
  }

  return value
}

export function hasInclusions(input, product) {
  if (isEmpty(input)) {
    return true
  }

  const inputKeys = Object.keys(input)
  let value = false

  for (let i = 0; i < inputKeys.length; i++) {
    const inputKey = inputKeys[i]
    const inputData = normalizeData(input[inputKey])
    const {
      arrayKey,
      location
    } = PROMOTIONSMAP[inputKey]

    const locationData = normalizeData(getLocationData({ product, location, arrayKey }))

    if (inputData.some(data => locationData.includes(data))) {
      value = true
    } else {
      value = false
      break
    }
  }

  return value
}

function isEmpty(value) {
  if (!value) {
    return true
  }

  if (!Object.keys(value).length) {
    return true
  }

  return false
}

function getLocationData({ product, location, arrayKey }) {
  let data = get(product, `${location}`)

  if (arrayKey) {
    return data.map(item => item[arrayKey])
  }

  return data
}

// always work with an array to open up the
// use of array methods .some and .includes
function normalizeData(data) {
  if (Array.isArray(data)) {
    return data
  }

  return [data]
}

function promotionAreas(promotions) {
  let build = {}

  promotions.forEach(({ locations }, i) => {
    locations.forEach((locationContent, l) => {
      const key = `${i}${l}`
      // if key already exists add to it
      if (build[locationContent.config.location]) {
        build[locationContent.config.location].push({
          ...locationContent,
          key
        })
      // else make a new key and array
      } else {
        build[locationContent.config.location] = [{
          ...locationContent,
          key
        }]
      }
    })
  })

  return build
}

function getActivePromotions(promotions) {
  // don't filter promotions out by their time stamps if on a non-prod environment
  if (
    [
      `preview.`,
      `localised-`,
      `localhost`
    ].some(criteria => window.location.host.includes(criteria))
  ) {
    return promotions
  }

  const timeNow = new Date().getTime()

  return promotions.filter(({ promotion }) => {
    if (
      promotion?.start > timeNow ||
      promotion?.end < timeNow
    ) {
      return false
    }

    return true
  })
}

export function orderPromotions(promotions) {
  return [...promotions].sort(sortByPriority)
}

function sortByPriority(promotionA, promotionB) {
  const Apriority = promotionA?.promotion?.priority || Infinity
  const Bpriority = promotionB?.promotion?.priority || Infinity

  if (Apriority < Bpriority) {
    return -1
  }

  if (Apriority > Bpriority) {
    return 1
  }

  return 0
}
