import React from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import { I18n } from 'utils/i18n'
import { getComponentOptions } from 'global-content'
import { usePrevious } from 'hooks'
import { AnimatedIcon } from 'components/icons'
import { Link } from 'components/text'
import { IconLabel } from 'components/icon-label'

import styles from './favorites-link.css'

export const webLabels = {
  label: `WEB.FAVORITES.LABEL`
}

export const FavoritesLink = () => {
  const [adding, setAdding] = React.useState()
  const favorites = useSelector(state => state.favorites.all)
  const previousFavorites = usePrevious(favorites)
  const { position } = getComponentOptions(`cartCount`)
  const { size } = getComponentOptions(`favoritesLink`)

  React.useEffect(() => {
    if (previousFavorites && favorites.length > previousFavorites.length) {
      setAdding(true)

      setTimeout(() => { setAdding(false) }, 500)
    }
  })

  let icon = 'heart'

  if (favorites.length === 0) {
    icon = 'heart-o'
  }

  return (
    <Link
      className={classNames(styles.container, {
        [styles.empty]: favorites.length === 0,
        [styles.tighter]: position === 'right'
      })}
      to="/my-favorites"
    >
      <div style={{ width: size }}>
        <AnimatedIcon
          icon={icon}
          size={size}
          animating={adding}
          animation="grow"
        />
      </div>
      <IconLabel iconLabel={I18n.t(webLabels.label)} />
    </Link>
  )
}
