import { currencyFormatted } from 'utils'
import { I18n } from 'utils/i18n'
import { typeAndAmountInterpolater } from './amount-interpolation'

export const webLabels = {
  EMPTY: `LOKA.KEY.EMPTY.MESSAGE.PROMO`,
  ITEM_COUNT: `WEB.PROMO.TIER.ITEM`,
  BASKET_TOTAL: `WEB.PROMO.TIER.BASKET`
}

export function tierPromotion({
  language,
  messageId,
  tierDiscount
}) {
  const {
    tiers,
    tierType,
    discountType
  } = tierDiscount

  return tiers.map(interpolateTier).reverse() // most attractive offer first

  function interpolateTier({
    discountAmount,
    min
  }) {
    const discount = typeAndAmountInterpolater({
      amount: discountAmount,
      language,
      type: discountType
    })

    const minString = tierType === `ITEM_COUNT` ? min : currencyFormatted(min)

    return {
      discount,
      min: minString,
      content: I18n.t(getWebLabel(), {
        language,
        replace: {
          discount,
          min: minString
        }
      })
    }
  }

  function getWebLabel() {
    // DISABLED WHILST CONFIRM MESSAGEID USE
    // if (messageId && messageId !== webLabels.EMPTY) {
    //   return messageId
    // }

    return webLabels[tierType]
  }
}
