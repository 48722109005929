import React from 'react'
import classNames from 'classnames'

import { Grid } from 'components/grid'
import { Link } from 'components/text'
import { Svg } from 'components/icons'

import styles from './icons-group.css'

export const IconsGroup = ({
  blockIcons,
  borderedIcons,
  columns,
  gap = `3rem`,
  height,
  icons,
  inlineGrid,
  size,
  viewBox
}) => {
  if (icons.length) {
    return (
      <Grid
        columns={columns || icons.length}
        gap={gap}
        inlineGrid={inlineGrid}
      >
        {icons.map(icon => (
          renderIcon(icon)
        ))}
      </Grid>
    )
  }

  return null

  function renderIcon({ icon, link, target }) {
    const svg = (
      <div
        className={classNames({
          [styles.borderedIcons]: borderedIcons
        })}
        key={icon}
      >
        <Svg
          block={blockIcons}
          icon={icon}
          height={height}
          size={size}
          viewBox={viewBox}
        />
      </div>
    )

    if (link) {
      return (
        <Link
          key={icon}
          target={target}
          to={link}
        >
          {svg}
        </Link>
      )
    }

    return svg
  }
}
