import React from 'react'
import classNames from 'classnames'

import { getComponentOptions } from 'global-content/components'
import { Swatch } from 'components/swatches'

import styles from './swatch-button.css'

export const SwatchButton = ({
  'data-testid': testId,
  disabled,
  onClick,
  selected,
  swatchHex,
  swatchPath,
  swatchSize,
  swatchType = getComponentOptions(`swatch.type`)
}) => (
  <button
    className={classNames(styles.swatchWrapper, styles[swatchType], {
      [styles.disabled]: disabled,
      [styles.selected]: selected,
      [styles.fill]: swatchSize === 'fill'
    })}
    data-testid={testId}
    onClick={onClick}
  >
    <Swatch
      swatchHex={swatchHex}
      swatchPath={swatchPath}
      swatchType={swatchType}
      swatchSize={swatchSize}
    />
  </button>
)
