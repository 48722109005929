import React from 'react'
import classNames from 'classnames'

import styles from './grid.css'

export const Grid = ({
  className,
  children,
  columns,
  gap,
  inlineGrid,
  itemSize = '1fr',
  style = {}
}) => {
  let gridTemplateColumns = columns
  let msGridColumns = columns

  if (typeof columns === 'number') {
    gridTemplateColumns = `repeat(${columns}, ${itemSize})`
    msGridColumns = `repeat(${columns}, ${itemSize})`
  }

  return (
    <div
      className={classNames(styles.grid, {
        [className]: className,
        [styles.inlineGrid]: inlineGrid
      })}
      style={{
        gridTemplateColumns,
        msGridColumns,
        gridGap: gap,
        ...style
      }}
    >
      {children}
    </div>
  )
}
