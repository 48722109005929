import React from 'react'
import classNames from 'classnames'

import styles from './svg.css'

export const Svg = ({
  block,
  icon,
  height,
  size = '1em',
  viewBox
}) => (
  <svg
    className={classNames(styles.svg, {
      [styles.block]: block
    })}
    style={{
      height: !viewBox ? size : height,
      width: !viewBox ? size : ''
    }}
    viewBox={viewBox}
  >
    <use href={`/themes/icons/sprites.svg?cb=${window.$cacheBust}#${icon}`} />
  </svg>
)
