import React from 'react'
import { Breadcrumbs } from 'components/breadcrumbs'
import { Button } from 'components/buttons'

import { CategoryCards } from 'factory-components/category-cards'
import { ComponentTemplate } from 'factory-components/component-template'
import { EmailSignup } from 'factory-components/email-signup'
import { Group } from 'factory-components/group'
import { PageNav } from 'factory-components/page-nav'
import { ProductTray } from 'factory-components/product-tray'
import { Text } from 'factory-components/text'
import { TextBanner } from 'factory-components/text-banner'
import { TextAndImage } from 'factory-components/text-and-image'

import {
  DropdownFilters,
  SidebarFilters,
  MobileFiltersToggles,
  Paginator,
  ResultsSummary,
  SelectedFilters
} from 'components/filters'

import { Headline } from 'components/text'
import { Listing, ListingProductGrid } from 'components/product-listing'
import { PromoStrip } from 'components/header'
import { SideNavigation } from 'components/side-navigation'

const LazyMarkDownContent = React.lazy(() => (
  import(/* webpackChunkName: "markdown-content" */ 'factory-components/markdown/mark-down-content')
))

const LazyContactContainer = React.lazy(() => (
  import(/* webpackChunkName: "contact-container" */ 'factory-components/contact-form/contact-container')
))

const MarkDownContent = props => (
  <React.Suspense fallback={<div />}>
    <LazyMarkDownContent {...props} />
  </React.Suspense>
)

const ContactContainer = props => (
  <React.Suspense fallback={<div />}>
    <LazyContactContainer {...props} />
  </React.Suspense>
)

export function universalComponents() {
  return {
    // Lithos 5.0 and ready to go
    button: Button,
    group: Group,
    headline: Headline,
    h1: Headline,
    productTray: ProductTray,
    componentTemplate: ComponentTemplate,
    textAndImage: TextAndImage,
    text: Text,
    categoryCards: CategoryCards,

    // Need to die
    promoStrip: PromoStrip, // used
    textBanner: TextBanner,

    // General
    breadcrumbs: Breadcrumbs,
    emailSignup: EmailSignup,

    // PLP
    dropdownFilters: DropdownFilters,
    sidebarFilters: SidebarFilters,
    listing: Listing,
    listingProductGrid: ListingProductGrid,
    mobileFiltersToggles: MobileFiltersToggles,
    results: ResultsSummary,
    paginator: Paginator,
    selectedFilters: SelectedFilters,
    sideNavigation: SideNavigation,

    // Support
    contactForm: ContactContainer,
    markDownContent: MarkDownContent,
    pageNav: PageNav
  }
}
