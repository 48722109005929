import React from 'react'
import classNames from 'classnames'

import { Link } from 'components/text'

import styles from './link-text.css'

export const LinkText = ({
  active,
  activeState,
  button,
  children,
  hover,
  hoverState,
  lookalike = false,
  onClick,
  textAlign,
  to,
  size = 'regular',
  type,
  uppercase = false,
  ...rest
}) => {
  const props = {
    className: classNames({
      [styles.regular]: size === `regular`,
      [styles.medium]: size === `medium`,
      [styles.small]: size === `small`,
      [styles.xs]: size === `xs`,
      [styles.menuLinkPrimary]: type === `menuLinkPrimary`,
      [styles.menuLinkSecondary]: type === `menuLinkSecondary`,
      [styles.textLink]: type === `textLink`,
      [styles.active]: active,
      [styles.hover]: hover,
      [styles[`activeState${activeState || ''}`]]: activeState,
      [styles[`hoverState${hoverState || ''}`]]: hoverState
    }),
    onClick: onClick,
    style: {
      textAlign,
      textTransform: uppercase ? 'uppercase' : ''
    },
    ...rest
  }

  if (lookalike) {
    return (
      <div {...props}>
        {children}
      </div>
    )
  }

  if (button) {
    return (
      <button {...props}>
        {children}
      </button>
    )
  }

  return (
    <Link
      {...props}
      to={to}
    >
      {children}
    </Link>
  )
}
