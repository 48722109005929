import React from 'react'
import classNames from 'classnames'

import { Layout } from 'components/layout'

import styles from './product-tray-tabs.css'

export const ProductTrayTabs = ({
  activeTab,
  marginBottom = `small`,
  position = `center`,
  setActiveTab,
  tabs
}) => (
  <Layout
    data-testid="product-tray-tabs-layout"
    layout={{ marginBottom }}
  >
    <div
      className={classNames({
        [styles.left]: position === `left`,
        [styles.center]: position === `center`,
        [styles.right]: position === `right`
      })}
      data-testid="product-tray-tabs"
    >
      {tabs.map(({ title }, idx) => (
        <div
          className={styles.tab}
          key={title}
        >
          <button
            onClick={() => setActiveTab(idx)}
            className={classNames({
              [styles.activeTab]: idx === activeTab
            })}
          >
            {title}
          </button>
        </div>
      ))}
    </div>
  </Layout>
)
