import React from 'react'
import classNames from 'classnames'
import merge from 'lodash/merge'

import { getComponentOptions } from 'global-content'
import { useContent } from 'hooks'

import { getLevelDown } from 'utils'

import { Layout } from 'components/layout'
import { Link, Heading } from 'components/text'
import { TextAndImage } from 'factory-components/text-and-image'

import styles from './megamenu.css'

export const Megamenu = ({
  activeL1,
  activeL2,
  augmentation,
  offset,
  onClick,
  relative
}) => {
  const navTree = useContent(`navTree`)
  const {
    innerContainer,
    order,
    outerContainer,
    posterRatio,
    width = 1440
  } = getComponentOptions(`megamenu`)

  const {
    augmentations,
    columnCount = 5,
    columnLength = 6,
    level,
    showHeading = true,
    showLevelDown
  } = augmentation

  const augmentedNavTree = merge({}, navTree, augmentations)

  const activePoster = {
    l2: activeL1 && augmentedNavTree.l1[activeL1].poster,
    l3: activeL2 && augmentedNavTree.l1[activeL1].l2[activeL2].poster
  }

  const startingBranch = getTreeBranch()
  const startingParents = [activeL1, activeL2].filter(Boolean)
  const entries = buildEntries({
    startingBranch,
    startingLevel: level,
    showLevelDown,
    startingParents
  })
  const groups = getRenderableData({ data: entries, columnCount, columnLength, showLevelDown })

  const totalItems = getTotalItems(entries)

  const linkRefs = React.useRef([])
  const [linkWidth, setLinkWidth] = React.useState()

  React.useEffect(() => {
    linkRefs.current = linkRefs.current.slice(0, totalItems)
    getLongestWidth()
  }, [activeL1, activeL2])

  const alignment = getAlignment(innerContainer.alignment, width, linkWidth, groups)

  const componentMap = {
    navGroups: renderNavigation(),
    poster: renderPoster()
  }

  return (
    <div
      className={styles.wrapper}
      style={{
        top: !relative && offset
      }}
    >
      <Layout
        className={styles.outerContainer}
        layout={{
          container: outerContainer.container
        }}
      >
        <Layout
          className={styles.innerContainer}
          layout={{
            container: innerContainer.container
          }}
        >
          <div
            className={classNames(styles.innerWrapper, {
              [styles.horizontalPadding]: innerContainer.horizontalPadding
            })}
            style={{
              width
            }}
          >
            {order.map(component => componentMap[component])}
          </div>
        </Layout>
      </Layout>
    </div>
  )

  function getTreeBranch() {
    if (level === `l2`) {
      return augmentedNavTree.l1[activeL1].l2
    }

    if (level === `l3`) {
      return augmentedNavTree.l1[activeL1].l2[activeL2].l3
    }
  }

  function renderNavigation() {
    return (
      <div
        className={classNames(styles.navigation, {
          [styles.center]: alignment === `center`,
          [styles.left]: alignment === `left`
        })}
        key="navigation"
      >
        {renderGroups()}
      </div>
    )
  }

  function renderGroups() {
    return (
      groups.map(({ id, data, items }) => {
        return (
          <div
            className={styles.group}
            key={id}
            style={{ width: linkWidth }}
          >
            {data && showHeading &&
              <Link
                target={data.target}
                to={data.href}
                className={styles.title}
                onClick={onClick}
              >
                {renderHeading(data)}
              </Link>
            }
            {renderColumns(items)}
          </div>
        )
      })
    )
  }

  function renderColumns(columns) {
    if (columns && columns.length) {
      return (
        <div className={styles.groupWrapper}>
          {
            columns.map(({ id, items }) => (
              <div
                className={styles.column}
                key={id}
              >
                {items.map((item, i) => (
                  <div key={item.data.href}>
                    <Link
                      className={styles.link}
                      data-testid={`nav-link-${showLevelDown ? getLevelDown(level) : level}-${i}`}
                      onClick={onClick}
                      ref={el => {
                        linkRefs.current[item.data.linkNumber] = el
                      }}
                      target={item.data.target}
                      to={item.data.href}
                    >
                      {item.data.html}
                    </Link>
                  </div>
                ))}
              </div>
            ))
          }
        </div>
      )
    }
  }

  function renderHeading({ linkNumber, html }) {
    return (
      <Layout
        layout={{
          marginBottom: `xxs`
        }}
      >
        <Heading
          content={{
            html
          }}
          layout={{
            marginBottom: 'medium'
          }}
          styling={{
            fontSize: 'xs'
          }}
        // ref={el => { linkRefs.current[linkNumber] = el }}
        />
      </Layout>
    )
  }

  function renderPoster() {
    const poster = activePoster[level]

    if (poster) {
      return (
        <div
          className={classNames(styles.poster, {
            [styles.setWidth]: posterRatio
          })}
          key="poster"
        >
          <TextAndImage
            config={{
              onClick
            }}
            content={{
              href: poster.path,
              title: poster.ctaText,
              image: {
                alt: poster.ctaText,
                src: poster.image
              }
            }}
            positioning={{
              captionPosition: [`center`, `center`],
              textAlign: `center`,
              textOverlay: true
            }}
            styling={{
              captionColor: `white`,
              titleFontSize: `medium`
            }}
          />
        </div>
      )
    }

    return null
  }

  function getLongestWidth() {
    const widths = linkRefs.current.filter(Boolean).map((ref, i) => ref.getBoundingClientRect().width)
    setLinkWidth(Math.max(...widths))
  }
}

function getTotalItems(entries) {
  return entries.reduce((acc, group) => {
    const { items = [] } = group

    return (
      acc +
      1 +
      items.length
    )
  }, 0)
}

function getAlignment(innerAlignment, megaMenuWidth, linkWidth, groups) {
  if (
    innerAlignment === `left` ||
    linkWidth * groups.length >= megaMenuWidth
  ) {
    return `left`
  }

  return innerAlignment
}

function getRenderableData({
  data,
  columnCount,
  columnLength,
  showLevelDown
}) {
  if (!showLevelDown) {
    const groups = chunkArray(data, columnCount)
    const columns = groups.map((group, id) => {
      return {
        id,
        items: chunkArray(group.items, columnLength)
      }
    })

    return columns
  }

  const groups = data.map((group, id) => {
    return {
      id,
      data: group.data,
      items: chunkArray(group.items, columnLength)
    }
  })

  return groups
}

function chunkArray(myArray, chunkSize) {
  let index = 0
  let arrayLength = myArray.length
  let build = []

  for (index = 0; index < arrayLength; index += chunkSize) {
    const myChunk = myArray.slice(index, index + chunkSize)

    build.push({
      id: index,
      items: myChunk
    })
  }

  return build
}

/**********************/

function buildEntries({
  startingBranch,
  startingLevel,
  showLevelDown,
  startingParents
}) {
  let linkNumber = 0

  return buildData({
    branch: startingBranch,
    level: startingLevel,
    getNextLevel: showLevelDown,
    parents: startingParents
  })

  function buildData({
    branch,
    level,
    getNextLevel,
    parents
  }) {
    let build = []

    if (branch) {
      const order = branch.order || Object.keys(branch)

      order.forEach(slug => {
        const value = branch[slug]

        let entry = {
          data: {
            linkNumber,
            html: value.label,
            href: value.path || constructedSlug(parents, slug),
            target: value.target
          }
        }

        linkNumber++

        if (getNextLevel) {
          entry.items = buildData({
            branch: branch[slug][getLevelDown(level)],
            parents: [...parents, slug]
          })
        }

        build.push(entry)
      })
    }

    return build
  }
}

function constructedSlug(parents, slug) {
  return `/${[...parents, slug].join('/')}`
}

/*********************/
