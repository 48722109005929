import React from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'utils/i18n'

import { SkeletonText } from 'components/skeleton'

import styles from './results-summary.css'

export const webLabels = {
  approximate: `WEB.LISTING.PAGINATION.RESULTSAPPROX`,
  results: `WEB.LISTING.PAGINATION.RESULTS`
}

export const ResultsSummary = () => {
  const loading = useSelector(state => state.listing.loading)
  const page = useSelector(state => state.listing.page)
  const resultsSummary = useSelector(state => state.listing.resultsSummary)
  const { approximateTotal, currentPage, hitsPerPage, nbHits } = resultsSummary

  if (loading) {
    return renderSkeleton()
  }

  if (nbHits <= 0) {
    return null
  }

  const translation = approximateTotal ? webLabels.approximate : webLabels.results

  return (
    <div className={styles.resultsSummary}>
      {I18n.t(translation, {
        replace: {
          first: itemCountFrom(),
          last: itemCountTo(),
          total: nbHits
        }
      })}
    </div>
  )

  function itemCountFrom() {
    return currentPage > 0 ? (currentPage * hitsPerPage) + 1 : 1
  }

  function itemCountTo() {
    const baseCountTo = page * hitsPerPage
    return Math.min(baseCountTo, nbHits)
  }

  function renderSkeleton() {
    return (
      <div className={styles.resultsSummary}>
        <SkeletonText
          size="bodyXS"
          width="14rem"
        />
      </div>
    )
  }
}
