import React from 'react'
import classNames from 'classnames'

import mediaQueries from 'css/media-queries.json'
import { getComponentOptions } from 'global-content'
import { I18n } from 'utils/i18n'
import { IconLabel } from 'components/icon-label'
import { Svg } from 'components/icons'

import styles from './search-input.css'

const inputMaxLength = 50

export class SearchInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFocus: false,
      value: ''
    }
    this.inputRef = React.createRef()
  }

  clearInput() {
    this.setState({
      value: ''
    })
  }

  handleChange(e) {
    const value = e.target.value
    this.setState({ value })
    const { onChange } = this.props
    onChange && onChange(value)
  }

  handleSubmit(e) {
    e.preventDefault()
    const { onSubmit } = this.props
    const { value } = this.state
    if (value) {
      onSubmit(value.substring(0, inputMaxLength))
      this.clearInput()
    } else {
      this.inputRef.current.focus()
    }
  }

  handleFocus(e) {
    e.target.style.fontSize = ''
    this.setState({
      isFocus: true
    })
  }

  handleBlur() {
    this.setState({
      isFocus: false
    })
  }

  render() {
    const { isFocus, value } = this.state
    const { border, iconPosition, iconSize } = getComponentOptions(`searchInput.styling`)
    const { expandable, size, variation } = this.props
    const placeholder = I18n.t('WEB.SEARCH.TITLE')

    let width

    if (expandable && window.matchMedia(mediaQueries.aboveTabletMedium).matches) {
      width = isFocus || value ? expandable[1] : expandable[0]
    }

    return (
      <div
        className={classNames(
          styles.container,
          styles[variation],
          styles[border],
          styles[size]
        )}
        data-testid="search-input"
      >
        <form
          className={classNames(styles.searchInput, {
            [styles.leftIcon]: iconPosition === 'left'
          })}
          onSubmit={this.handleSubmit.bind(this)}
        >
          <input
            className={styles.input}
            maxLength={inputMaxLength}
            onChange={this.handleChange.bind(this)}
            onFocus={this.handleFocus.bind(this)}
            onBlur={this.handleBlur.bind(this)}
            placeholder={placeholder}
            ref={this.inputRef}
            style={{
              padding: width === 0 && 0,
              width
            }}
            type="text"
            value={value}
          />

          <button className={styles.button}>
            <Svg
              icon="search"
              size={iconSize}
            />
            {variation === 'header' &&
              <IconLabel iconLabel={placeholder} />
            }
          </button>
        </form>

        {variation === 'header' && value &&
          <div>
            <button
              onClick={this.clearInput.bind(this)}
              className={styles.clearButton}
            >
              <Svg
                icon="close"
                size="2rem"
              />
            </button>
            <button
              onClick={this.handleSubmit.bind(this)}
              className={styles.submitButton}
            >
              <Svg
                icon="search"
                size="1.8rem"
              />
            </button>
          </div>
        }
      </div>
    )
  }
}
