import React from 'react'
import classNames from 'classnames'

import { Link } from 'components/text'
import { Layout } from 'components/layout'

import styles from './text-banner.css'

export const TextBanner = ({
  content = {},
  styling = {}
}) => {
  const { backgroundColor, color, container, padding, textAlign } = styling
  const { areas } = content

  return (
    <Layout
      className={styles.container}
      layout={{
        paddingVertical: padding
      }}
      style={{ backgroundColor, color, textAlign }}
    >
      <Layout
        className={styles.innerContainer}
        layout={{
          container
        }}
      >
        {Object.keys(areas).map(area => {
          return (
            <div
              className={styles[area]}
              key={area}
            >
              {areaContentSelector(areas[area])}
            </div>
          )
        })}
      </Layout>
    </Layout>
  )
}

function areaContentSelector(area) {
  if (area.content.buttons) {
    return area.content.buttons.map(button => (
      <div
        className={classNames(styles.linkWrapper, styles[button.fontSize], {
          [styles.underline]: button.underline
        })}
        key={button.href}
      >
        <Link to={button.href}>
          {button.text}
        </Link>
      </div>
    ))
  }

  if (area.content.text) {
    return (
      <div
        className={styles[area.styling.fontSize]}
        style={{ fontWeight: area.styling.fontWeight }}
      >
        {area.content.text}
      </div>
    )
  }

  return null
}
