import React from 'react'
import classNames from 'classnames'
import LazyLoad from 'react-lazyload'

import { getConfigOptions } from 'global-content'

import { Image } from 'components/images'
import { Link } from 'components/text'

import styles from './card-image.css'

export const CardImage = ({
  applyMargin,
  'data-testid': testId,
  imageSource,
  interactive = false,
  link,
  onClick,
  overlay = 'light',
  position,
  prefix,
  ratio = getConfigOptions(`variant.imageRatio`),
  title
}) => {
  const alwaysLoad = 8

  return (
    <Link
      className={classNames(styles.imageWrapper, {
        [styles.applyMargin]: applyMargin
      })}
      data-testid={`${testId}-image-link`}
      onClick={onClick}
      title={title}
      to={link}
    >
      {position > alwaysLoad &&
        <LazyLoad placeholder={<Image ratio={ratio} />}>
          {renderImage()}
        </LazyLoad>
      }
      {position <= alwaysLoad && renderImage()}
    </Link>
  )

  function renderImage() {
    return (
      <Image
        alt={title}
        interactive={interactive}
        overlay={overlay}
        prefix={prefix}
        ratio={ratio}
        src={imageSource}
      />
    )
  }
}
