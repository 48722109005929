import {
  CHANGE_LANGUAGE,
  PRERENDER_READY,
  SET_ACTIVE_TOOLTIP
} from 'state/actions'

const initialState = {
  activeTooltip: '',
  prerenderReady: false,
  hasSwitchedLanguages: false // to check if any more content needs to be loaded
}

const site = (state = initialState, action) => {
  switch (action.type) {
  case PRERENDER_READY:
    if (!state.prerenderReady) {
      let interval

      // If a selector has been passed in the payload fire event when selector is found
      if (action.payload) {
        interval = setInterval(() => {
          if (document.querySelector(action.payload)) {
            clearInterval(interval)
            appReady()
          }
        }, 30)
      } else {
      // Else fire it straightaway
        appReady()
      }

      // Clear interval regardless of what has happened after two seconds
      setTimeout(() => {
        clearInterval(interval)
        appReady()
      }, 2000)
    }

    return {
      ...state,
      prerenderReady: true
    }
  case SET_ACTIVE_TOOLTIP:
    return {
      ...state,
      activeTooltip: action.payload
    }
  case CHANGE_LANGUAGE:
    return {
      ...state,
      hasSwitchedLanguages: true
    }
  default:
    return state
  }
}

function appReady() {
  setTimeout(() => {
    window.$APP_READY = true
    document.dispatchEvent(new CustomEvent('app-ready', {
      detail: null
    }))
  }, 100)
}

export default site
