import React from 'react'

import { I18n } from 'utils/i18n'
import { unversionedStorage } from 'utils/storage'
import { Svg } from 'components/icons'
import { Link } from 'components/text'

import styles from './cookie-message.css'

export const webLabels = {
  desc: `WEB.COOKIE.MESSAGE.DESC`,
  cta: `WEB.COOKIE.MESSAGE.CTA`
}

export const CookieMessage = () => {
  const messageKey = 'cookieMessage'
  const [hide, setHide] = React.useState(Boolean(unversionedStorage.get(messageKey)))

  if (!hide) {
    return (
      <div className={styles.cookieMessage}>
        <div className={styles.wrapper}>
          <div>
            {I18n.t(webLabels.desc)}
            <Link
              to="/about-us/privacy-policy"
              className={styles.link}
            >
              {I18n.t(webLabels.cta)}
            </Link>
          </div>
        </div>
        <button
          className={styles.closeIcon}
          onClick={hideMessage}
        >
          <Svg
            icon="close"
            size="2.2rem"
          />
        </button>
      </div>
    )
  }

  return null

  function hideMessage() {
    unversionedStorage.set(messageKey, 'hide')
    setHide(true)
  }
}
