import React from 'react'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { useContent } from 'hooks'
import { Heading, SideNavLink } from 'components/text'
import { Svg } from 'components/icons'

import styles from './page-nav.css'

export const PageNav = ({ content }) => {
  const { menu } = content
  const [showMobileNav, setShowMobileNav] = React.useState(false)
  const { pathname } = useLocation()

  const contentObj = useContent(menu)
  let nav = []
  if (contentObj) {
    nav = contentObj.content.nav
  }

  const activeSection = getActiveSection()

  return (
    <div className={styles.container}>
      <div className={styles.desktopHeader}>
        <Heading
          content={{
            html: activeSection.label
          }}
          styling={{
            fontFamily: `primary`,
            fontSize: `small`,
            universalColor: true
          }}
        />
      </div>
      <button
        className={styles.menuToggle}
        onClick={() => setShowMobileNav(!showMobileNav)}
      >
        {activeSection.label}
        <Svg
          icon={showMobileNav ? `minus` : `plus`}
          size="2rem"
        />
      </button>
      <ul
        className={classNames(styles.pageNav, {
          [styles.open]: showMobileNav
        })}
      >
        {activeSection.items && activeSection.items.map(({ label, path, subItems }) => (
          <li
            className={styles.item}
            key={label}
          >
            <SideNavLink
              active={checkForActive(path, subItems)}
              to={path}
              value={label}
            />
            {subItems &&
              <ul className={styles.subNav}>
                {subItems.map(subitem => (
                  <li key={subitem.label}>
                    <SideNavLink
                      active={subitem.path === pathname}
                      to={subitem.path}
                      value={subitem.label}
                    />
                  </li>
                ))}
              </ul>
            }
          </li>
        ))}
      </ul>
    </div>
  )

  function getActiveSection() {
    const slug = pathname.split('/')[1]

    return nav.find(item => item.slug === slug) || {}
  }

  function checkForActive(path, subItems) {
    if (subItems) {
      return Boolean(subItems.find(item => item.path === pathname))
    }

    return path === pathname
  }
}
