import queryString from 'query-string'

import { getHost } from 'global-content/config'

export function getMetaDataV2() {
  const host = `hk.urbanoutfitters.localised-test.com`
  const fileName = `metadata-v2.json`

  let target = `https://${host}/${host}-current/${fileName}?cb=${window.$cacheBust}`

  if (host.includes(`preview`)) {
    target = getPreviewTarget(host, fileName)
  }

  return fetch(target).then(data => data.json()).catch(e => {
    throw new Error(`Unable to fetch ${target}`)
  })
}

export function getMetaData() {
  const host = `hk.urbanoutfitters.localised-test.com`
  const fileName = `metadata.json`
  let target = `https://${host}/${host}-current/${fileName}?cb=${window.$cacheBust}`

  if (window.location.hostname === `localhost`) {
    target = `/${fileName}`
  } else if (host.includes(`preview`)) {
    target = getPreviewTarget(host, fileName)
  }

  return fetch(target).then(data => data.json()).catch(e => {
    throw new Error(`Unable to fetch ${target}`)
  })
}

function getPreviewTarget(host, fileName) {
  const { client, country } = queryString.parse(window.location.search)

  if (!client || !country) {
    return null
  }

  return `https://${host}/${host}-current/${client.toLowerCase()}-${country.toLowerCase()}/${fileName}?cb=${window.$cacheBust}`
}
