import React from 'react'

import { CardBadge, CardBanner, CardAdditional } from 'components/product-listing'
import { HighlightBox } from './highlight-box'
import { PromotionAdditional } from './promotion-additional'
import { ShippingPromo } from './shipping-promo'
import {
  PROMOTIONAREA_PRODUCTCARD_BADGE,
  PROMOTIONAREA_PRODUCTCARD_BANNER,
  PROMOTIONAREA_PRODUCTCARD_ADDITIONAL,
  PROMOTIONAREA_PDP_HIGHLIGHT,
  PROMOTIONAREA_PDP_EXPANDABLE,
  PROMOTIONAREA_PDP_ADDITIONAL,
  PROMOTIONAREA_CHECKOUT_SHIPPINGPROMO
} from 'utils/constants'

export const Promotions = ({ area, promotions }) => {
  const componentMap = {
    [PROMOTIONAREA_PRODUCTCARD_BADGE]: CardBadge,
    [PROMOTIONAREA_PRODUCTCARD_BANNER]: CardBanner,
    [PROMOTIONAREA_PRODUCTCARD_ADDITIONAL]: CardAdditional,
    [PROMOTIONAREA_PDP_HIGHLIGHT]: HighlightBox,
    [PROMOTIONAREA_PDP_EXPANDABLE]: PromotionAdditional,
    [PROMOTIONAREA_PDP_ADDITIONAL]: PromotionAdditional,
    [PROMOTIONAREA_CHECKOUT_SHIPPINGPROMO]: ShippingPromo
  }

  const Component = componentMap[area]

  if (promotions) {
    return promotions.map(promotion => (
      <Component
        key={promotion.content.html}
        {...promotion}
      />
    ))
  }

  return null
}
