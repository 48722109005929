import React from 'react'
import classNames from 'classnames'
import get from 'lodash/get'

import { getComponentOptions } from 'global-content'
import { useContent } from 'hooks'
import { NavigationMenu } from 'components/navigation'
import { Layout } from 'components/layout'

import styles from './banner-navigation.css'

export const BannerNavigation = React.forwardRef(({
  activeL1,
  activeL2,
  hoverL1,
  hoverL2,
  onClick,
  onHover,
  show,
  relative
}, ref) => {
  const { bannerNavigation } = useContent('navMenu')
  const { borderBottom } = getComponentOptions(`header.bannerNav`)

  const augmentation = {
    ...bannerNavigation,
    layout: {
      container: get(bannerNavigation, 'layout.container', 'clientDefault')
    },
    positioning: {
      itemSpacing: get(bannerNavigation, 'positioning.itemSpacing', `large`)
    }
  }

  if (bannerNavigation && show) {
    return (
      <div
        className={classNames(styles.bannerNavigation, {
          [styles.borderBottom]: borderBottom,
          [styles.absolute]: !relative
        })}
        ref={ref}
      >
        <Layout
          className={styles.sections}
          layout={{
            container: augmentation.layout.container
          }}
        >
          <NavigationMenu
            activeL1={activeL1}
            activeL2={activeL2}
            augmentation={augmentation}
            hoverL1={hoverL1}
            hoverL2={hoverL2}
            linkType="menuLinkSecondary"
            key="bannerNavigation"
            onClick={onClick}
            onHover={onHover}
          />
        </Layout>
      </div>
    )
  }

  return null
})
