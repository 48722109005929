import { constructUrl } from 'models/utils'

export function normalizeCartResponse(data) {
  const normalized = {
    ...data,
    items: data.items ? data.items.map(item => normalizeCartItem(item)) : []
  }

  return normalized
}

function normalizeCartItem(item) {
  const { productId, slug } = item

  return {
    ...item,
    productId,
    slug,
    url: constructUrl({ productId, slug })
  }
}
