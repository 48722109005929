import { getLanguage } from 'utils'
import { translatedOptions } from 'utils/translate'

export function setShippingOptions() {
  window.$content.shippingOptions = translatedOptions(window.$META.shippingOptions)
}

export function getShippingOptions() {
  return window.$content.shippingOptions[getLanguage()]
}
