import React from 'react'
import { useSelector } from 'react-redux'

import { useFilters } from 'hooks'

import {
  CategoryHeading,
  OptionsCategoryColumns,
  OptionsColumns,
  OptionsGrid,
  OptionsList,
  RangeSelector,
  SortBy,
  SubcategoryHeading
} from 'components/filters'

import {
  SelectableBox,
  TickableButton,
  SwatchButton
} from 'components/buttons'

export const OptionsContainer = ({ filter, usePending }) => {
  const {
    buttonType = `selectableBox`,
    categorize,
    name,
    showSwatch,
    subcategorize,
    swatchType,
    swatchSize,
    type = `columns`
  } = filter

  const { onFilterSelect } = useFilters(usePending)
  const options = useSelector(state => state.listing.pageFilters[name])
  const selectedFilters = useSelector(state => state.listing.selectedFilters)
  const pendingFilters = useSelector(state => state.listing.pendingFilters)

  const optionsMap = {
    categoryColumns: OptionsCategoryColumns,
    columns: OptionsColumns,
    grid: OptionsGrid,
    list: OptionsList,
    rangeSelector: RangeSelector
  }

  const buttonMap = {
    selectableBox: SelectableBox,
    tickableOption: TickableButton,
    swatch: SwatchButton
  }

  const OptionsComponent = optionsMap[type]
  const ButtonComponent = buttonMap[buttonType]

  const data = buildData({
    subcategorize,
    categorize,
    options
  })

  if (name === `sortBy`) {
    return <SortBy />
  }

  return (
    <OptionsComponent
      renderButton={renderButton}
      renderHeading={renderHeading}
      data={data}
      uiOptions={filter}
      usePending={usePending}
    />
  )

  function renderButton({ rgb, tag, value }) {
    if (value) {
      return (
        <ButtonComponent
          key={tag}
          label={value}
          nowrap
          onClick={() => onFilterSelect(name, tag)}
          selected={checkActive(tag)}
          showSwatch={showSwatch}
          size="small"
          swatchHex={[rgb]}
          swatchType={swatchType}
          swatchSize={swatchSize}
        />
      )
    }

    return null
  }

  function checkActive(tag) {
    if (usePending) {
      return pendingFilters[name] && pendingFilters[name].includes(tag)
    }

    return selectedFilters[name] && selectedFilters[name].includes(tag)
  }

  function renderHeading(html, subcategory) {
    if (subcategory) {
      return <SubcategoryHeading html={html} />
    }

    if (categorize) {
      return <CategoryHeading html={html} />
    }

    return null
  }
}

function buildData({
  categorize,
  options,
  subcategorize
}) {
  if (!categorize && !subcategorize) {
    return options
  }

  if (subcategorize) {
    return buildSubCategories(options)
  }

  if (categorize || subcategorize) {
    return buildCategories(options)
  }
}

function buildCategories(options) {
  let build = {}

  options.forEach(filter => {
    const { category } = filter

    if (build[category]) {
      build[category].push(filter)
    } else {
      build[category] = [filter]
    }
  })

  return build
}

function buildSubCategories(options) {
  let build = {}

  options.forEach(option => {
    const { category, subCategory } = option

    if (build[category]) {
      if (build[category][subCategory]) {
        build[category][subCategory].push(option)
      } else {
        build[category][subCategory] = [option]
      }
    } else {
      build[category] = {
        [subCategory]: [option]
      }
    }
  })

  return build
}
