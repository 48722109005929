import compact from 'lodash/compact'
import numeral from 'numeral'

import { getHost, getMetaOptions } from 'global-content/config'

import analytics from './analytics'
import favicon from './favicon'
import fonts from './fonts'
import getMediaQueries from './mediaQueries'
import tracking from './tracking'
import { storage } from './storage'

export {
  analytics,
  favicon,
  fonts,
  getMediaQueries,
  tracking
}

export function currencyFormatted(value, { includeDecimal } = {}) {
  const { format, symbol } = getMetaOptions('currency')

  if (value === 0) {
    return 'FREE'
  }

  if (includeDecimal) {
    return symbol + numeral(value).format(`0,0.00`)
  }

  return symbol + numeral(value).format(format)
}

export const isSingapore = () => {
  return getMetaOptions('country.code').toLowerCase() === 'sg'
}

export function sizeDown(size, levels = 1) {
  if (levels === 1) {
    switch (size) {
    case 'xl': {
      return 'large'
    }
    case 'large': {
      return 'medium'
    }
    case 'medium': {
      return 'small'
    }
    case 'small': {
      return 'xs'
    }
    }
  }

  if (levels === 2) {
    switch (size) {
    case 'xl': {
      return 'medium'
    }
    case 'large': {
      return 'small'
    }
    case 'medium': {
      return 'xs'
    }
    }
  }
}

/**
 * Creates an array range given a size parameter
 *
 * @export
 * @param {Number} size Size of the returned range
 * @returns Array
 */
export function rangeFromNumber(size) {
  return Array.from({ length: size }, (v, i) => i + 1)
}

export function categorizeFilters(filterType, filters, categoryLabel) {
  const categories = {}

  filters.forEach(filter => {
    const label = filter[categoryLabel]

    if (categories[label]) {
      categories[label].push(filter)
    } else {
      categories[label] = [filter]
    }
  })

  delete categories.undefined

  return categories
}

export const setClientCookie = (key, val, domain) => {
  window.document.cookie = `${key}=${val};domain=${domain};max-age=31536000`
}

export function adyenDeviceFingerprint(inputElementId) {
  // TODO: find a way to respond to when it is actually ready rather than a timeout
  // TODO: call this from app.js after move to react rather than header.js

  // this magic number appears to be a safe bound for when the fingerprint is ready
  // but is not consistently reliable. since the df is optional this is not a problem
  // of known scope
  const delay = 2500
  return new Promise((resolve, reject) => {
    const existingFingerprint = storage.get('deviceFingerprint')
    if (existingFingerprint) {
      resolve(existingFingerprint)
      return
    }

    let adyenScript = document.createElement('script')
    adyenScript.onload = () => {
      window.dfDo(inputElementId)

      // this event and timeout should not be necessary, however because we are a SPA we need to trigger it manually
      document.dispatchEvent(new Event('DOMContentLoaded'))

      setTimeout(() => {
        const elem = document.getElementById(inputElementId)
        if (elem) {
          // TODO: reject if null
          const fingerprint = elem.value
          storage.set('deviceFingerprint', fingerprint)
          resolve(fingerprint)
        }
      }, delay)
    }
    const adyenPath = `https://live.adyen.com/hpp/js/df.js?v=${new Date()}`
    adyenScript.setAttribute('src', adyenPath)

    document.body.appendChild(adyenScript)
  })
}

export function getLevelDown(level) {
  return `l${parseInt(level.split('')[1]) + 1}`
}

export function getLevelUp(level) {
  return `l${parseInt(level.split('')[1]) - 1}`
}

export function getLanguage() {
  // returns 2-letter language code
  // do some in-browser apps not support window.location.pathname properly? Hunch to fix this:
  // https://brandpath.atlassian.net/browse/LIP-626#icft=LIP-626

  const route = window.location.pathname.substr(1).split('/')
  const languagKey = route[0].split('-')[0]

  return languagKey
}

export function capitalize(word = '', strict) {
  if (strict) {
    return `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`
  }

  return `${word.charAt(0).toUpperCase()}${word.slice(1)}`
}

export function capitalizeSentence(sentence, strict) {
  const wordArray = sentence.split(' ')
  return wordArray.map(word => capitalize(word, strict)).join(' ')
}

export function mapOrder(array, order, key) {
  const orderMap = {}
  const result = []

  // create an object which maps the order
  order.map((item, i) => {
    orderMap[item] = i
  })

  // add the items directly into their positions
  // this will leave empty (undefined) spots in the array
  // if the order array is longer than the provided array
  array.map(item => {
    result[orderMap[item[key]]] = item
  })

  // remove the empty spots and return
  return compact(result)
}

export function productUrl(product, includeFqdn = false) {
  const prodUrl = `/product/${product.slug}/${product.id}`
  if (includeFqdn) {
    return `https://${getHost()}${prodUrl}`
  } else {
    return prodUrl
  }
}

export function generateRouteLink(path, parents) {
  const cleanParents = parents.filter(Boolean)
  const base = cleanParents.length ? `/${cleanParents.join('/')}` : ''
  const trimmed = path.startsWith('/') ? path.slice(1) : path

  return `${base}/${trimmed}`
}

export function getIso3CountryCode(iso2Code, countries) {
  return countries.find(country => country.countryCode === iso2Code.toUpperCase())?.code || iso2Code
}

export function getCountryName(iso2Code, countries) {
  return countries.find(country => country.countryCode === iso2Code.toUpperCase())?.name || iso2Code
}
