import React from 'react'

import { animateScroll } from 'react-scroll'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useFilterSets } from 'hooks'

import { getConfigOptions } from 'global-content'

import { Loader } from 'components/loader'
import { Heading } from 'components/text'
import { LinkList } from 'pages/brands'

import styles from './brand-indexes.css'

export const BrandIndexes = () => {
  const { l1 } = useParams()
  const brandIndexes = useSelector(state => state.brands.brandIndexes)
  const brandsPathname = getConfigOptions(`brandsPathname`)
  const index = l1 || 'all'
  const loaded = useFilterSets(true)

  if (!loaded) {
    return (
      <div style={{ minHeight: `45vh`, display: `flex` }}>
        <Loader />
      </div>
    )
  }

  const brandCategoryKeys = Object.keys(brandIndexes[index])

  return (
    <React.Fragment>
      <div className={styles.indexes}>
        {brandCategoryKeys.map(category => (
          <button
            key={`link-${category}`}
            className={styles.letter}
            onClick={() => zoomToIndex(category)}
          >
            {category}
          </button>
        ))}
      </div>
      {
        brandCategoryKeys.map(brand => {
          const brandCategory = brandIndexes[index][brand].map(b => {
            if (l1) {
              return {
                url: `/${l1}/${brandsPathname}/${b.tag}`,
                ...b
              }
            }

            return {
              url: `/${brandsPathname}/${b.tag}`,
              ...b
            }
          })

          return (
            <div
              className={styles.brandList}
              id={`brand-list-${brand}`}
              key={`brand-list-${brand}`}
            >
              <div className={styles.headingWrapper}>
                <Heading
                  content={{
                    html: brand
                  }}
                  styling={{
                    fontFamily: `primary`,
                    fontSize: 'xs',
                    uppercase: true,
                    universalColor: true
                  }}
                />
              </div>
              <div className={styles.linklistWrapper}>
                {divideIntoColumns(brandCategory)}
              </div>
            </div>
          )
        })
      }
    </React.Fragment>
  )
}

function divideIntoColumns(brandCategory) {
  const division = Math.ceil(brandCategory.length / 3)
  const columns = []

  for (let i = 0; i < 3; i += 1) {
    columns.push(
      <LinkList list={brandCategory.slice(division * i, (division * i) + division)} />
    )
  }

  return columns
}

function zoomToIndex(category) {
  const elem = document.querySelectorAll(`#brand-list-${category.toLowerCase()}`)[0]

  if (elem) {
    animateScroll.scrollTo(elem.offsetTop)
  }
}
