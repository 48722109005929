
import { matchPath } from 'react-router-dom'
import cloneDeep from 'lodash/cloneDeep'
import queryString from 'query-string'

import { getMetaOptions } from 'global-content'
import { getSiteName } from 'utils/i18n'

import store from 'state/reducers'

export function setSeoData({
  description,
  headline,
  product = {},
  search
}) {
  const activeLanguage = store.getState().language.active
  const metadataClone = cloneDeep(window.$content.seoData[activeLanguage])

  const keyValues = {
    headline,
    description,
    productName: product.name || '',
    query: queryString.parse(search).query,
    suffix: metadataClone.template.suffix || '',
    promo: metadataClone.template.promo || '',
    siteCountry: getMetaOptions('territory.name'),

    // todo: adding template overrides b/c CONFIG and META are not multi-lingual
    siteName: metadataClone.template.siteName || getSiteName(),
    language: metadataClone.template.language || '',
    country: metadataClone.template.country || ''
  }

  const metadata = templateSelector(metadataClone) || {};

  ['description', 'title'].forEach(attribute => {
    Object.entries(keyValues).forEach(([key, value]) => {
      if (metadata[attribute]) {
        metadata[attribute] = (
          metadata[attribute]
            .replace(`{{${key}}}`, value)
        )
      }
    })
  })

  if (document.querySelector('meta[name="description"]')) {
    document.title = metadata.title
    document.querySelector('meta[name="description"]').content = metadata.description || metadata.title
    document.querySelector('meta[name="keywords"]').content = metadata.keywords || ''
  }
}

function templateSelector(metadata) {
  const metadataRouter = metadata.routes || {}
  const metadataPages = metadata.pages || {}
  const pathnameWithoutPrefix = `/${window.location.pathname.split('/').slice(2).join('/')}`

  // Check for specific page metadata
  if (metadataPages[pathnameWithoutPrefix]) {
    return metadataPages[pathnameWithoutPrefix]
  }

  return (
    Object.keys(metadataRouter).map(route => ({
      ...metadataRouter[route],
      match: route
    }))
      .sort((a, b) => {
        if (a.index > b.index) return 1
        if (a.index < b.index) return -1
        return 0
      })
      .find(key => (
        matchPath(pathnameWithoutPrefix, {
          path: key.match,
          exact: true,
          strict: false
        })
      ))
  )
}
