import React from 'react'
import { batch, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { changeLanguage } from 'state/actions'
import { routeChange, updateNavigation } from 'state/actions/navigation'
import { updateGoogleAnalytics } from 'services/analytics'
import { getLanguage } from 'utils'
import store from 'state/reducers'

let loadedOnce = false
let previousPathname

export const useRouteChange = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (!loadedOnce) {
      loadedOnce = true

      updateLocation({
        ...location,
        action: `INITIAL`
      })
    }
  }, [])

  function updateLocation({ action, pathname, search, force }) {
    const language = getLanguage()
    const activeLanguage = store.getState().language.active
    const languageChanged = language !== activeLanguage

    batch(() => {
      // only do a route change action when the pathname changes
      if (previousPathname !== pathname || force) {
        previousPathname = pathname

        dispatch(routeChange({
          language,
          pathname,
          search
        }))
      }

      dispatch(updateNavigation({
        language,
        pathname,
        search
      }))

      if (languageChanged) {
        dispatch(changeLanguage(getLanguage()))
      }
    })

    // don't fire event when action === REPLACE
    if ([`POP`, `PUSH`, `INITIAL`].includes(action)) {
      if (languageChanged) {
        // log switch language event?
      } else {
        updateGoogleAnalytics('pageview')
      }
    }
  }

  return updateLocation
}
