import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getConfigOptions } from 'global-content'

import { openPullout, overlayOn } from 'state/actions'
import { I18n } from 'utils/i18n'

import { Button } from 'components/buttons'

import styles from './mobile-filters-toggles.css'

export const webLabels = {
  title: `WEB.LISTING.FILTER.TITLE`
}

export const MobileFiltersToggles = () => {
  const dispatch = useDispatch()
  const selectedSort = useSelector(state => state.listing.selectedSort)

  const positioning = {
    buttonHeight: `large`
  }

  const styling = {
    singleLine: true,
    type: `secondary`
  }

  return (
    <div className={styles.container}>
      <div className={styles.toggles}>
        <Button
          onClick={() => togglePullout('mobileFilters')}
          positioning={positioning}
          styling={styling}
        >
          {I18n.t(webLabels.title)}
        </Button>
        <Button
          onClick={() => togglePullout('mobileSortBy')}
          config={{
            onClick: () => togglePullout('mobileSortBy')
          }}
          positioning={positioning}
          styling={styling}
        >
          {I18n.t(getConfigOptions(`sortOptions`).find(option => option.value === selectedSort).label)}
        </Button>
      </div>
    </div>
  )

  function togglePullout(component) {
    dispatch(overlayOn('pullout'))
    dispatch(openPullout(component))
  }
}
