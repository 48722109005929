import React from 'react'

import { LazyWrapper } from 'pages/lazy-wrapper'

export { Brand } from 'pages/brand'
export { Brands } from 'pages/brands'
export { Complete } from 'pages/complete'
export { CustomPage } from 'pages/custom-page'
export { CustomPage2 } from 'pages/custom-page'
export { Faceted } from 'pages/faceted'
export { Favorites } from 'pages/favorites'
export { Homepage } from 'pages/homepage'
export { NotFound } from 'pages/not-found'
export { Redirect } from 'pages/redirect'
export { SearchResults } from 'pages/search-results'
export { PageTemplate } from 'pages/page-template'

const LazyCheckout = React.lazy(() => import(/* webpackChunkName: "checkout" */ 'pages/checkout/checkout'))
const LazyProduct = React.lazy(() => import(/* webpackChunkName: "product" */ 'pages/product/product'))
const LazyRecreateCart = React.lazy(() => import(/* webpackChunkName: "recreate-cart" */ 'pages/recreate-cart/recreate-cart'))
const LazyTracking = React.lazy(() => import(/* webpackChunkName: "tracking" */ 'pages/tracking/tracking'))

export const Checkout = props => (
  <LazyWrapper>
    <LazyCheckout {...props} />
  </LazyWrapper>
)

export const Product = props => (
  <LazyWrapper>
    <LazyProduct {...props} />
  </LazyWrapper>
)

export const RecreateCart = props => (
  <LazyWrapper>
    <LazyRecreateCart {...props} />
  </LazyWrapper>
)

export const Tracking = props => (
  <LazyWrapper>
    <LazyTracking {...props} />
  </LazyWrapper>
)
