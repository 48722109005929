import React from 'react'

import { Svg } from 'components/icons'
import { Swatch } from 'components/swatches'

import styles from './tickable-button.css'

export const TickableButton = ({ label, onClick, selected, showSwatch, swatchHex, swatchType }) => (
  <button
    className={styles.button}
    onClick={onClick}
  >
    {selected && <Svg icon="ok" />}
    {showSwatch && (
      <div className={styles.swatchWrapper}>
        <Swatch
          swatchHex={swatchHex}
          swatchType={swatchType}
          swatchSize="small"
        />
      </div>
    )}
    {label}
  </button>
)
