import React from 'react'
import get from 'lodash/get'
import queryString from 'query-string'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useContent, useFilters } from 'hooks'

import { getLevelDown } from 'utils'

import { SideNavLink } from 'components/text'

import styles from './category-nav.css'

export const CategoryNav = ({ levelsToShow = 2 }) => {
  const { search } = useLocation()
  const { onFilterSelectReplace } = useFilters()
  const availableCategories = useSelector(state => state.listing.pageFacets['categories']) || {}
  const navTree = useContent('navTree')
  let categoriesToShow = []

  recurisveLookup(navTree.l1, 'l1')
  const categoryString = queryString.parse(search).categories || ''

  return (
    <div className={styles.categoryNav}>
      {categoriesToShow.map(({ categories, indent, label }) => (
        <div
          key={`${categories}-${label}-${indent}`}
          style={{
            marginLeft: `${(indent - 1) * 5}px`
          }}
        >
          <SideNavLink
            active={categoryString === categories.join(`,`)}
            button
            onClick={() => handleClick(categories)}
            value={label}
          />
        </div>
      ))}
    </div>
  )

  // TODO: WRITE A SCOPED WRAPPER TO KEEP THIS PURER RATHER MUTATING categoriesToShow
  function recurisveLookup(obj, level) {
    const levelDown = getLevelDown(level)
    Object.keys(obj).forEach(key => {
      const categories = get(obj[key], 'filters.categories', [])
      const onSale = get(obj[key], 'filters.onSale', false)

      if (categories.some(cat => Object.keys(availableCategories).includes(cat)) && !onSale) {
        categoriesToShow.push({
          categories,
          indent: level[1],
          label: obj[key].label
        })
      }

      if (obj[key][levelDown] && levelDown[1] <= levelsToShow) {
        recurisveLookup(obj[key][levelDown], levelDown)
      }
    })
  }

  function handleClick(categories) {
    onFilterSelectReplace(`categories`, categories)
  }
}
