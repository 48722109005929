import { getLanguage } from 'utils'
import { unversionedStorage } from 'utils/storage'
import { getMetaOptions } from 'global-content/config'
import {
  CHANGE_LANGUAGE,
  LOADED_DEPENDENCIES
} from 'state/actions'

const initialState = {
  active: getLanguage() || 'en'
}

const language = (state = initialState, action) => {
  switch (action.type) {
  case LOADED_DEPENDENCIES: {
    const lang = getLanguage() || getMetaOptions('defaultLanguage')
    updateDomLanguage(lang)

    return {
      active: lang
    }
  }

  case CHANGE_LANGUAGE: {
    const lang = action.payload
    updateUrl(state.active, lang)
    updateDomLanguage(lang)
    updateStorageLanguage(lang)

    return {
      active: action.payload
    }
  }
  default:
    return state
  }
}

function updateUrl(current, replace) {
  const redirectUrl = window.location.href.replace(`/${current}`, `/${replace}`)
  window.history.pushState({}, '', redirectUrl)
}

function updateDomLanguage(lang) {
  // https://www.w3schools.com/tags/ref_language_codes.asp
  document.querySelector('html').setAttribute('lang', lang)
  document.querySelector('html').className = lang
}

function updateStorageLanguage(newLang) {
  const clientTag = getMetaOptions('clientTag')
  const country = getMetaOptions('country.code').toLowerCase()
  const preChosenLanguages = unversionedStorage.get('chosenLanguages')

  const chosenLanguage = {
    [`${clientTag}-${country}`]: newLang
  }
  let chosenLanguages
  if (preChosenLanguages) {
    chosenLanguages = {
      ...preChosenLanguages,
      ...chosenLanguage
    }
  } else {
    chosenLanguages = chosenLanguage
  }

  unversionedStorage.set('chosenLanguages', chosenLanguages)
}

export default language
