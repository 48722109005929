import React from 'react'
import merge from 'lodash/merge'
import { connect } from 'react-redux'
import get from 'lodash/get'

import { I18n } from 'utils/i18n'
import { getLevelDown, getLevelUp } from 'utils'

import { closePullout, overlayOff } from 'state/actions'

import { FooterNav } from 'components/footer'
import { Svg } from 'components/icons'
import { LanguageToggle } from 'components/pullout'
import { Link } from 'components/text'

import styles from './mobile-navigation.css'

const iconWidth = '3.2rem'

export const webLabels = {
  back: `WEB.CTA.BACK`,
  viewAll: `WEB.MOBILENAV.VIEWALL`
}
class MobileNavigationClass extends React.Component {
  constructor(props) {
    super(props)
    const { activeLanguage } = props
    this.mobileNavAugmentations = get(window.$content.navTree[activeLanguage], 'mobileNavigation.augmentations', {})
    this.mobileNavTree = merge({}, window.$content.navTree[activeLanguage], this.mobileNavAugmentations)

    this.state = {
      active: {
        l1: undefined,
        l2: undefined,
        l3: undefined
      },
      show: 'l1'
    }
  }

  generateSlug() {
    const { active } = this.state
    const { l1, l2, l3 } = active
    const filterFalseValues = [l1, l2, l3].filter(slug => slug).join('/')

    return `${filterFalseValues}`
  }

  navClick(e) {
    const { dispatch } = this.props
    dispatch(closePullout())
    dispatch(overlayOff('pullout'))
  }

  buttonClick(level, levelDown, key) {
    this.setState({
      active: {
        ...this.state.active,
        [level]: key
      },
      show: levelDown
    })
  }

  goBack() {
    const { active, show } = this.state
    const levelUp = getLevelUp(show)
    this.setState({
      active: {
        ...active,
        [show]: undefined
      },
      show: levelUp
    })
  }

  renderIcon(icon, size = iconWidth) {
    if (icon) {
      return (
        <div className={styles.iconWrapper}>
          <Svg
            icon={icon}
            size={size}
          />
        </div>
      )
    }

    return null
  }

  renderButtonOrLink(level, key, object, index) {
    const levelDown = getLevelDown(level)
    const testId = `mobile-nav-link-${level}-${index}`

    if (object[levelDown]) {
      return (
        <button
          className={styles.navItem}
          data-testid={testId}
          key={key}
          onClick={this.buttonClick.bind(this, level, levelDown, key)}
        >
          {this.renderIcon(object.icon)}
          {object.label}
          <Svg icon="angle_right" />
        </button>
      )
    }

    let href = this.generateSlug()
    if (href) {
      href = `/${href}/${key}`
    } else {
      href = `/${key}`
    }

    return (
      <Link
        className={styles.navItem}
        data-testid={testId}
        key={key}
        onClick={this.navClick.bind(this)}
        to={object.path || href}
      >
        {this.renderIcon(object.icon)}
        {object.label}
        {object.sublabel &&
          <div className={styles.subLabel}>
            {object.sublabel}
          </div>
        }
      </Link>
    )
  }

  render() {
    const { active, show } = this.state
    const { l1, l2, l3 } = active

    const activeLevel = {
      l1: this.mobileNavTree.l1,
      l2: l1 && this.mobileNavTree.l1[l1].l2,
      l3: l2 && this.mobileNavTree.l1[l1].l2[l2].l3,
      l4: l3 && this.mobileNavTree.l1[l1].l2[l2].l3[l3].l4
    }

    const levelUp = getLevelUp(show)
    let mapArray = Object.keys(activeLevel[show])

    if (activeLevel[show].order) {
      mapArray = activeLevel[show].order
    }

    return (
      <nav className={styles.mobileNavigation}>
        {show !== 'l1' &&
          <React.Fragment>
            <button
              className={styles.navItem}
              onClick={this.goBack.bind(this)}
            >
              {this.renderIcon('angle_left', '1em')}
              {I18n.t(webLabels.back)}
            </button>

            <Link
              className={styles.navItem}
              data-testid="mobile-nav-link-view-all"
              onClick={this.navClick.bind(this)}
              to={activeLevel[levelUp][active[levelUp]].path || `/${this.generateSlug()}`}
            >
              {I18n.t(webLabels.viewAll, {
                replace: {
                  label: I18n.t(activeLevel[levelUp][active[levelUp]].label)
                }
              })}
            </Link>
          </React.Fragment>
        }
        {mapArray.map((key, i) => (
          this.renderButtonOrLink(show, key, activeLevel[show][key], i)
        ))}
        <LanguageToggle iconWidth={iconWidth} />
        <div className={styles.footerNavWrapper}>
          <FooterNav
            mobile
            onClick={this.navClick.bind(this)}
          />
        </div>
      </nav>
    )
  }
}

const mapStateToProps = state => ({
  activeLanguage: state.language.active
})

export const MobileNavigation = connect(mapStateToProps)(MobileNavigationClass)
