import React from 'react'
import { useSelector } from 'react-redux'

import { Heading } from 'components/text'

export const Headline = ({ content = {}, styling = {} }) => {
  const h1 = useSelector(state => state.navigation.currentPage.h1)
  const { fontFamily, fontWeight, fontSize, textAlign } = styling

  return (
    <Heading
      content={{
        html: content.html || h1
      }}
      styling={{
        fontFamily,
        fontWeight,
        fontSize,
        textAlign,
        universalColor: true
      }}
    />
  )
}
