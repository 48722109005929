import React from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useFilters } from 'hooks'
import { getComponentOptions, getConfigOptions } from 'global-content'

import { I18n } from 'utils/i18n'

import styles from './sort-by.css'

export const SortBy = ({ buttonWrapperClass, onClick }) => {
  const { applySort } = useFilters()
  const selectedSort = useSelector(state => state.listing.selectedSort)
  const { pathname } = useLocation()
  const { size } = getComponentOptions(`paginator`) // Temporary sharing with paginator size

  return (
    <div
      className={classNames({
        [styles[size]]: size
      })}
    >
      {getConfigOptions(`sortOptions`).map(option => {
        const { label, onlyAppear, value } = option
        const active = value === selectedSort

        if (onlyAppear && !onlyAppear.includes(pathname)) {
          return null
        }

        return (
          <div key={value}>
            <button
              className={classNames(styles.option, {
                [buttonWrapperClass]: buttonWrapperClass,
                [styles.active]: active
              })}
              onClick={() => handleClick(value, active)}
            >
              {I18n.t(label)}
            </button>
          </div>
        )
      })}
    </div>
  )

  function handleClick(optionName, active) {
    !active && applySort(optionName)
    onClick && onClick()
  }
}
