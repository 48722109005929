import React from 'react'

import { useContent } from 'hooks'
import {
  DESKTOP_KEY,
  TABLET_MEDIUM_KEY,
  TABLET_SMALL_KEY
} from 'utils/constants'
import { Factory, v1FactoryToV2 } from 'components/factory'
import { FooterNav } from 'components/footer'
import { IconsGroup, PaymentIcons } from 'components/icons'
import { Link } from 'components/text'
import { Layout } from 'components/layout'
import { Responsive } from 'components/responsive'

import styles from './footer.css'

export const Footer = () => {
  const { content } = useContent('footer')

  if (content) {
    const { components, disclaimer, paymentIcons = {}, socialIcons = [] } = content
    const { branded } = paymentIcons
    const items = components
      ? v1FactoryToV2({ components })
      : undefined

    return (
      <React.Fragment>
        {items &&
          <Factory items={items} />
        }
        <div className={styles.footer}>
          <Layout layout={{ container: `clientDefault` }}>
            <FooterNav />
            <div className={styles.socialIconsContainer}>
              <IconsGroup
                icons={socialIcons}
                inlineGrid
                size="3rem"
              />
            </div>
          </Layout>
        </div>
        <div className={styles.footerBottom}>
          <div className={styles.paymentIconsContainer}>
            {renderPaymentIcons(branded)}
          </div>
          {disclaimer && (
            <Layout>
              <div className={styles.footerCopy}>
                <span>{disclaimer.line1}</span>
                <Link to={disclaimer.aboutUs.path} className={styles.footerCopyLink}>
                  <span>{disclaimer.aboutUs.label}</span>
                </Link>
                <span>{disclaimer.line2}</span>
              </div>
            </Layout>
          )}
        </div>
      </React.Fragment>
    )
  }

  return null
}

function renderPaymentIcons(branded) {
  return (
    <>
      <Responsive deviceRange={[DESKTOP_KEY, TABLET_MEDIUM_KEY]}>
        <PaymentIcons
          branded={branded}
          height="3rem"
        />
      </Responsive>
      <Responsive deviceRange={[TABLET_SMALL_KEY]}>
        <PaymentIcons
          branded={branded}
          height="3rem"
          maxPerRow={6}
        />
      </Responsive>
    </>
  )
}
