import React from 'react'

import { I18n } from 'utils/i18n'
import { Svg } from 'components/icons'
import { SearchInput } from 'components/inputs'

import styles from './options-list.css'

export const webLabels = {
  viewAll: `WEB.LABELS.COMMON.VIEWALL`
}

export const OptionsList = ({
  uiOptions,
  renderButton,
  renderHeading,
  data
}) => {
  const { limit } = uiOptions
  const [show, setShow] = React.useState(limit || Infinity)
  const [userInput, setUserInput] = React.useState()

  return (
    <div className={styles.container}>
      <div className={styles.viewport}>
        {renderContent(data)}
      </div>
    </div>
  )

  function renderContent(d, i = 0) {
    if (Array.isArray(d)) {
      return renderList(d)
    }

    return renderCategories(d, i)
  }

  function renderList(options) {
    let matchedOptions = options

    if (userInput) {
      matchedOptions = options.filter(filter => (
        filter.value.toLowerCase().includes(userInput.toLowerCase())
      ))
    }

    let listToShow = matchedOptions.slice(0, show).map(filter => (
      <div
        className={styles.buttonWrapper}
        key={filter.tag}
      >
        {renderButton(filter, styles.buttonWrapper)}
      </div>
    ))

    if (limit && options.length > limit) {
      return (
        <React.Fragment>
          <SearchInput
            onSubmit={() => {}}
            onChange={newFilter => setUserInput(newFilter)}
            variation="filters"
          />
          {listToShow}
          {show < matchedOptions.length &&
            <button
              className={styles.showMore}
              onClick={() => setShow(Infinity)}
            >
              {I18n.t(webLabels.viewAll)}
              <Svg
                icon="plus"
                size="1rem"
              />
            </button>
          }
        </React.Fragment>
      )
    }

    return listToShow
  }

  function renderCategories(categories, i) {
    const categoryNames = Object.keys(categories)

    return categoryNames.map(category => (
      <div
        className={styles.category}
        key={category}
      >
        {category && category !== `undefined` && renderHeading(category, i)}
        {renderContent(categories[category], i + 1)}
      </div>
    ))
  }
}
