import React from 'react'
import get from 'lodash/get'
import { useSelector } from 'react-redux'

import { currencyFormatted } from 'utils'
import { useFilters } from 'hooks'
import { CREATEFILTERSMAP } from 'services/algolia'
import { SelectedFilterButton } from 'components/filters'

export const SelectedOptions = ({ name }) => {
  const activeLanguage = useSelector(state => state.language.active)
  const allFilters = useSelector(state => state.listing.allFilters)[activeLanguage]
  const selectedFilters = useSelector(state => state.listing.selectedFilters)[name]
  const loadedLanguages = useSelector(state => state.listing.loadedLanguages)
  const { onFilterSelect } = useFilters()

  const { currency, filterType } = CREATEFILTERSMAP()[name]

  if (filterType === 'numeric') {
    return (
      <SelectedFilterButton onClick={() => onFilterSelect(name)}>
        {renderNumber(selectedFilters[0])} - {renderNumber(selectedFilters[1])}
      </SelectedFilterButton>
    )
  }

  if (loadedLanguages.includes(activeLanguage)) {
    return selectedFilters.map(renderItem)
  }

  return selectedFilters.map(renderSkeleton)

  function renderNumber(value) {
    if (currency) {
      return currencyFormatted(value)
    }
  }

  function renderItem(option) {
    const value = get(allFilters, `${name}.${option}.value`, option)

    return (
      <SelectedFilterButton
        key={option}
        onClick={() => onFilterSelect(name, option)}
      >
        {value}
      </SelectedFilterButton>
    )
  }

  function renderSkeleton(option) {
    return (
      <SelectedFilterButton key={option}>
        <span style={{ opacity: 0 }}>
          {option}
        </span>
      </SelectedFilterButton>
    )
  }
}
