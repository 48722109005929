import React from 'react'

import { PageTemplate } from 'pages'
import { PageBody } from 'components/scaffold'
import { Factory } from 'components/factory'

export const Faceted = () => {
  return (
    <PageTemplate template="plp">
      {FacetedComponent}
    </PageTemplate>
  )
}

const FacetedComponent = ({ items, ...props }) => {
  return (
    <PageBody {...props}>
      <Factory items={items} />
    </PageBody>
  )
}
