import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { getComponentOptions } from 'global-content'
import { useFilters, useOnClickOutside } from 'hooks'
import { FilterMenuButton, OptionsContainer } from 'components/filters'
import { SkeletonText } from 'components/skeleton'
import { Scrollable } from 'components/scrollable'

import styles from './dropdown-filters.css'

export const DropdownFilters = ({ content, styling = {} }) => {
  const { filterMenuButtonType } = styling
  const { removeUnavailableFilters } = useFilters()
  const loadedLanguages = useSelector(state => state.listing.loadedLanguages)
  const activeLanguage = useSelector(state => state.language.active)

  const [activeName, setActiveName] = React.useState()
  const ref = React.useRef()
  useOnClickOutside(ref, handleClickOutside)
  const { filters } = content
  const loaded = loadedLanguages.includes(activeLanguage)
  const { close, fontSize, open } = getComponentOptions(`filterMenuButton.content.areas.dropdown`)

  return (
    <div
      className={styles.container}
      ref={ref}
    >
      {renderContent()}
    </div>
  )

  function renderContent() {
    if (!loaded) {
      return filters.map(filter => (
        <SkeletonText
          key={filter.name}
          margin="0 1rem 0 0"
          width="80px"
        />
      ))
    }

    return (
      filters
        .filter(removeUnavailableFilters)
        .map(filter => {
          const { name, type } = filter
          const active = activeName === name

          const minWidthMap = {
            grid: `500px`,
            list: `300px`,
            rangeSelector: `500px`
          }

          const minWidth = name !== `sortBy` ? minWidthMap[type] : `auto`

          return (
            <FilterMenuButton
              active={active}
              buttonType={filterMenuButtonType}
              className={classNames(styles.button, {
                [styles.active]: active
              })}
              closeIcon={close.iconRef}
              fontSize={fontSize}
              key={name}
              name={name}
              onClick={() => toggle(name)}
              openIcon={open.iconRef}
            >
              {active &&
                <div
                  className={styles.dropdown}
                  data-testid="dropdown-container"
                >
                  <Scrollable
                    className={classNames(styles.viewport)}
                    style={{
                      minWidth
                    }}
                  >
                    <OptionsContainer filter={filter} />
                  </Scrollable>
                </div>
              }
            </FilterMenuButton>
          )
        })
    )
  }

  function handleClickOutside() {
    setActiveName(undefined)
  }

  function toggle(name) {
    if (activeName === name) {
      setActiveName(undefined)
    } else {
      setActiveName(name)
    }
  }
}
