import React from 'react'
import * as Sentry from '@sentry/browser'

import { ScaffoldError } from './scaffold-error'
import { TextError } from './text-error'
import { logToCloudWatch } from 'utils/logToCloudWatch'

function withErrorBoundry(
  Component,
  errorComponentRef,
  loggingMessage = `Render-Error-Boundary`
) {
  class ErrorBoundry extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        error: undefined
      }

      this.errorMap = {
        scaffold: ScaffoldError,
        text: TextError
      }
    }

    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      console.error(error)
      return {
        error
      }
    }

    componentDidCatch(error, info) {
      logToCloudWatch(loggingMessage, {
        error: error.toString()
      })

      Sentry.captureMessage(`${loggingMessage}: ${error}`)
    }

    render() {
      const { error } = this.state

      if (error) {
        const ErrorComponent = this.errorMap[errorComponentRef]
        if (ErrorComponent) {
          return (
            <ErrorComponent error={error} />
          )
        }

        return null
      }

      return <Component {...this.props} />
    }
  }

  return ErrorBoundry
}

export default withErrorBoundry
