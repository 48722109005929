
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { getConfigOptions } from 'global-content'
import { useUrlManager } from 'hooks'
import { CREATEFILTERSMAP } from 'services/algolia'
import { updateFilteroptions, updatePendingFilterOptions } from 'state/actions/listing'
import { INITIAL_PAGE } from 'state/reducers/listing'
import { getBrandRoute } from 'router'

export const defaultSearchSort = `bestMatch`

export function useFilters(usePending) {
  const brandMatch = useRouteMatch(getBrandRoute())
  const urlManager = useUrlManager()
  const dispatch = useDispatch()
  const pendingFilters = useSelector(state => state.listing.pendingFilters)
  const selectedFilters = useSelector(state => state.listing.selectedFilters)
  const pageFilters = useSelector(state => state.listing.pageFilters)

  return {
    applyPage,
    applyPending,
    applyQuery,
    applySort,
    batchApply,
    clearSelected,
    onFilterSelect,
    onFilterSelectReplace,
    removeUnavailableFilters
  }

  function applyPage(pageToApply) {
    if (pageToApply !== 0) {
      urlManager({
        params: {
          append: {
            page: pageToApply
          }
        }
      })
    } else {
      urlManager({
        params: {
          remove: [`page`]
        }
      })
    }

    dispatch(updateFilteroptions({
      page: pageToApply
    }))
  }

  function applyPending() {
    urlManager({
      method: `replace`,
      params: {
        append: {
          ...pendingFilters
        },
        remove: [`page`]
      }
    })

    dispatch(updateFilteroptions({
      filters: pendingFilters,
      page: INITIAL_PAGE
    }))
  }

  function applyQuery(query) {
    urlManager({
      params: {
        replace: {
          query,
          sortBy: defaultSearchSort
        },
        remove: [`page`]
      }
    })

    dispatch(updateFilteroptions({
      filters: {},
      sortBy: defaultSearchSort,
      query,
      page: INITIAL_PAGE
    }))
  }

  function applySort(option) {
    urlManager({
      params: {
        append: {
          sortBy: option
        },
        remove: [`page`]
      }
    })

    dispatch(updateFilteroptions({
      sortBy: option,
      page: INITIAL_PAGE
    }))
  }

  function batchApply(options) {
    const { sortBy, query, page, filters } = options

    urlManager({
      method: `replace`,
      params: {
        append: { sortBy, query, page, ...filters }
      }
    })

    dispatch(updateFilteroptions({
      page,
      filters,
      sortBy,
      query
    }))
  }

  function clearSelected() {
    urlManager({
      method: `replace`,
      params: {
        remove: [`page`, ...Object.keys(selectedFilters)]
      }
    })

    dispatch(updatePendingFilterOptions({}))
    dispatch(updateFilteroptions({
      filters: {},
      page: INITIAL_PAGE
    }))
  }

  function onFilterSelect(facetKey, option) {
    if (usePending) {
      onPendingFilterSelect(facetKey, option)
    } else {
      onInstantFilterSelect(facetKey, option)
    }
  }

  function onPendingFilterSelect(facetKey, option) {
    const updatedValue = getNewValue(facetKey, option, pendingFilters)

    dispatch(updatePendingFilterOptions(
      removeEmptyKeys({
        ...pendingFilters,
        [facetKey]: updatedValue
      })
    ))
  }

  function onInstantFilterSelect(facetKey, option) {
    const updatedValue = getNewValue(facetKey, option, selectedFilters)

    if (updatedValue && updatedValue.length) {
      urlManager({
        method: `replace`,
        params: {
          append: {
            [facetKey]: updatedValue
          },
          remove: [`page`]
        }
      })
    } else {
      urlManager({
        method: `replace`,
        params: {
          remove: [`page`, facetKey]
        }
      })
    }

    dispatch(updateFilteroptions({
      filters: removeEmptyKeys({
        ...selectedFilters,
        [facetKey]: updatedValue
      }),
      page: INITIAL_PAGE
    }))
  }

  function onFilterSelectReplace(filterType, option) {
    urlManager({
      method: `replace`,
      params: {
        append: {
          [filterType]: option
        },
        remove: [`page`]
      }
    })

    dispatch(updateFilteroptions({
      filters: {
        ...selectedFilters,
        [filterType]: option
      },
      page: INITIAL_PAGE
    }))
  }

  function removeUnavailableFilters({ name }) {
    if (name === `sortBy`) {
      return true
    }

    const showBrand = getConfigOptions(`variant.show.brand`)

    if (
      name === `brand` &&
      (!showBrand || brandMatch)
    ) {
      return false
    }

    const FILTERSMAP = CREATEFILTERSMAP()
    const { filterType } = FILTERSMAP[name]

    if (
      filterType === `numeric` &&
      pageFilters[name] &&
      pageFilters[name].length < 2
    ) {
      return false
    }

    if (pageFilters[name] && pageFilters[name].length) {
      return true
    }
  }
}

function getNewValue(facetKey, option, filters) {
  const FILTERSMAP = CREATEFILTERSMAP()
  const { filterType } = FILTERSMAP[facetKey]

  if (filterType === `numeric`) {
    return option
  }

  const options = filters[facetKey] || []

  if (options.includes(option)) {
    return options.filter(opt => opt !== option)
  }

  return [...options, option]
}

function removeEmptyKeys(newFilters) {
  let build = {}

  Object.entries(newFilters).forEach(([key, value]) => {
    if (value) {
      build[key] = value
    }
  })

  return build
}
