import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { CategoryNav } from 'components/category-nav'
import { SideNav } from 'pages/faceted'

import {
  FAVORITES_ROUTE,
  SEARCH_ROUTE,
  getBrandRoute
} from 'router'

export const SideNavigation = ({
  config = {}
}) => {
  const { levelsToShow } = config

  return (
    <Switch>
      <Route exact path={FAVORITES_ROUTE} render={renderCategoryNav} />
      <Route exact path={SEARCH_ROUTE} render={renderCategoryNav} />
      <Route exact path={getBrandRoute()} render={renderCategoryNav} />
      <Route render={renderSideNav} />
    </Switch>
  )

  function renderCategoryNav() {
    return (
      <CategoryNav levelsToShow={levelsToShow} />
    )
  }

  function renderSideNav() {
    return (
      <SideNav />
    )
  }
}
