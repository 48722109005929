import get from 'lodash/get'

import { loadContent } from 'services/content'
import { translate } from 'utils/translate'
import { formatCampaigns } from './campaigns'

export function contentDependencies(language) {
  return Promise.all([
    loadCampaigns(language),
    loadClpRoutes(language),
    loadSeoData(language),
    loadBadges(language),
    loadPromotions(language),
    loadFooter(language),
    loadFooterNav(language),
    loadHead(language),
    loadNavigation(language)
  ])
}

export function getContent(key, language) {
  const content = get(window.$content, key)

  if (content) {
    return content[language] || content
  }
}

export function setContent({
  target,
  content,
  language,
  doI18n
}) {
  window.$content[target] = {
    ...window.$content?.[target],
    [language]: translate({ content, language, doI18n })
  }
}

export function loadBadges(language) {
  return loadContent('/data/badges.json').then(data => {
    setContent({
      target: `badges`,
      content: data.content,
      language
    })
  })
}

export function loadFooter(language) {
  return loadContent('/data/footer.json').then(data => {
    setContent({
      target: `footer`,
      content: data.content,
      language
    })
  })
}

export function loadFooterNav(language) {
  return loadContent('/data/footerNav.json').then(data => {
    setContent({
      target: `footerNav`,
      content: data.content,
      language
    })
  })
}

export function loadHead(language) {
  return loadContent('/data/head.json').then(data => {
    setContent({
      target: `head`,
      content: data.content,
      language
    })
  })
}

export function loadNavigation(language) {
  return Promise.all([
    loadContent('/data/navTree.json'),
    loadContent('/data/navMenu.json')
  ]).then(([navTree, navMenu]) => {
    setContent({
      target: `navTree`,
      content: navTree.content,
      language
    })
    setContent({
      target: `navMenu`,
      content: navMenu.content,
      language
    })
  })
}

export function loadPromotions(language) {
  return loadContent('/data/promotions.json').then(data => {
    setContent({
      target: `promotions`,
      content: data.content,
      language
    })
  })
}

export function loadClpRoutes() {
  // returns list of CLP routes from a json file generated during build process
  // in lithos-content. these routes are then injected into our router
  return loadContent('/data/clp-routes.json').then(data => {
    window.$content.routes = {
      list: data.content
    }
  })
}

export function loadSeoData(language) {
  return loadContent('/data/seoData.json').then(data => {
    setContent({
      target: `seoData`,
      content: data.content,
      language
    })
  })
}

export function loadCampaigns(language) {
  return loadContent('/data/campaigns/campaigns.json').then(({ content }) => {
    if (content?.length) {
      return Promise.all(content.map(loadCampaign)).then(campaigns => {
        const formattedCampaigns = formatCampaigns(campaigns, language)
        setContent({
          target: `campaigns`,
          content: formattedCampaigns,
          language,
          doI18n: false
        })
      })
    }
  }).catch((e) => {
    console.error(e)
    return Promise.resolve()
  })
}

function loadCampaign(campaignFile) {
  return loadContent(`/data/campaigns/${campaignFile}`)
    .then(({ content }) => content)
}
