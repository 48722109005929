import React from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { prerenderReady } from 'state/actions'
import { useDataFetching, useRouteChange } from 'hooks'
import { MediaQuery2, PageBody } from 'components/scaffold'
import { Factory } from 'components/factory'
import { PageTemplate } from 'pages'

import withErrorBoundry from 'components/errors/with-error-boundry'

export const PRERENDER_CLASS = `prerenderHook`

export const CustomPage = withErrorBoundry(({ cacheName, folder, source }) => {
  const [file, triedFetch] = useDataFetching({ cacheName, folder, source })
  const dispatch = useDispatch()
  const { pathname, search } = useLocation()
  const updateLocation = useRouteChange()

  React.useEffect(() => {
    if (file) {
      updateLocation({
        pathname,
        search
      })

      dispatch(prerenderReady(`.${PRERENDER_CLASS}`))
    }
  }, [file])

  if (!triedFetch) {
    return <PageBody loading />
  }

  if (file?.data?.template) {
    return (
      <PageTemplate
        {...file}
        template={file.data.template}
      >
        {CustomPageComponent}
      </PageTemplate>
    )
  }

  if (file) {
    return (
      <MediaQuery2 items={file.items}>
        {CustomPageComponent}
      </MediaQuery2>
    )
  }

  return null
}, 'scaffold')

const CustomPageComponent = ({ items, ...props }) => {
  return (
    <PageBody {...props}>
      <Factory items={items} />
      <div
        className={PRERENDER_CLASS}
        data-testid={PRERENDER_CLASS}
      />
    </PageBody>
  )
}
