import queryString from 'query-string'
import merge from 'lodash/merge'

import { getHost, getMetaOptions } from 'global-content/config'
import { logToCloudWatch } from 'utils/logToCloudWatch'
import { getLanguage } from 'utils'
import { AfterPay } from 'utils/afterPay'
import { GooglePay } from 'utils/googlePay'
import { storage } from 'utils/storage'

let authAttempts = 0
export const maxAuthAttempts = 3
export const checkoutURL = 'checkout/payment'
export const sessionIdName = 'x-amz-cf-id'

export function resetAuthAttempt() {
  // DO NOT CALL this outside of tests. we need a way to reset the state between tests
  authAttempts = 0
}

// This is a function rather than a plain object so it is correct at run time.
export function defaultOptions() {
  return {
    credentials: 'include',
    headers: {
      shoppingSessionId: window.$shoppingSessionId.value,
      'content-type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }
}

export function baseUrl() {
  return `https://${getHost()}/api/`
}

export function call({ path, params }, options) {
  const mergedOptions = merge({}, defaultOptions(), options)

  const stringifiedParams = queryString.stringify(params)
  const append = stringifiedParams ? `?${stringifiedParams}` : ''
  const endpoint = `${path}${append}`
  const pathname = `${baseUrl()}${endpoint}`

  const sessionName = sessionStorage.getItem(sessionIdName)

  if (sessionName) {
    return fetch(pathname, mergedOptions)
      .then(response => {
        if (response.status === 401) {
          return createSession()
            .then(data => call({ path, params }, mergedOptions))
        }
        if (response.status >= 400) {
          try {
            return response.json().then(json => { throw json }) // e.g. cart api throwing 400 and line item error
          } catch {
            // Return the whole response as an error so we can read the status
            throw response
          }
        }

        return response
      })
      .then(response => {
        authAttempts = 0
        if (typeof response.text === 'function') {
          return response.text()
            .then(text => JSON.parse(text || '{}'))
        } else {
          return response
        }
      })
      .catch(error => {
        console.warn(`API request failed at ${path} with error status ${error.status}`)

        if (pathname.includes(checkoutURL)) {
          logToCloudWatch('Error-Checkout', {
            error: `API request failed with status ${error.status}`,
            sessionId: storage.get(sessionIdName)
          })
        }
        return Promise.reject(error)
      })
  }

  return createSession()
    .then(data => call({ path, params }, mergedOptions))
}

/* REST VERBS */

export function get(pathAndParams, options) {
  return call(pathAndParams, options)
}

export function post(pathAndParams, options) {
  return call(
    pathAndParams,
    {
      ...defaultOptions(),
      ...options,
      method: 'POST'
    }
  )
}

export function put(pathAndParams, options) {
  return call(
    pathAndParams,
    {
      ...options,
      method: 'PUT'
    }
  )
}

export function deleteCall(pathAndParams, options) {
  return call(
    pathAndParams,
    {
      ...options,
      method: 'DELETE'
    }
  )
}

/* API METHODS */
/* SESSION */

export function createSession() {
  authAttempts++

  if (authAttempts > maxAuthAttempts) {
    throw new Error(`Failed to authenticate after ${maxAuthAttempts} attempts`)
  }

  const sessionEndpoint = `${baseUrl()}session`
  const options = {
    ...defaultOptions(),
    headers: {
      ...defaultOptions().headers,
      Authorization: 'Basic dGVzdDp0ZXN0'
    },
    body: JSON.stringify({
      language: getLanguage(),
      sitePublicId: getMetaOptions('sessionCreationKey')
    }),
    method: 'POST'
  }

  return fetch(sessionEndpoint, options)
    .then(response => {
      // save sessionId to localStorage for logging
      for (let pair of response.headers.entries()) {
        if (pair[0] === sessionIdName) {
          storage.set(sessionIdName, pair[1])
          sessionStorage.setItem(sessionIdName, pair[1])
        }
      }
      return response
    })
}

export function restartSession() {
  window.$shoppingSessionId.set()

  return deleteCall({ path: 'session' })
    .then(() => {
      createSession()
    })
}

/* CHECKOUT */

export function checkout(customerInformation) {
  return post({ path: checkoutURL }, { body: JSON.stringify(customerInformation) })
}

export function checkoutWithPaypal(data, orderReference) {
  return put(
    { path: `checkout/payment/${orderReference}` },
    { body: JSON.stringify(data) }
  )
}

export function checkoutWithGooglePay(customerInformation, googlePayOptions) {
  let googlePay = new GooglePay({
    customerInformation,
    options: googlePayOptions
  })

  return loadCartContents().then((cart) => {
    return googlePay.googlePayFlow(cart)
  }).then(googlePayResponse => {
    if (googlePayResponse.googleEncryptedData) {
      return post({ path: checkoutURL }, { body: JSON.stringify(googlePayResponse) })
    }

    return {
      statusCode: `GOOGLEPAY_NO_TOKEN`,
      message: `Didn't receive a token from GooglePay`
    }
  }).catch(e => {
    return {
      statusCode: e.statusCode || `GOOGLEPAY_FAILURE`,
      message: e.message
    }
  })
}

export function checkoutWithAfterPay() {
  let afterPay
  let baseUrlString = baseUrl()
  return loadCartContents().then((cart) => {
    afterPay = new AfterPay(baseUrlString)
    return afterPay.afterPayFlow(cart)
  })
}

export function loadValidation(lang) {
  return get({ path: `validation/${getMetaOptions('country.code').toLowerCase()}/${lang}` })
}

export function loadCountries() {
  return get({ path: 'territory' })
}

export function loadServerTime(timeZone) {
  return get({
    path: `get-time`,
    params: {
      zone: timeZone
    }
  })
}

/* VOUCHERS */

export function applyVoucher(data) {
  return post(
    { path: 'cart/voucher' },
    { body: data }
  )
}

export function removeVouchers(data) {
  return deleteCall(
    { path: 'cart/voucher' },
    { body: data }
  )
}

/* CART */

export function loadCartContents() {
  return get({
    path: 'cart',
    params: {
      lang: getLanguage()
    }
  })
}

export function updateCartItem({ cartItem, quantity }) {
  return put(
    {
      path: `cart/${cartItem.id}`,
      params: {
        lang: getLanguage()
      }
    },
    { body: JSON.stringify({ quantity: quantity }) }
  )
}

export function addCartItem({ quantity, product, skuOptions }) {
  return post(
    { path: `cart` },
    {
      body: JSON.stringify({
        productId: product.id,
        quantity: quantity,
        setCode: skuOptions.sku,
        forceBasketClear: false
      })
    }
  )
}

export function recreateCart({ cartPublicId }) {
  return post(
    {
      path: `cart/recreateCart`,
      params: {
        cartPublicId
      }
    }
  )
}

/* SHIPPING */

export function saveShippingOption(id) {
  return post(
    { path: 'shipping' },
    { body: JSON.stringify({ id: id }) }
  )
}

/* EMAIL */

export function addToEmailList(email) {
  return get({
    path: `marketing/subscribe`,
    params: {
      email: email,
      language: getLanguage()
    }
  })
}

export function removeFromEmailList(email) {
  return deleteCall(
    { path: 'marketing/subscribe' },
    { body: JSON.stringify({ email }) }
  )
}

/* PDP */

export function getProduct({ productId, language }) {
  // the /s/ denotes the stateless version of this endpoint (does not require cookie)
  return get({
    path: `product/s/${productId}`,
    params: {
      lang: language,
      siteTag: getMetaOptions('siteTag')
    }
  })
    .then(response => {
      // Product API returns 200 OK still with a result of NOT_FOUND
      if (response.result === 'NOT_FOUND') {
        throw new Error('Not found')
      }

      return {
        product: response
      }
    })
    .catch(err => {
      logToCloudWatch('PDP-Error', err.status)
      throw err
    })
}
