import { OVERLAY_ON, OVERLAY_OFF } from 'state/actions'

const initalState = {
  megamenu: false,
  mobileSearch: false,
  pullout: false
}

const overlay = (state = initalState, action) => {
  switch (action.type) {
  case OVERLAY_OFF:
    return {
      ...state,
      [action.payload]: false
    }
  case OVERLAY_ON:
    return {
      ...state,
      [action.payload]: true
    }
  default:
    return state
  }
}

export default overlay
