import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { CREATEFILTERSMAP } from 'services/algolia'
import { I18n } from 'utils/i18n'
import { Button } from 'components/buttons'
import { Svg } from 'components/icons'
import { Heading } from 'components/text'
import { Layout } from 'components/layout'

import styles from './filter-menu-button.css'

export const webLabels = {
  sortByLabel: `WEB.LISTING.SORTBY.LABEL`
}

export const FilterMenuButton = ({
  active,
  buttonType = 'default',
  children,
  className,
  closeIcon,
  fontSize,
  name,
  onClick,
  openIcon,
  padding
}) => {
  const selectedFilters = useSelector(state => state.listing.selectedFilters)

  let filterType
  let webLabel = webLabels.sortByLabel

  if (name !== `sortBy`) {
    const FILTERSMAP = CREATEFILTERSMAP()
    webLabel = FILTERSMAP[name].webLabel
    filterType = FILTERSMAP[name].filterType
  }

  return (
    <Layout
      className={styles.container}
      layout={{
        paddingHorizontal: padding
      }}
    >
      <Button
        className={classNames(styles.button, {
          [className]: className,
          [styles.active]: active
        })}
        onClick={onClick}
        positioning={buttonType === `secondary` && {
          buttonHeight: `medium`
        }}
        styling={{
          type: buttonType
        }}
      >
        <div className={styles.headingWrapper}>
          {webLabel &&
            <Heading
              content={{
                html: I18n.t(webLabel)
              }}
              styling={{
                fontFamily: 'secondary',
                fontSize,
                universalColor: true
              }}
            />
          }
          {selectedFilters[name] && Boolean(selectedFilters[name].length) &&
            <span
              className={classNames(styles.besideLabel, { // universal text component needed
                [styles.xxl]: fontSize === `xxl`,
                [styles.xl]: fontSize === `xl`,
                [styles.large]: fontSize === `large`,
                [styles.medium]: fontSize === `medium`,
                [styles.small]: fontSize === `small`,
                [styles.xs]: fontSize === `xs`
              })}
            >
              ({renderBesideLabel()})
            </span>
          }
        </div>
        <Svg
          icon={active ? openIcon : closeIcon}
          size="1.4rem"
        />
      </Button>
      {children}
    </Layout>
  )

  function renderBesideLabel() {
    if (filterType === 'numeric') {
      return (
        <Svg
          icon="check"
          size="1.2rem"
        />
      )
    }

    return selectedFilters[name].length
  }
}
