import mediaQueries from 'css/media-queries.json'
import { capitalize } from 'utils'

export default function getMediaQueries(supportedSizes = []) {
  // Must always start with a desktop object
  if (supportedSizes[0] !== 'desktop') {
    supportedSizes.unshift('desktop')
  }

  return supportedSizes.map((device, index) => {
    const thisDevice = supportedSizes[index]
    const nextDevice = supportedSizes[index + 1]
    const minQuery = nextDevice ? `above${capitalize(nextDevice)}` : 'any'

    if (thisDevice === 'desktop') {
      return mediaQueries[minQuery]
    }

    if (!nextDevice) {
      return mediaQueries[device]
    }

    return `${mediaQueries[device]} and ${mediaQueries[minQuery]}`
  })
}
