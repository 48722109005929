import React from 'react'
import { Provider } from 'react-redux'

import store from 'state/reducers'

import { Router } from './router'

export const App = () => {
  React.useEffect(() => {
    // laviniu depends on 'initialized' for an uptime script
    document.querySelector('body').className = 'initialized'
  }, [])

  return (
    <Provider store={store}>
      <Router />
    </Provider>
  )
}
