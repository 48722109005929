import React from 'react'

import { PageBody } from 'components/scaffold'
import { useFilterSets } from 'hooks'
import { PageTemplate } from 'pages'
import { Factory } from 'components/factory'

export const Brand = () => {
  return (
    <PageTemplate template="plp">
      {BrandComponent}
    </PageTemplate>
  )
}

const BrandComponent = ({ items, ...props }) => {
  const loaded = useFilterSets()

  if (loaded) {
    return (
      <PageBody {...props}>
        <Factory items={items} />
      </PageBody>
    )
  }

  return <PageBody loading />
}
