import * as Sentry from '@sentry/browser'
import isPlainObject from 'lodash/isPlainObject'
import isString from 'lodash/isString'
import isNumber from 'lodash/isNumber'
import isArray from 'lodash/isArray'
import isNull from 'lodash/isNull'
import reduce from 'lodash/reduce'
import map from 'lodash/map'

import { getMetaOptions } from 'global-content/config'
import { logToCloudWatch } from 'utils/logToCloudWatch'
import { I18n } from 'utils/i18n'

export function translate({
  content,
  language,
  returnSomething,
  chain = [],
  doI18n = true
}) {
  const languages = getMetaOptions('languages')
  let lang = language

  if (!(languages.indexOf(lang) > -1)) {
    logToCloudWatch('Translate Error', {
      // explicit logging. Should pair up with what is being bubbled up
      value: `Cannot translate unsupported language "${lang}". Available options: ${languages}`
    })

    lang = getMetaOptions('defaultLanguage')
  }

  if (isPlainObject(content)) {
    const keys = Object.keys(content)
    if (
      languages.some(lng => keys.includes(lng)) &&
      !keys.includes(lang)
    ) {
      if (returnSomething) {
        return content.en || ``
      } else {
        logTranslationError(lang, content, chain)
        return ``
      }
    }

    return getTranslatedValue({
      content,
      language,
      returnSomething,
      chain,
      doI18n
    })
  }

  if (isString(content) || isNumber(content)) {
    return finalValue({
      value: content,
      doI18n,
      language
    })
  }

  return map(content, item => isPlainObject(item)
    ? getTranslatedValue({
      content: item,
      language,
      returnSomething,
      chain,
      doI18n
    })
    : item
  )
}

function getTranslatedValue({
  content,
  language,
  returnSomething,
  chain,
  doI18n
}) {
  const country = getMetaOptions('country.code').toLowerCase()

  return reduce(
    content,
    (acc, value, key) => {
      if (isPlainObject(value)) {
        if (
          value[`${language}-${country}`] ||
          value[`${language}-${country.toUpperCase()}`] ||
          value[language] ||
          isNull(value[language]) ||
          value[language] === ``
        ) {
          acc[key] = (
            value[`${language}-${country}`] ||
            value[`${language}-${country.toUpperCase()}`] || value[language]
          )
        } else {
          acc[key] = translate({
            content: value,
            language,
            returnSomething,
            chain: [...chain, key],
            doI18n
          })
        }
      } else if (isArray(value)) {
        acc[key] = translate({
          content: value,
          language,
          returnSomething,
          chain: [...chain, key],
          doI18n
        })
      } else {
        acc[key] = finalValue({
          value,
          doI18n,
          language
        })
      }
      return acc
    },
    {}
  )
}

function finalValue({ value, doI18n, language }) {
  if (doI18n) {
    return I18n.t(value, {
      language
    })
  }

  return value
}

const sentryErrorMap = {
  // these are known, so start off in the map not to fire events for them
  'fields.phoneticSurname.label.en': `known`,
  'fields.phoneticSurname.example.en': `known`,
  'fields.phoneticGivenName.example.en': `known`,
  'fields.phoneticGivenName.label.en': `known`
}

function logTranslationError(lang, content, chain) {
  const message = `Missing language key "${lang}". Available content: ${JSON.stringify(content)}`
  const position = [...chain, lang].join('.')

  if (!sentryErrorMap[position]) {
    sentryErrorMap[position] = message

    logToCloudWatch('Missing language key', {
      position,
      value: message
    })

    Sentry.withScope(scope => {
      Object.keys(content).forEach(key => {
        scope.setExtra(key, content[key])
      })
      Sentry.captureMessage(`Missing language key ${position}`)
    })
  }
}

export function translatedOptions(content) {
  const translated = {}

  getMetaOptions('languages').forEach(language => {
    translated[language] = translate({ content, language })
  })

  return translated
}
