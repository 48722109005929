import React from 'react'
import { useSelector } from 'react-redux'
import uniq from 'lodash/uniq'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'

import { HITS_PER_PAGE } from 'utils/constants'
import * as algolia from 'services/algolia'
import { getAlgoliaIndex } from 'global-content'
import { mapOrder } from 'utils'

export function useProductTray({
  algoliaIndex,
  itemsToShow,
  lazy,
  query,
  retryUsing,
  retryParams,
  tabs
}) {
  const activeLanguage = useSelector(state => state.language.active)
  const [products, setProducts] = React.useState([])
  const [activeTab, setActiveTab] = React.useState(0)
  const { filters } = tabs[activeTab]

  React.useEffect(() => {
    if (!lazy) {
      getNewProducts({
        language: activeLanguage,
        algoliaIndex,
        filters,
        itemsToShow,
        hitsPerPage: HITS_PER_PAGE, // to guarantee the ranking order is correct
        query,
        retryUsing,
        retryParams
      }).then(results => {
        setProducts(results)
      })
    }
  }, [activeTab, lazy, JSON.stringify(filters)])

  return { activeTab, setActiveTab, products }
}

function getNewProducts({
  language,
  algoliaIndex,
  collectedVariants = [],
  filters,
  itemsToShow,
  hitsPerPage,
  query,
  retryParams = [],
  retryUsing
}) {
  const options = {
    filters,
    hitsPerPage,
    query
  }

  return algolia.getProducts({
    language,
    index: getAlgoliaIndex(algoliaIndex),
    options
  }).then(data => {
    // Debugging
    // console.log({ filters })
    // console.log([...collectedVariants.map(v => v.productId), ...data.hits.map(v => v.productId)])

    // If the response has less than {{hitsPerPage}}, try again. Pass on the already collected products
    // So they can be used to check if there are enough products
    // And to exlude their ids from future calls

    if (
      (data.hits.length + collectedVariants.length) < itemsToShow &&
      retryParams.length
    ) {
      let retryFilters = filters
      retryFilters = {
        ...filters,
        productId: {
          excludes: uniq([
            ...get(filters, 'productId.excludes', []),
            ...collectedVariants.map(v => v.productId),
            ...data.hits.map(v => v.productId)
          ])
        }
      }

      const { newFilters, newParams, newRetryUsing } = retryParameters({
        filters: retryFilters,
        params: retryParams,
        retry: retryUsing
      })

      return getNewProducts({
        algoliaIndex,
        collectedVariants: [...collectedVariants, ...data.hits],
        filters: newFilters,
        itemsToShow,
        language,
        hitsPerPage,
        query,
        retryParams: newParams,
        retryUsing: newRetryUsing
      })
    }

    let variantOrder = [
      ...collectedVariants,
      ...data.hits
    ]

    if (filters && filters.collections) {
      variantOrder.sort((a, b) => {
        const firstCollection = filters.collections.includes[0]
        const aRank = a.collections && a.collections.find(t => t.tag === firstCollection)
        const bRank = b.collections && b.collections.find(t => t.tag === firstCollection)

        if (aRank && bRank) {
          return (
            aRank.rank - bRank.rank
          )
        }
      })
    }

    if (filters && filters.code) {
      variantOrder = mapOrder(variantOrder, filters.code.includes, 'sku')
    }

    return variantOrder.slice(0, itemsToShow)
  })
}

function retryParameters({ filters, params, retry }) {
  let newFilters = cloneDeep(filters)
  const newParams = cloneDeep(params)
  const newRetryUsing = cloneDeep(retry)

  if (!retry) {
    for (let i = 0; i < newParams.length; i++) {
      const parameter = params[i]
      newParams.shift()

      if (Object.prototype.hasOwnProperty.call(newFilters, parameter)) {
        delete newFilters[parameter]
        break
      }
    }
  }

  if (retry) {
    newFilters = cloneDeep(newRetryUsing)

    const facet = Object.keys(newRetryUsing)[0]
    const includes = newRetryUsing[facet].includes
    newFilters[facet].includes = [includes[includes.length - 1]]
    newFilters.productId = filters.productId
    includes.pop()

    if (!includes.length) {
      delete newRetryUsing[facet]
    }
  }

  return {
    newFilters,
    newParams,
    newRetryUsing
  }
}
