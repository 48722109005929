import React from 'react'
import LazyLoad from 'react-lazyload'

import { getComponentOptions } from 'global-content'
import {
  ProductTrayTabs,
  ProductTraySupportLink,
  useProductTray
} from 'factory-components/product-tray'
import { Layout } from 'components/layout'
import { ProductGrid } from 'components/product-listing'

export const ProductTray = props => (
  <LazyLoad
    offset={200}
    placeholder={
      <ProductTrayContent
        {...props}
        lazy
      />
    }
  >
    <ProductTrayContent {...props} />
  </LazyLoad>
)

export const ProductTrayContent = ({
  config = {},
  content = {},
  lazy,
  positioning = {},
  styling = {}
}) => {
  const {
    algoliaIndex,
    analyticsTitle,
    query,
    retryParams,
    retryUsing
  } = config

  const {
    supportLink,
    tabs
  } = content

  const {
    itemsPerRow,
    itemsToShow,
    supportLinkPosition,
    supportLinkSize,
    supportLinkType,
    tabsMarginBottom,
    tabsPosition
  } = positioning

  const {
    productCardOptions
  } = styling

  const {
    activeTab,
    products,
    setActiveTab
  } = useProductTray({
    algoliaIndex,
    itemsToShow,
    lazy,
    query,
    retryParams,
    retryUsing,
    tabs
  })
  const { innerLayout } = getComponentOptions(`productTray`)

  return (
    <div>
      {supportLink && supportLink.href && (
        <ProductTraySupportLink
          href={supportLink.href}
          position={supportLinkPosition}
          size={supportLinkSize}
          text={supportLink.text}
          type={supportLinkType}
        />
      )}
      {tabs.length > 1 &&
        <ProductTrayTabs
          activeTab={activeTab}
          marginBottom={tabsMarginBottom}
          position={tabsPosition}
          setActiveTab={setActiveTab}
          tabs={tabs}
        />
      }
      <Layout layout={innerLayout}>
        <ProductGrid
          analyticsTitle={analyticsTitle}
          displayOptions={productCardOptions}
          itemsPerRow={itemsPerRow}
          itemsToShow={itemsToShow}
          products={products}
          skeleton={!products.length}
        />
      </Layout>
    </div>
  )
}
