import layoutCss from './layout.css'
import { getConfigOptions } from 'global-content'
import { capitalize } from 'utils'

export function cssMap({ type, value }) {
  const map = {
    container: {
      xs: layoutCss.siteContainerXs,
      small: layoutCss.siteContainerSmall,
      normal: layoutCss.siteContainerMedium, // needs to be removed
      medium: layoutCss.siteContainerMedium,
      large: layoutCss.siteContainerLarge,
      full: layoutCss.siteContainerFull,
      flush: layoutCss.siteContainerFlush,
      clientDefault: layoutCss[`siteContainer${capitalize(getConfigOptions(`layout.container`))}`]
    },
    marginBottom: {
      xxxxs: layoutCss.marginBottomXxxxs,
      xxxs: layoutCss.marginBottomXxxs,
      xxs: layoutCss.marginBottomXxs,
      xs: layoutCss.marginBottomXs,
      small: layoutCss.marginBottomSmall,
      medium: layoutCss.marginBottomMedium,
      large: layoutCss.marginBottomLarge,
      xl: layoutCss.marginBottomXl,
      clientDefault: layoutCss[`marginBottom${capitalize(getConfigOptions(`layout.marginBottom`))}`]
    },
    marginHorizontal: {
      xxxxs: layoutCss.marginHorizontalXxxxs,
      xxxs: layoutCss.marginHorizontalXxxs,
      xxs: layoutCss.marginHorizontalXxs,
      xs: layoutCss.marginHorizontalXs,
      small: layoutCss.marginHorizontalSmall,
      medium: layoutCss.marginHorizontalMedium,
      large: layoutCss.marginHorizontalLarge,
      xl: layoutCss.marginHorizontalXl,
      xxl: layoutCss.marginHorizontalXxl
    },
    marginVertical: {
      xxxxs: layoutCss.marginVerticalXxxxs,
      xxxs: layoutCss.marginVerticalXxxs,
      xxs: layoutCss.marginVerticalXxs,
      xs: layoutCss.marginVerticalXs,
      small: layoutCss.marginVerticalSmall,
      medium: layoutCss.marginVerticalMedium,
      large: layoutCss.marginVerticalLarge,
      xl: layoutCss.marginVerticalXl,
      xxl: layoutCss.marginVerticalXxl
    },
    paddingHorizontal: {
      xxxxs: layoutCss.paddingHorizontalXxxxs,
      xxxs: layoutCss.paddingHorizontalXxxs,
      xxs: layoutCss.paddingHorizontalXxs,
      xs: layoutCss.paddingHorizontalXs,
      small: layoutCss.paddingHorizontalSmall,
      medium: layoutCss.paddingHorizontalMedium,
      large: layoutCss.paddingHorizontalLarge,
      xl: layoutCss.paddingHorizontalXl,
      xxl: layoutCss.paddingHorizontalXxl,
      clientDefault: layoutCss[`paddingHorizontal${capitalize(getConfigOptions(`layout.padding`))}`]
    },
    paddingVertical: {
      xxxxs: layoutCss.paddingVerticalXxxxs,
      xxxs: layoutCss.paddingVerticalXxxs,
      xxs: layoutCss.paddingVerticalXxs,
      xs: layoutCss.paddingVerticalXs,
      small: layoutCss.paddingVerticalSmall,
      medium: layoutCss.paddingVerticalMedium,
      large: layoutCss.paddingVerticalLarge,
      xl: layoutCss.paddingVerticalXl,
      xxl: layoutCss.paddingVerticalXxl,
      clientDefault: layoutCss[`paddingVertical${capitalize(getConfigOptions(`layout.padding`))}`]
    }
  }

  return map[type][value]
}
