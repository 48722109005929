import React from 'react'
import { useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/browser'

import { logToCloudWatch } from 'utils/logToCloudWatch'
import { updateGoogleAnalytics } from 'services/analytics'
import { PageTemplate } from 'pages'
import { PageBody } from 'components/scaffold'
import { Factory } from 'components/factory'
import { useRouteChange } from 'hooks'

import styles from './not-found.css'

export const NotFound = ({ logger = {} }) => {
  const { pathname, search } = useLocation()
  const updateLocation = useRouteChange()

  React.useEffect(() => {
    // this updateLocation is for when the PDP fails to return a product
    updateLocation({ pathname, search, force: true })
    logToCloudWatch('Error-404', logger)

    Sentry.withScope(scope => {
      Object.keys(logger).forEach(key => {
        scope.setExtra(key, logger[key])
      })
      Sentry.captureMessage(`Error-404: ${logger.parent}`)
    })

    updateGoogleAnalytics(`pageNotFound`, {
      url: pathname
    })
  }, [])

  return (
    <PageTemplate
      logger={logger}
      template="status-404"
    >
      {NotFoundComponent}
    </PageTemplate>
  )
}

export const NotFoundComponent = ({
  items,
  ...props
}) => {
  return (
    <PageBody
      className={styles.notFound}
      data-testid="not-found"
      {...props}
    >
      <Factory items={items} />
    </PageBody>
  )
}
