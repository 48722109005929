import { unversionedStorage } from 'utils/storage'
import uuidv4 from 'uuid/v4'

export default class ShoppingSessionId {
  constructor() {
    this.keyName = 'shoppingSessionId'
  }

  init() {
    if (!unversionedStorage.get(this.keyName)) {
      this.set()
    }

    this.value = unversionedStorage.get(this.keyName)
  }

  set() {
    const uuid = uuidv4()
    unversionedStorage.set(this.keyName, uuid)
    this.value = uuid
  }
}
