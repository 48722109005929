import React from 'react'
import { useDispatch } from 'react-redux'

import { closePullout, overlayOff } from 'state/actions'
import { I18n } from 'utils/i18n'
import { SortBy } from 'components/filters'
import { Heading } from 'components/text'

import styles from './mobile-sort-by.css'

export const webLabels = {
  sortByLabel: `WEB.LISTING.SORTBY.LABEL`
}

export const MobileSortBy = () => {
  const dispatch = useDispatch()

  return (
    <React.Fragment>
      <div className={styles.header}>
        <Heading
          content={{
            html: I18n.t(webLabels.sortByLabel)
          }}
          styling={{
            fontFamily: `primary`,
            fontSize: `xsRegular`,
            universalColor: true
          }}
        />
      </div>

      <SortBy
        buttonWrapperClass={styles.buttonWrapper}
        onClick={handleClick}
      />
    </React.Fragment>
  )

  function handleClick() {
    dispatch(overlayOff('pullout'))
    dispatch(closePullout())
  }
}
