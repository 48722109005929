import React from 'react'
import classNames from 'classnames'

import { Carousel } from 'components/carousel'
import { Grid } from 'components/grid'
import { Factory } from 'components/factory'
import { Tabs } from 'components/tabs'

import styles from './group.css'

export const Group = ({
  config = {},
  content = {},
  items,
  positioning = {}
}) => {
  const { carousel, tabs } = config

  const {
    alignItems,
    columns,
    columnWidths,
    direction,
    gap,
    justifyContent,
    textAlign
  } = positioning

  if (carousel) {
    return (
      <Carousel
        config={config}
        items={items}
        positioning={positioning}
      />
    )
  }

  if (tabs) {
    return (
      <Tabs
        config={config}
        content={content}
        items={items}
        positioning={positioning}
      />
    )
  }

  if (columns || columnWidths) {
    return (
      <Grid
        columns={columnWidths || columns}
        gap={gap}
      >
        {renderContent(true)}
      </Grid>
    )
  }

  if (direction === `horizontal` || direction === `vertical` || textAlign) {
    return (
      <div
        className={classNames({
          [styles.flex]: [`horizontal`, `vertical`].includes(direction),
          [styles.horizontal]: direction === `horizontal`,
          [styles.vertical]: direction === `vertical`
        })}
        style={{
          '--gap': gap,
          alignItems,
          justifyContent,
          textAlign
        }}
      >
        {renderContent(true)}
      </div>
    )
  }

  return renderContent(false)

  function renderContent(wrap) {
    return (
      <Factory
        data={{
          wrap
        }}
        items={items}
      />
    )
  }
}
