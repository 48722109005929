import React from 'react'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import {
  MobileFilters,
  MobileNavigation,
  MobileSortBy
} from 'components/pullout'

import styles from './pullout.css'

export const Pullout = () => {
  const dispatch = useDispatch()
  const active = useSelector(state => state.pullout.active)
  const content = useSelector(state => state.pullout.content)

  const componentMap = {
    mobileFilters: MobileFilters,
    mobileNavigation: MobileNavigation,
    mobileSortBy: MobileSortBy
  }

  const Component = componentMap[content]

  return (
    <div className={classNames(styles.container, {
      [styles.out]: active
    })}>
      {Component &&
        <Component dispatch={dispatch} />
      }
    </div>
  )
}
