import { getConfigOptions, getMetaOptions } from 'global-content/config'
import { unversionedStorage } from 'utils/storage'
import { getLanguage } from 'utils'

export function setPrefixedUrl() {
  const country = getMetaOptions('country.code').toLowerCase()
  const languages = getMetaOptions('languages')
  let rest = `${window.location.pathname}${window.location.search}`
  const pieces = window.location.pathname.split('/')

  if (pieces[1].substr(0, 2).match(`^${languages.join('|')}`)) {
    rest = `/${pieces.splice(2, pieces.length).join('/')}${window.location.search}`
  }

  const languagePrefix = getLanguagePrefix()
  window.history.replaceState({}, '', `/${languagePrefix}-${country}${rest}`)

  // https://www.w3schools.com/tags/ref_language_codes.asp
  document.querySelector('html').setAttribute('lang', languagePrefix)
  document.querySelector('html').className = languagePrefix
}

function getLanguagePrefix() {
  const clientTag = getMetaOptions('clientTag')
  const country = getMetaOptions('country.code').toLowerCase()
  const defaultLanguage = getMetaOptions('defaultLanguage')
  const languages = getMetaOptions('languages')

  // if only one language, return that early
  if (languages.length === 1) {
    return languages[0]
  }

  let languagePrefix = defaultLanguage
  languagePrefix = getBrowserLanguage(languagePrefix)
  languagePrefix = getLocalstorageLang(languagePrefix)

  if (languages.includes(getLanguage())) {
    languagePrefix = getLanguage()
  }

  return languagePrefix

  function getBrowserLanguage(lang) {
    const browserLanguage = getNavigatorLanguage()
    if (
      languages.includes(browserLanguage) &&
      getConfigOptions(`useBrowserLanguage`)
    ) {
      lang = browserLanguage
    }

    return lang
  }

  function getLocalstorageLang(lang) {
    const preChosenLanguages = unversionedStorage.get('chosenLanguages') || {}
    const preChosenLang = preChosenLanguages[`${clientTag}-${country}`]

    if (languages.includes(preChosenLang)) {
      lang = preChosenLang
    }

    return lang
  }
}

function getNavigatorLanguage() {
  let navigatorLanguage = navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en'

  if (navigator.languages && navigator.languages.length) {
    navigatorLanguage = navigator.languages[0]
  }

  return navigatorLanguage.substr(0, 2)
}
