import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { I18n } from 'utils/i18n'
import { Svg } from 'components/icons'
import { LinkText } from 'components/text'

import styles from './breadcrumbs.css'

export const webLabels = {
  back: `WEB.CTA.BACK`
}

export const Breadcrumbs = ({ positioning = {} }) => {
  const history = useHistory()
  const breadcrumbs = useSelector(state => state.navigation.breadcrumbs)
  const currentPage = useSelector(state => state.navigation.currentPage)
  const { showIcon } = positioning

  return (
    <ul className={styles.breadcrumbs}>
      {showIcon &&
        <button
          className={styles.back}
          onClick={() => history.goBack()}
        >
          <span className={styles.backIcon}>
            <Svg
              icon="angle_left"
              size="1.2rem"
            />
          </span>
          <LinkText
            hoverState={1}
            lookalike
            size="xs"
          >
            {I18n.t(webLabels.back)}
          </LinkText>
        </button>
      }
      {breadcrumbs.map(({ h1, path }) => (
        <li
          className={styles.item}
          key={path}
        >
          <LinkText
            active={path === currentPage.path}
            activeState={1}
            hoverState={1}
            size="xs"
            to={path}
          >
            {h1}
          </LinkText>
        </li>
      ))}
    </ul>
  )
}
