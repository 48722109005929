import React from 'react'

import { useContent } from 'hooks'
import { unversionedStorage } from 'utils/storage'
import { Layout } from 'components/layout'
import { Button } from 'components/buttons'
import { Heading, HtmlText } from 'components/text'
import { Image } from 'components/images'
import { MediaQuery } from 'components/scaffold'
import { Modal } from 'components/modal'

import styles from './splash-modal.css'

const SplashModalComponent = ({
  image,
  headline,
  bullets,
  buttonText,
  belowTextHTML
}) => {
  const [timesViewed, setTimesViewed] = React.useState(unversionedStorage.get('modalViewed') || 0)

  if (timesViewed === 0) {
    return (
      <div>
        <Modal
          onClose={closeModal}
          padding="none"
        >
          <div className={styles.modalContent}>
            <Image
              prefix
              src={image.src}
              ratio={image.ratio}
            />
            {(headline || bullets || buttonText || belowTextHTML) &&
              <div className={styles.textContent}>
                <Layout
                  layout={{
                    marginBottom: `xxxs`
                  }}
                >
                  <Heading
                    content={{
                      html: headline
                    }}
                    styling={{
                      fontFamily: `primary`,
                      fontSize: `small`,
                      universalColor: true
                    }}
                  />
                </Layout>
                <ul>
                  {bullets.map(bullet => (
                    <li key={bullet}>
                      <HtmlText html={bullet} />
                    </li>
                  ))}
                </ul>
                <Button
                  className={styles.button}
                  onClick={closeModal}
                  styling={{
                    singleLine: true,
                    type: `primary`
                  }}
                >
                  {buttonText}
                </Button>
                {belowTextHTML &&
                  <div className={styles.belowTextWrapper}>
                    <HtmlText html={belowTextHTML} />
                  </div>
                }
              </div>
            }
          </div>
        </Modal>
      </div>
    )
  }

  return null

  function closeModal() {
    const newCount = timesViewed + 1
    setTimesViewed(newCount)
    unversionedStorage.set('modalViewed', newCount)
  }
}

export const SplashModal = () => {
  const { splashModal } = useContent('promotions')

  if (splashModal) {
    return (
      <MediaQuery
        startingProps={splashModal}
      >
        {SplashModalComponent}
      </MediaQuery>
    )
  }

  return null
}
